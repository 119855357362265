import React, { Component } from 'react'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd"
import Icon from '../shared/icon'

const ProductCategoryImage = ({ category }) => {
  let image
  if (category.file_url) {
    image = <img src={category.file_url} />
  } else {
    image = (
      <div>
        <i className="bi bi-card-image"></i>
      </div>
    )
  }

  return (
    <div className="robis-image">{image}</div>
  )
}

class ProductCategories extends Component {
  state = { hoveredCategory: {} }

  navigateToCategory = (category) => {
    if (this.props.edit) return

    window.location = `/products/${category.id}`
  }

  editCategory(category, e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.setObjectForEdit(category)
  }

  renderOverlay(category) {
    if (this.props.edit) {
      return (
        <div className="overlay text-center">
          <Icon name='dragCross' className='drag-icon cross-icon' />
          <div onClick={this.editCategory.bind(this, category)}>
            <div className='mb-1'>Drag to reposition</div>
            <div className='d-flex justify-content-center'>
              <Icon name='editIcon' className='primary-edit-icon mr-2' />
              <span className='as-link white'>Edit</span>
            </div>
          </div>
        </div>
      )
    }
  }

  renderCategoryCard(category, index) {
    return (
      <div
        id={`category-${category.id}`}
        className="card robis-image-card ml-4 p-0"
        onClick={() => this.navigateToCategory(category)}>
        <ProductCategoryImage category={category} />
        <div className="card-body robis-card-body">
          <b>{category.name}</b>
        </div>
        {this.renderOverlay(category)}
      </div>
    )
  }

  onChange(sourceId, sourceIndex, targetIndex, targetId) {
    let nextItems = swap(this.props.categories, sourceIndex, targetIndex)
    nextItems = nextItems.map((item, index) => { item.position = index + 1; return item })
    this.props.updatePositions(nextItems)
  }

  render() {
    return (
      <GridContextProvider onChange={this.onChange.bind(this)}>
        <GridDropZone
          id="items"
          boxesPerRow={3}
          rowHeight={250}
          disableDrag={!this.props.edit}
          style={{ height: '100%' }}
        >
          {this.props.categories.map((category, index) => {
            return (
              <GridItem key={category.id} className='grid-robis-card'>
                {this.renderCategoryCard(category, index)}
              </GridItem>
            )
          })}
        </GridDropZone>
      </GridContextProvider>
    )
  }
}

export default ProductCategories
