import React, { Fragment, MouseEventHandler } from 'react'
import Tooltip from '@mui/material/Tooltip'
import InputMask from 'react-input-mask'
import map from 'lodash/map'
import find from 'lodash/find'
import classNames from 'classnames'
import { Box, ClickAwayListener, Popper } from '@mui/material'
import { operationNames, operationIcons } from './DraggableOperation'
import { LocalizationContext } from '../../shared/contexts'
import Icon from '../../shared/icon'
import { CookingMode } from '../types'
import { t } from '../../../i18n'

type Product = {
  id: number,
  file_url: string,
  name: string,
  recipe_configurable: boolean
}

interface RecipeTaskProduct {
  product: Product,
  amount: number,
}

const twoDigits = (value) => {
  return (value <= 9) ? `0${value}` : value.toString()
}

const formattedTime = (totalSeconds) => {
  const minutes = twoDigits(Math.floor(totalSeconds / 60))
  const seconds = twoDigits(totalSeconds % 60)

  return `${minutes}:${seconds}`
}

const timeStrToSeconds = (value) => {
  const strTime = value.replaceAll('_', '0') // replace input mask characters
  const [ minutes, seconds ] = strTime.split(':').map(v => parseInt(v))

  return minutes * 60 + seconds
}

const PlusMinusWrapper: React.FC<{
  className?: string,
  name: string,
  value: number,
  step?: number,
  onChange: Function,
  children: React.ReactNode,
}> = ({ className, name, value, step = 1, onChange, children }) => {
  return (
    <div className={`plus-minus-input ${className ? className : ''}`}>
      <button className='minus-button' onClick={() => onChange(name, value - step)}>
        -
      </button>
      {children}
      <button className='plus-button' onClick={() => onChange(name, value + step)}>
        +
      </button>
    </div>
  )
}

interface TaskProps {
  operation: string,
  ungroupTask: Function,
  removeTask: MouseEventHandler,
  removeProductFromTask: MouseEventHandler,
  taskIndex: number,
  updateTaskField: Function,
  updateTaskParameter: Function,
  combineTargetFor: any,
  passedRef: string,
  draggableProps: any,
  dragHandleProps: any,
  isDragging: boolean,
  transform: string,
  stepName: string,
  cooking_mode_id: number,
  cook_time: number,
  main_temp: number,
  aux_temp: number,
  updateProductConfigurable: Function,
  defaultAuxTemp: number,
  groupNumber: number
}

interface AddProductTaskProps extends TaskProps {
  updateTaskProductParameter: Function,
  recipe_task_products: RecipeTaskProduct[],
}

interface OperationTaskProps extends TaskProps {
  cookingModes: CookingMode[],
  defaultAuxTemp: number,
}

const scope = 'recipes'

const AddProductsTask: React.FC<AddProductTaskProps> = ({
  operation, recipe_task_products, ungroupTask, removeTask, removeProductFromTask, taskIndex, updateTaskProductParameter,
  updateTaskParameter, combineTargetFor, passedRef, draggableProps, dragHandleProps,
  transform, stepName, cook_time, main_temp ,updateProductConfigurable, groupNumber
}) => {
  const isBoilerTask = (stepName === 'boiler_tasks')
  const validCombinableTarget = (combineTargetFor && operation === 'add_product')
  const multipleProducts = (recipe_task_products && recipe_task_products.length > 1)
  const classes = classNames(
    'task', {
      'combinable-target': validCombinableTarget,
      'grouped': multipleProducts
    }
  )

  let boilerTaskTimerRendered = false

  const boilerTaskTimer = () => {
    boilerTaskTimerRendered = true

    return (
      <div className='task-parameters'>
        <PlusMinusWrapper
          className='ml-2'
          name='cook_time'
          step={5}
          value={cook_time}
          onChange={(name, value) => updateTaskParameter(stepName, taskIndex, name, value)}
        >
          <div className='subwrapper'>
            <Tooltip
            title={t('recipe_products.cook_time', { scope })}
            placement='top'
            arrow>
              <InputMask
                value={formattedTime(cook_time)}
                name='cook_time'
                mask={[/[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                onChange={({ target: { name, value } }) =>
                  updateTaskParameter(stepName, taskIndex, name, timeStrToSeconds(value))
                }
              />
              </Tooltip>
          </div>
        </PlusMinusWrapper>
      </div>
    )
  }

  return (
    <div {...draggableProps} {...dragHandleProps}
      className={classes}
      ref={passedRef}
      style={{ ...draggableProps.style, transform }}
    >
      <div className='task-items-wrapper'>
        {multipleProducts &&
          <div className="task-item group-info">
            <div className="group-name">
              <span className="task-name">Group {groupNumber}</span>
              <Tooltip title='Ungroup' placement='top' className='task-ungroup-btn' arrow>
                <i
                  className='bi bi-box-arrow-down-right'
                  onClick={() => ungroupTask(stepName, taskIndex, null, true)}
                />
              </Tooltip>
            </div>
            {isBoilerTask && boilerTaskTimer()}
            {multipleProducts && <span className='task-remove-button group-remove' onClick={removeTask}><Icon name="deleteIcon"/></span>}
          </div>
        }
        <LocalizationContext.Consumer>
          {({ weight_scale }) =>
            map(recipe_task_products, ({ amount, product: { name, file_url, recipe_configurable } }, productIndex) => {
              return (
                <div className='task-item' key={productIndex}>
                  <img className='task-image' src={file_url} alt={name} />
                  <span className='task-name'>{name}</span>
                  {multipleProducts &&
                    <Tooltip title='Ungroup' placement='top' className='task-ungroup-btn' arrow>
                      <i
                        className='bi bi-box-arrow-down-right'
                        onClick={() => ungroupTask(stepName, taskIndex, productIndex)}
                      />
                    </Tooltip>
                  }
                  <PlusMinusWrapper
                    name='amount'
                    step={weight_scale === 'metric' ? 10 : 1}
                    value={amount}
                    onChange={(name, value) => updateTaskProductParameter(stepName, taskIndex, productIndex, name, value)}
                  >
                    <Tooltip
                      title={t('recipe_products.amount', { scope })}
                      placement='top'
                      arrow
                    >
                      <div className='subwrapper'>
                        <input
                          className={weight_scale == 'metric' ? 'with-unit-offset' : 'with-unit-offset-oz-kg'}
                          type='number'
                          name='amount'
                          value={amount}
                          maxLength={4}
                          onChange={({ target: { name, value } }) =>
                            updateTaskProductParameter(stepName, taskIndex, productIndex, name, value)
                          }
                        />
                        <span className='input-unit'>{weight_scale === 'metric' ? 'g' : 'oz.'}</span>
                      </div>
                    </Tooltip>
                  </PlusMinusWrapper>
                  {!multipleProducts && isBoilerTask && !boilerTaskTimerRendered && boilerTaskTimer()}
                  <Tooltip
                    title={t('recipe_products.lock', { scope })}
                    placement='top'
                    arrow>
                    <div className='task-availability-icon'>
                      {recipe_configurable ?
                        <Icon name='unlocked' onClick={() => updateProductConfigurable(stepName, taskIndex, productIndex, 0)} /> :
                        <Icon name='locked' onClick={() => updateProductConfigurable(stepName, taskIndex, productIndex, 1)} />}
                    </div>
                  </Tooltip>
                  <span
                    className='task-remove-button'
                    onClick={(e) => multipleProducts ? removeProductFromTask(productIndex) : removeTask(e)}
                  >
                    <Icon name='deleteIcon' />
                  </span>
                </div>
              )
            })
          }
        </LocalizationContext.Consumer>
      </div>
    </div>
  )
}

const OperationTask: React.FC<OperationTaskProps> = ({
  operation, passedRef, draggableProps, dragHandleProps, removeTask, updateTaskParameter, updateTaskField,
  taskIndex, cooking_mode_id, cook_time, main_temp, aux_temp, defaultAuxTemp, cookingModes, transform, stepName
}) => {
  const renderParameters = () => {
    const parameterProps = {
      cookTime: cook_time,
      mainTemp: main_temp,
      auxTemp: aux_temp,
      updateTaskField,
      updateTaskParameter,
      taskIndex,
      stepName
    }

    switch (operation) {
      case 'set_temperature':
        return <SetTemperatureParameters {...parameterProps}
                 defaultAuxTemp={defaultAuxTemp}
               />
      case 'set_cooking_mode':
        return <SetCookingModeParameters {...parameterProps}
                 cookingModes={cookingModes}
                 cooking_mode_id={cooking_mode_id}
               />
      default: null
    }
  }

  return (
    <div {...draggableProps} {...dragHandleProps}
      className='task boiler-task'
      ref={passedRef}
      style={{ ...draggableProps.style, transform }}
    >
      <div className='task-items-wrapper'>
        <div className='task-item'>
          <Icon className='task-image' name={operationIcons[operation]} color='#fff'/>
          <span className='task-name'>{operationNames[operation]}</span>
        </div>
      </div>
      <div className='task-parameters'>
        {renderParameters()}
      </div>
      <span className='task-remove-button' onClick={removeTask}>&times;</span>
    </div>
  )
}

const SetTemperatureParameters = ({
  cookTime, mainTemp, auxTemp, defaultAuxTemp, updateTaskParameter, taskIndex, stepName
}) => {
  return <Fragment>
    <PlusMinusWrapper
      className='task-parameter ml-2'
      name='main_temp'
      step={10}
      value={mainTemp || 0}
      onChange={(name, value) => updateTaskParameter(stepName, taskIndex, name, value)}
    >
      <Tooltip
        title={t('recipe_tasks.set_temperature', { scope })}
        placement='top'
        arrow
      >
        <div className='subwrapper'>
          <input
            className='with-unit-offset'
            type='text'
            name='main_temp'
            value={mainTemp || 0}
            maxLength={3}
            onChange={({ target: { name, value } }) =>
              updateTaskParameter(stepName, taskIndex, name, value)
            }
          />
          <span className='input-unit'>&deg;</span>
        </div>
      </Tooltip>
    </PlusMinusWrapper>
    <PlusMinusWrapper
      className='task-parameter ml-2'
      name='aux_temp'
      step={10}
      value={auxTemp || defaultAuxTemp}
      onChange={(name, value) => updateTaskParameter(stepName, taskIndex, name, value)}
    >
      <Tooltip
          title={t('recipe_tasks.keep_temperature', { scope })}
          placement='top'
          arrow
      >
        <div className='subwrapper'>
          <input
            className='with-unit-offset'
            type='text'
            name='aux_temp'
            value={auxTemp || defaultAuxTemp}
            maxLength={3}
            onChange={({ target: { name, value } }) =>
              updateTaskParameter(stepName, taskIndex, name, value)
            }
          />
          <span className='input-unit'>&deg;</span>
        </div>
      </Tooltip>
    </PlusMinusWrapper>
  </Fragment>
}

const SetCookingModeParameters = ({
  cooking_mode_id, updateTaskField, taskIndex, cookingModes, stepName
}) => {

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null)
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setAnchorElement(anchorElement ? null : anchorRef.current);
  };

  const closeDropdown = () => {
    setAnchorElement(null)
  }

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popper' : undefined;

  const selectMode = (cookingModeId) => {
    updateTaskField(stepName, taskIndex, 'cooking_mode_id', cookingModeId)
    closeDropdown()
  }

  return (
    <Tooltip
      title='Set cooking mode (speed of rotation)'
      placement='top'
      className='task-parameter'
      arrow
    >
      <div>
        <ClickAwayListener onClickAway={closeDropdown}>
          <div className="cooking-mode-select-dropdown">
            <div className="select-dropdown-placeholder" aria-describedby={id} onClick={handleClick} onBlur={handleClick} ref={anchorRef}>
              <span className={`label ${cooking_mode_id ? '' : 'placeholder'}`}>
                {cooking_mode_id
                  ? find(cookingModes, opt => opt.id === cooking_mode_id)?.name
                  : t('recipe_tasks.cooking_mode.select_placeholder', { scope })
                }
              </span>
              <Icon name={open ? 'arrowUp' : 'arrowDown'}/>
            </div>
            <Popper
              style={{zIndex: '2'}}
              placement="bottom-end"
              id={id} open={open}
              anchorEl={anchorElement}
              disablePortal={true}
            >
              <Box
                style={{zIndex: '2', maxHeight: '200', overflow: 'auto'}}
                sx={{ bgcolor: 'background.paper' }}
                className="dropdown-content"
              >
                {map(cookingModes, cookingMode =>
                  <CookingModeLabel
                    key={cookingMode.id}
                    cookingMode={cookingMode}
                    selectMode={() => selectMode(cookingMode.id)}
                  />
                )}
              </Box>
            </Popper>
          </div>
      </ClickAwayListener>
    </div>
  </Tooltip>
  )
}

const CookingModeLabel = ({ cookingMode, selectMode }) => {
  const scope = 'recipes.recipe_tasks.cooking_mode'

  const getMoveMethodName = (moveMethod) => {
    return t(`activerecord.attributes.cooking_mode/move_method.${moveMethod}`)
  }

  const dot = () => {
    return <div className="dot"/>
  }

  return(
    <div className="cooking-mode-label" onClick={selectMode}>
      <span className="cooking-mode-name">{cookingMode.name}</span>
      <span className="cooking-mode-description">
        {t('cooking_mode_description.angle_position', { scope })}: {cookingMode.angle_position} {dot()}
        {t('cooking_mode_description.mixer_speed', { scope })}: {cookingMode.mixer_speed} {dot()}
        {t('cooking_mode_description.rotation_speed', { scope })}: {cookingMode.rotation_speed} {dot()}
        {t('cooking_mode_description.move_method', { scope })}: {getMoveMethodName(cookingMode.move_method)}
      </span>
    </div>
  )
}

const Task = ({
  recipe_task_products, updateTaskField, updateTaskProductParameter, cookingModes, groupNumber,
  defaultAuxTemp, ...props
}) => {
  let transform = props.draggableProps.style.transform

  if (props.isDragging && transform) {
    // Remove horizontal transform
    transform = transform.replace(/\(.+\,/, '(0,')
  }

  const operation = props.operation

  if (operation === 'add_product' || operation === 'add_sauce') {
    return (
      <AddProductsTask {...props as TaskProps}
        operation={operation}
        transform={transform}
        recipe_task_products={recipe_task_products}
        updateTaskProductParameter={updateTaskProductParameter}
        groupNumber={groupNumber}
      />
    )
  } else {
    return (
      <OperationTask {...props as TaskProps}
        operation={operation}
        transform={transform}
        cookingModes={cookingModes}
        updateTaskField={updateTaskField}
        defaultAuxTemp={defaultAuxTemp}
      />
    )
  }
}

export default Task
