import compact from 'lodash/compact'
import flatMap from 'lodash/flatMap'
import { ReplenishmentOrder } from './types'

export const hasActiveReplenishment = (
  replenishmentOrders: ReplenishmentOrder[],
  slotType: 'solid' | 'liquid',
  slotNumber: Number
) => {
  const productReplenishments = compact(flatMap(replenishmentOrders, o => o.productReplenishments))

  if (productReplenishments.length === 0) return false

  return productReplenishments.some(repl =>
    (repl.containerType === slotType && repl.slotNumber === slotNumber)
  )
}
