import React from 'react'
import moment from 'moment'
import map from 'lodash/map'
import partition from 'lodash/partition'
import ProductContainerSlot from './ProductContainerSlot'
import { hasActiveReplenishment } from './shared'
import { ProductContainer, ReplenishmentOrder } from './types'

const SOLID_CONTAINER_ROWS = 7
const SOLID_CONTAINER_COLS = 7
const SOLID_CONTAINER_FRIDGE_COLS = 5
const SOLID_CONTAINER_NONFRIDGE_COLS = SOLID_CONTAINER_COLS - SOLID_CONTAINER_FRIDGE_COLS

const SOLID_CONTAINER_SLOT_COUNT = SOLID_CONTAINER_ROWS * SOLID_CONTAINER_COLS

interface Props {
  searchQuery: string,
  currentProductCategoryId: number,
  containers: Record<number, ProductContainer>,
  currentDate: moment.Moment,
  expandContainer: Function,
  draftReplenishmentOrder: ReplenishmentOrder,
  activeReplenishmentOrders: ReplenishmentOrder[],
  orderSlots: Function,
  replenishmentView?: boolean
}

const SolidsView: React.FC<Props> = ({
  searchQuery, currentProductCategoryId, containers, currentDate, expandContainer,
  draftReplenishmentOrder, activeReplenishmentOrders, orderSlots, replenishmentView
}) => {

  const slots = map(orderSlots(SOLID_CONTAINER_COLS, SOLID_CONTAINER_SLOT_COUNT), slotIndex => {
    return { slotNumber: slotIndex, container: containers[slotIndex] }
  })

  const [fridgeSlots, nonFridgeSlots] = partition(slots, slot =>
    (slot.slotNumber - 1) % SOLID_CONTAINER_COLS > (SOLID_CONTAINER_NONFRIDGE_COLS - 1)
  )

  // slot numbers from human perspective
  const humanSlots = map(Array.from(Array(SOLID_CONTAINER_SLOT_COUNT).keys()), slotIndex => {
    const slotNumber = slotIndex + 1

    return { slotNumber, container: containers[slotNumber] }
  })

  const [humanFridgeSlots, humanNonFridgeSlots] = partition(humanSlots, slot =>
    (slot.slotNumber - 1) % SOLID_CONTAINER_COLS <= (SOLID_CONTAINER_FRIDGE_COLS - 1)
  )

  const renderProductContainerSlot = (slot, humanSlot) => {
    return (
      <ProductContainerSlot
        key={slot.slotNumber}
        slotNumber={slot.slotNumber}
        humanSlotNumber={humanSlot.slotNumber}
        container={slot.container}
        currentDate={currentDate}
        expandContainer={expandContainer}
        searchQuery={searchQuery}
        currentProductCategoryId={currentProductCategoryId}
        isLiquid={false}
        replenishmentView={replenishmentView}
        hasDraftReplenishment={hasActiveReplenishment([draftReplenishmentOrder], 'solid', slot.slotNumber)}
        hasSentReplenishment={hasActiveReplenishment(activeReplenishmentOrders, 'solid', slot.slotNumber)}
      />
    )
  }

  return (
    <div className='tab-content solids'
      style={{ gridTemplateColumns: `${SOLID_CONTAINER_FRIDGE_COLS}fr 20px ${SOLID_CONTAINER_NONFRIDGE_COLS}fr` }}
    >
      <div className='fridge' style={{ gridTemplateColumns: `repeat(${SOLID_CONTAINER_FRIDGE_COLS}, 1fr)` }}>
        {map(fridgeSlots, (slot, index) =>
          renderProductContainerSlot(slot, humanFridgeSlots[index])
        )}
      </div>
      <div className='fridge-seperator'>
        <i className='fridge-icon fa fa-snowflake-o' />
      </div>
      <div className='non-fridge' style={{ gridTemplateColumns: `repeat(${SOLID_CONTAINER_NONFRIDGE_COLS}, 1fr)` }}>
        {map(nonFridgeSlots, (slot, index) =>
          renderProductContainerSlot(slot, humanNonFridgeSlots[index])
        )}
      </div>
    </div>
  )
}

export default SolidsView
