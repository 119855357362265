import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  result = result.map((item, index) => { item.position = index + 1; return item })

  return result
}

const DropContainer = ({ children, updatePositions, collection, direction }) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let items = reorder(collection, result.source.index, result.destination.index)
    updatePositions(items)
  }


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction='vertical'>
        {(dropabbleProvided, snapshot) => (
          <div
            {...dropabbleProvided.droppableProps}
            ref={dropabbleProvided.innerRef}>
            {children}
            {dropabbleProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export { DropContainer, reorder }
