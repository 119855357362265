import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models }  from 'powerbi-client'
import axios from 'axios'

const INTERVAL_TIME = 600000 // 10 minutes for token expiration

interface Props {
  powerbi_report_id: string,
  embedded_url: string
}

interface State {
  refreshToken: string
}

export default class ReportsIndex extends React.Component<Props, State> {
  state = { refreshToken: '' }

  componentDidMount() {
    this.updateToken()
    // Set an interval to check the access token expiration, and update if needed
    setInterval(() => this.updateToken(), INTERVAL_TIME)
    document.addEventListener("visibilitychange", () => {
      // Check the access token when the tab is visible
      if (!document.hidden) {
        this.updateToken()
      }
    })
  }

  updateToken() {
    axios.get('/microsoft/refresh_token').then(res => {
      this.setState({ refreshToken: res.data.refresh_token })
    }).catch(err => {
      console.log("COULDN't fetch", err)
    })
  }

  renderEmbededReport() {
    return (
      <PowerBIEmbed
        embedConfig = {{
          type: 'report',   // Supported types: report, dashboard, tile, visual and qna
          id: this.props.powerbi_report_id,
          embedUrl: this.props.embedded_url,
          accessToken: this.state.refreshToken,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            navContentPaneEnabled: false
          }
        }}
        cssClassName='embed-container'
      />
    )
  }

  render() {
    return (
      <div>
        <h3>Reports</h3>
        {this.renderEmbededReport()}
      </div>
    )
  }
}
