import axios from 'axios'

export default function() {
  if(document.querySelector('meta[name="csrf-token"]')) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    axios.defaults.headers.common['Content-Type'] = 'application/json'

    axios.defaults.params = {}
    axios.defaults.params['format'] = 'json'
  }
}
