import React from 'react'
import Select from 'react-select'
import {
  customSelectOption,
  customValueContainerWithPlaceholder,
} from './common'

// wrapper for react-select dropdown that takes another attribute - depth for each option
const NestedDropdown = ({ placeholder, value, options, handleChange }) => {

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      marginLeft: `${state.data.depth * 15}px`
    })
  };

  return <Select
    className='react-select mb-3'
    classNamePrefix='react-select'
    placeholder={placeholder}
    options={options}
    styles={styles}
    value={value}
    onChange={handleChange}
    isSearchable={false}
    components={{
      Option: customSelectOption,
      ValueContainer: customValueContainerWithPlaceholder,
      IndicatorSeparator: () => null
    }}
  />
}

export default NestedDropdown
