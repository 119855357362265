import React, { useRef } from 'react'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import { ProductReplenishment, ReplenishmentOrder } from '../types'
import Icon from '../../shared/icon'
import { t } from '../../../i18n'

type PrintOrderProps = {
  replenishmentOrder: ReplenishmentOrder
}

const scope = 'replenishment'

const ReplenishmentOrderExport = ({ replenishmentOrder }) => {

  const printRef = useRef(null)

  return (
    <div className="replenishment-export">

      <a href={`/replenishment_orders/${replenishmentOrder.id}/replenishment_order_pdf`} target='_blank'>
        <button className="export-btn"><Icon name='download'/>{t('export.download', { scope })}</button>
      </a>
      <ReactToPrint
        documentTitle="Replenishment order"
        trigger={() => <button className="export-btn"><Icon name='print'/>{t('export.print', { scope })}</button>}
        content={() => printRef.current}
      />
      <div style={{ display: "none" }}>
        <PrintOrderContent ref={(el) => (printRef.current = el)} replenishmentOrder={replenishmentOrder}/>
      </div>
    </div>
  )
}

class PrintOrderContent extends React.Component<PrintOrderProps> {

  productReplenishmentType = (replenishment: ProductReplenishment) => {
    if(replenishment.previousProduct && replenishment.product.id == replenishment.previousProduct.id) {
      return t('export.refill', { scope })
    }
    return  t('export.product_change', { scope })
  }

  render() {
    const { replenishmentOrder } = this.props

    const solidProductReplenishments = replenishmentOrder.productReplenishments.filter(replenishment => replenishment.containerType == 'solid')
    const liquidProductReplenishments = replenishmentOrder.productReplenishments.filter(replenishment => replenishment.containerType == 'liquid')

    return (
      <div className="order-print-content">
        <div>
          <h1>{t('export.replenishment_order', { scope, orderId: replenishmentOrder.id })}</h1>
          <br/>
          <span>{t('export.delivery_date', { scope, deliveryDate: moment(replenishmentOrder.createdAt).format('M/D/YYYY') })}</span>
        </div>

        {!!solidProductReplenishments.length &&
          <div style={{marginTop: '50px'}}>
            <h3>{t('export.solid_ingredients', { scope })}</h3>
            <table className="replenishment-table">
              <thead>
                <tr>
                  <th>{t('order_table.container_slot_number', { scope })}</th>
                  <th>{t('order_table.replenishment_type', { scope })}</th>
                  <th>{t('order_table.previous_product', { scope })}</th>
                  <th>{t('order_table.new_product', { scope })}</th>
                </tr>
              </thead>
              <tbody>
                {solidProductReplenishments.map(replenishment =>
                  <tr key={`${replenishment.id}-${replenishment.slotNumber}`}>
                    <td>{replenishment.slotNumber}</td>
                    <td>{this.productReplenishmentType(replenishment)}</td>
                    <td>{replenishment.previousProduct?.name || t('replenishment.replenishment_modal.empty')}</td>
                    <td>{this.productReplenishmentType(replenishment) == 'Refill' ? '/' : replenishment.product.name}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        }

        {!!liquidProductReplenishments.length &&
          <div style={{marginTop: '50px'}}>
            <h3>{t('export.liquid_ingredients', { scope })}</h3>
            <table className="replenishment-table">
              <thead>
                <tr>
                  <th>{t('order_table.container_slot_number', { scope })}</th>
                  <th>{t('order_table.replenishment_type', { scope })}</th>
                  <th>{t('order_table.previous_product', { scope })}</th>
                  <th>{t('order_table.new_product', { scope })}</th>
                </tr>
              </thead>
              <tbody>
                {liquidProductReplenishments.map(replenishment =>
                  <tr key={`${replenishment.id}-${replenishment.slotNumber}`}>
                    <td>{replenishment.slotNumber}</td>
                    <td>{this.productReplenishmentType(replenishment)}</td>
                    <td>{replenishment.previousProduct.name}</td>
                    <td>{this.productReplenishmentType(replenishment) == 'Refill' ? '/' : replenishment.product.name}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        }

        {!!replenishmentOrder.plateReplenishments.length &&
        <div style={{marginTop: '50px'}}>
          <h3>{t('export.bowls', { scope })}</h3>
          <table className="replenishment-table">
            <thead>
              <tr>
                <th>{t('order_table.container_slot_number', { scope })}</th>
                <th>{t('order_table.amount', { scope })}</th>
              </tr>
            </thead>
            <tbody>
              {replenishmentOrder.plateReplenishments.map(replenishment =>
                <tr key={`${replenishment.id}-${replenishment.slotNumber}`}>
                  <td>{replenishment.slotNumber}</td>
                  <td>{replenishment.amount}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        }
      </div>
    )
  }
}

export default ReplenishmentOrderExport
