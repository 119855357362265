import React from 'react'
import { Tooltip } from '@mui/material'
import { Dropdown } from '@mui/base'
import { Menu } from '@mui/base/Menu'
import { MenuButton } from '@mui/base/MenuButton'
import { MenuItem } from '@mui/base/MenuItem'
import Icon from '../shared/icon'
import { t } from '../../i18n'

const scope = 'stock_control.feeders'

const FeederEdges = ({ feeder }) => {
  return (
    <>
      <span className='container-edge top left'/>
      <span className='container-edge top right'/>
      {!feeder && <>
        <span className='container-edge bottom left'/>
        <span className='container-edge bottom right'/>
      </>}
    </>
  )
}

const Alert = ({ feeder }) => {
  if (!feeder?.show_alert) return null
  return <span className='container-danger' />
}

const ProductName = ({ feeder }) => {
  if (feeder?.product) {
    return <div className='product-name'>{feeder.product.name}</div>
  } else if (feeder) {
    return <div className='product-name'>{t('empty_feeder', { scope })}</div>
  } else {
    return <div className='product-name'/>
  }
}

const RemainingAmount = ({ feeder }) => {
  if (!feeder?.product) return null

  return (
    <>
      <Tooltip title={`${Math.round(feeder.remaining_amount_percent)}% / ${feeder.remaining_amount} left`} placement='top' arrow>
        <div className={`amount-bar status-${feeder.remaining_amount_status}`}>
          <div
            className='current-amount-bar'
            style={{ width: `${feeder.remaining_amount_percent}%` }}
          />
        </div>
      </Tooltip>
      <div className={`remaining-count status-${feeder.remaining_amount_status}`}>
        <span>{`${feeder.remaining_amount}/${feeder.initial_amount}`}</span>
        <Icon name='bowlIcon' />
      </div>
    </>
  )
}

const FeederOption = ({ feeder }) => {
  if (feeder?.product) {
    return <MenuItem className='danger'>{t('neutralise_feeder', { scope })}</MenuItem>
  } else if (feeder) {
    return <MenuItem className='danger'>{t('remove_feeder', { scope })}</MenuItem>
  } else {
    return <MenuItem>{t('add_new_feeder', { scope })}</MenuItem>
  }
}

const FeederDetails = ({ feeder, slotNumber }) => {
  return (
    <div className='d-flex justify-content-between align-items-center mt-2'>
      {feeder
        ? <div className='container-slot-number'>#{slotNumber}</div>
        : <div className='container-slot-number-placeholder'/>
      }
      <Dropdown>
        <MenuButton slots={{ root: 'span' }} className='options-btn'>
          <Icon name='options' className='options-btn' />
        </MenuButton>
        <Menu slotProps={{ root: { className: 'feeder-options-menu' } }}>
          <FeederOption feeder={feeder} />
        </Menu>
      </Dropdown>
    </div>
  )
}

const FeederSlot = ({ slotNumber, feeder }) => {
  return (
    <div className={`container-slot feeder ${!feeder?.product ? 'empty' : ''}`}>
      <FeederEdges feeder={feeder} />
      <Alert feeder={feeder} />

      <div className='container-info'>
        <ProductName feeder={feeder} />
        <RemainingAmount feeder={feeder} />
        <FeederDetails feeder={feeder} slotNumber={slotNumber} />
      </div>
    </div>
  )
}

export default FeederSlot
