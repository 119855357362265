import React, { Component } from 'react'
import axios from 'axios'
import Modal from '../shared/modal'
import PlusMinusWrapper from '../shared/plusMinusWrapper'
import { PlateContainer } from './types'
import { t } from '../../i18n'

interface Props {
  expandedContainerSlot: number,
  expandedContainer: PlateContainer,
  closeContainer: React.MouseEventHandler,
  refreshStock: Function,
}

interface State {
  currentView: string,
  replacerContainer: PlateContainer
}

const scope = 'stock_control.plate_container'

class PlateContainerModal extends Component<Props, State> {
  blankContainer: PlateContainer

  constructor(props: Props) {
    super(props)

    this.blankContainer = {
      slot_number: props.expandedContainerSlot,
      initial_amount: props.expandedContainer?.initial_amount || 0
    }

    this.state = {
      currentView: 'edit',
      replacerContainer: this.blankContainer,
    }
  }

  changeView = (value) => {
    this.setState({ currentView: value })
  }

  handleFormCancel = () => {
    this.props.closeContainer(null)
  }

  handleReplacerContainerChange = (name, value) => {
    let replacerContainer: PlateContainer = { ...this.state.replacerContainer }

    replacerContainer[name] = value

    this.setState({ replacerContainer })
  }

  handleReplacerContainerIntChange = (name, passedValue) => {
    const value = passedValue ? parseInt(passedValue) : 0
    if (isNaN(value)) return

    this.handleReplacerContainerChange(name, Math.max(0, value))
  }

  resetReplacerContainerForm = () => {
    this.setState({ replacerContainer: this.blankContainer })
  }

  createPlateContainer = () => {
    const { slot_number, initial_amount } = this.state.replacerContainer

    axios.post('/plate_containers?format=json', { plate_container: { slot_number, initial_amount }}).then(({ data }) => {
      this.props.refreshStock()
      this.changeView('saveConfirmation')
      this.resetReplacerContainerForm()
    }).catch(() => {
      this.changeView('saveError')
    })
  }

  modalEditContent = () => {
    const { replacerContainer } = this.state

    return (
      <div className='container-modal edit'>
        <div className='attribute-wrapper' style={{ marginLeft: '-15px' }}>
          <span className='input-label'>Amount</span>
          <PlusMinusWrapper
            step={10}
            value={replacerContainer.initial_amount}
            onChange={(_, value) => this.handleReplacerContainerIntChange('initial_amount', value)}
          >
            <div className='subwrapper'>
              <input
                type='text'
                value={replacerContainer.initial_amount}
                maxLength={3}
                onChange={({ target: { value } }) => this.handleReplacerContainerIntChange('initial_amount', value)}
              />
            </div>
          </PlusMinusWrapper>
        </div>
        <div className='buttons'>
          <button className='btn btn-secondary' onClick={this.handleFormCancel}>Cancel</button>
          <button className='btn btn-primary'
            onClick={this.createPlateContainer}
            disabled={!this.isReplacerContainerValid()}
          >
            Save changes
          </button>
        </div>
      </div>
    )
  }

  modalSaveConfirmation = () => {
    return (
      <div className='container-modal save-result'>
        <div className='title-icon success mt-5'>
          <i className='fa fa-check-circle' />
        </div>
        <div className='heavy-text mt-1 mb-5'>{t('feeder_restored', { scope })}</div>
        <div className='buttons'>
          <button className='btn btn-primary' onClick={this.props.closeContainer}>Continue</button>
        </div>
      </div>
    )
  }

  modalSaveError = () => {
    return (
      <div className='container-modal save-result'>
        <div className='title-icon danger mt-5'>
          <i className='fa fa-times-circle' />
        </div>
        <div className='heavy-text mt-1 mb-5'>Something went wrong</div>
        <div className='buttons'>
          <button className='btn btn-primary' onClick={() => this.changeView('edit')}>Return</button>
        </div>
      </div>
    )
  }

  isReplacerContainerValid = () => {
    const { replacerContainer: { initial_amount } } = this.state

    return !!initial_amount
  }

  headerText = () => {
    const { currentView } = this.state

    switch (currentView) {
      case 'show': return t('product_feeder', { scope })
      case 'edit': return t('replace_feeder', { scope })
      default: return null
    }
  }

  render() {
    const { currentView } = this.state

    const headerText = this.headerText()

    return (
      <Modal onClose={this.props.closeContainer} widen='true'
        headerContent={headerText && <h3 className='header'>{headerText}</h3>}
      >
        {currentView === 'edit' && this.modalEditContent()}
        {currentView === 'saveConfirmation' && this.modalSaveConfirmation()}
        {currentView === 'saveError' && this.modalSaveError()}
      </Modal>
    )
  }
}

export default PlateContainerModal
