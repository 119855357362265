import React from 'react'

const SectionTitle = ({
  rows,
  className,
  dataContains,
  paddingLeft = 3
}: {
  rows: string[],
  className?: string,
  dataContains?: string,
  paddingLeft?: number
}) => (
  <div className={`section-heading ${className || ''}`} data-contains={dataContains}>
    <div className={`section-heading-row ml-${paddingLeft}`}>{rows[0]}</div>
    <div className={`section-heading-row bold ml-${paddingLeft}`}>{rows[1]}</div>
  </div>
)

export default SectionTitle
