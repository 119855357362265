import React from 'react'
import classNames from 'classnames'

const Tab = ({ label, index, currentTabIndex, hasAlert, switchTab }) => {
  const classes = classNames(
    'tab', {
      'active': index === currentTabIndex,
      'alert-indicator': hasAlert
    }
  )

  return (
    <div className={classes} onClick={() => switchTab(index)}>
      {label}
    </div>
  )
}

export default Tab
