import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import PlusMinusWrapper from './plusMinusWrapper.tsx'

const IntervalInput = ({ label, value, name, onChange, afterText, mandatory, tooltipText = '', maxLength = 3, step= 5, classNames }) => {
  const mandatoryClass = mandatory ? 'mandatory' : ''
  return (
    <div className={`form-group adjustable-group ${classNames}`}>
      <div className='form-control'>
        <div className={`black-label ${mandatoryClass}`}>
          {label}
        </div>
        <Tooltip title={tooltipText} placement='top' className='task-parameter' arrow>
          <div>
            <PlusMinusWrapper
              name={name}
              step={step}
              value={value}
              onChange={(_, newValue) => onChange(name, newValue)}
            >
              <div className='subwrapper'>

                <input
                  className={afterText ? 'with-unit-offset' : ''}
                  maxLength={maxLength}
                  type='number'
                  value={value}
                  onChange={({ target: { value: newValue } }) => onChange(name, newValue)}
                />
                <span className='input-unit'>{afterText}</span>
              </div>
            </PlusMinusWrapper>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default IntervalInput
