import React from 'react'
import Modal from './modal'
import Icon from './icon'

export const DeleteModal = ({ children, object, handleDelete, closeModal, deleteSuccessful, deleteFailMessage }) => {
  const renderObjectType = () => {
    return object.class_name === 'ProductCategory' ? 'Category' : object.class_name
  }

  const renderTitle = () => {
    if (deleteSuccessful) {
      return <h3 className="font-weight-bold">Product deleted</h3>
    } else if (deleteFailMessage) {
      return <h3 className="font-weight-bold">Failed to delete</h3>
    } else {
      return <h3 className="mt-4 mb-1 font-weight-bold">Delete {renderObjectType()}!</h3>
    }
  }

  const renderButtons = () => {
    if (deleteSuccessful || deleteFailMessage) {
      return (
        <div className="mt-5 mb-5">
          <button type="button" className='btn btn-primary' onClick={closeModal}>
            Continue
          </button>
        </div>
      )
    } else {
      return (
        <div className="mt-5 mb-5">
          <button type="button" className='btn btn-danger mr-3' onClick={() => handleDelete(object)}>
            Delete
          </button>
          <button type="button" className='btn btn-primary' onClick={closeModal}>
            Cancel
          </button>
        </div>
      )
    }
  }

  const renderText = () => {
    if (deleteSuccessful) {
      return (
        <div className="mt-4">
          <div className="text-center">{renderObjectType()} was successfully deleted</div>
        </div>
      )
    } else if (deleteFailMessage) {
      return (
        <div className="mt-4">
          <div className="text-center">{renderObjectType()} could not be deleted - {deleteFailMessage}</div>
        </div>
      )
    } else {
      return (
        <div className="mt-4">
          <div className="text-center">Are you sure you want to proceed?</div>
          <div className="text-center">This cannot be undone!</div>
        </div>
      )
    }
  }

  const renderHeaderIcon = () => {
    if (deleteSuccessful) {
      return (
        <div className="green-check mb-5">
          <Icon name='check' className='mb-1' />
        </div>
      )
    } else if (deleteFailMessage) {
      return (
        <div className="failed-icon mb-5">
          <Icon name='failed' className='mb-1' />
        </div>
      )
    } else {
      return <Icon name='bigDeleteIcon' className='mb-1' />
    }
  }

  const renderObjectData = () => {
    return (
      <React.Fragment>
        <div className="file-placeholder mt-3 mb-1" style={{ width: 'unset' }}>
          {object.file_url && <img src={object.file_url} /> || <Icon name='categoryPlaceholder' className='big-image' />}
        </div>
        <h3 className="mt-3 font-weight-bold">{object.name}</h3>
      </React.Fragment>
    )
  }

  return (
    <Modal onClose={closeModal} widen={true}>
      <div className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column' }}>
        {renderHeaderIcon()}
        {renderTitle()}
        {renderObjectData()}
        {renderText()}
        {renderButtons()}
      </div>
    </Modal>
  )
}
