import React from "react";

const SortableTableHead = ({ possibleColumns, sortableColumns, modelIndexBasePath, children }) => {

  const sortOptions = (column) => {
    if (!column.sort_options || !column.sort_options.selected) {
      return <i className={`bi bi-arrow-down-up`} style={{opacity: '0.2', marginLeft: '2px'}} />;
    }
    const sortingAsc = (column.sort_options.sort_order === 'asc')
    return <i className={`bi ${sortingAsc ? 'bi-arrow-up' : 'bi-arrow-down' }`} />
  }

  const sortLink = (column, modelIndexBasePath) => {
      return `${modelIndexBasePath}?sort_by=${column.column_name}&sort_order=${reverseSortOrder(column.sort_options.sort_order)}`
  }

  const reverseSortOrder = (sortOrder) => {
    return (sortOrder === 'asc') ? 'desc' : 'asc';
  }

  const columnSortable = (column) => {
    return sortableColumns.includes(column);
  }

  return (
    <thead className='thead-robis'>
      <tr>
        {possibleColumns.filter(c => c.selected).map(column => {
          return <th className={column.column_name} key={column.column_label}>
            {columnSortable(column) ?
              <a href={sortLink(column, modelIndexBasePath)}> {column.column_label} {sortOptions(column)}</a>
              : <span>{column.column_label }</span> }
          </th>
        })}
        {children}
      </tr>
    </thead>
  )
}

export { SortableTableHead }
