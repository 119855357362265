const Product = () => {
  return {
    id: null,
    name: '',
    location: {},
    attachment: null,
    file_url: null,
    group_id: {},
    fraction_id: {},
    unit_id: {},
    alergen_ids: [],
    alert: 0,
    kcal: 0,
    kjoul: 0,
    notes: '',
    processing_loss: 0,
    volume_to_weight_multiplier: 0,
    feeder_type: ''
  }
}

export default Product
