import React, { Fragment } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import Icon from '../../shared/icon'

const operationIcons = {
  set_temperature: 'thermometer',
  set_cooking_mode: 'settings',
  wash_tray: 'shower',
}

const operationNames = {
  set_temperature: 'Temperature',
  set_cooking_mode: 'Cooking mode',
  wash_tray: 'Wash tray',
}

interface Operation {
  operation: string,
  name: string,
}

interface OperationItemProps extends Operation {
  isPlaceholder?: boolean,
  isDragging?: boolean,
}

const DraggableOperation = ({ index, ...operation }) => {
  return (
    <Draggable draggableId={JSON.stringify(operation)} index={index}>
      {(provided, snapshot) =>
        <Fragment>
          <div
            className='draggable-operation'
            data-operation={operation.operation}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <OperationItem {...operation as Operation} isDragging={snapshot.isDragging} />
          </div>
          {snapshot.isDragging &&
            <OperationItem {...operation as Operation} isPlaceholder={true} />
          }
        </Fragment>
      }
    </Draggable>
  )
}

const OperationItem: React.FC<OperationItemProps> = ({
  operation, name, isPlaceholder, isDragging
}) => {

  const operationClassNames = classNames(
    'operation-container',
    {
      'placeholder': isPlaceholder
    }
  )

  return (
    <div className={operationClassNames}>
        <div className='operation'>
          <Icon name={operationIcons[operation]} className='operation-icon' color='#fff' />
        </div>
      <div className='operation-name'>{name}</div>
    </div>
  )
}

export { operationIcons, operationNames }
export default DraggableOperation
