import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Collapse from '@mui/material/Collapse'
import { Box, Popper } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import map from 'lodash/map'
import Icon from '../shared/icon'
import { Product } from './types'

interface ProductCategoryTree {
  id: number,
  name: string,
  description: string,
  image_url: string,
  ancestry_path: string,
  children: ProductCategoryTree[],
  products: Product
}

const ProductCategory = ({ category, setSelectedOption, indent = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="product-category-collapse" style={{ paddingLeft: `${indent * 20}px` }}>
      <div className="product-category-container" onClick={() => setIsExpanded(!isExpanded)}>
        {category.ancestry_path && <span className="category-ancestry">{`${category.ancestry_path} / `}</span>}
        <span className="category-name">{category.name}</span>
        <Icon name={isExpanded ? 'arrowUp' : 'arrowDown'}/>
      </div>
      <Collapse in={isExpanded}>
        {map(category.children, child =>
          <ProductCategory category={child} key={`category-${child.id}`} indent={indent + 1} setSelectedOption={setSelectedOption}/>
        )}
        {map(category.products, product =>
          <div
            className="product-container"
            style={{ paddingLeft: `${indent + 20}px` }}
            key={`product-${product.id}`}
            onClick={() => setSelectedOption(product)}
          >
            <img className="product-image" src={product.image_url_medium}/>
            <span>{product.name}</span>
          </div>
        )}
      </Collapse>
    </div>
  )
}

const SelectProductDropdown = ({ productType, selectedOption, setSelectedOption }) => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null)
  const [solidProductCategories, setSolidProductCategories] = useState<ProductCategoryTree[]>([])
  const [liquidProductCategories, setLiquidProductCategories] = useState<ProductCategoryTree[]>([])
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setAnchorElement(anchorElement ? null : anchorRef.current);
  };

  const closeDropdown = () => {
    setAnchorElement(null)
  }

  useEffect(() => {
    fetchProductsAndCategories()
  }, [])

  const fetchProductsAndCategories = () => {
    axios.get('/stocks/product_categories_with_products?format=json').then(({ data }) => {
      setSolidProductCategories(data.solid_product_categories)
      setLiquidProductCategories(data.liquid_product_categories)
    })
  }

  const categories = productType == 'solid' ? solidProductCategories : liquidProductCategories

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popper' : undefined;

  return(
    <ClickAwayListener onClickAway={closeDropdown}>
      <div className="product-select-dropdown" style={{ position: 'relative' }}>
        <div className="select-dropdown-placeholder" aria-describedby={id} onClick={handleClick} onBlur={handleClick} ref={anchorRef}>
          <span className="label">{selectedOption ? selectedOption.name : 'Product'}</span>
          <Icon name={open ? 'arrowUp' : 'arrowDown'}/>
        </div>
        <Popper style={{zIndex: '1051', width: '98%'}} placement="bottom" id={id} open={open} anchorEl={anchorElement} disablePortal={true}>
          <Box style={{zIndex: '1051', overflow: 'auto'}} sx={{ bgcolor: 'background.paper' }} className="dropdown-content">
            {map(categories, category =>
              <ProductCategory
                category={category}
                key={`category-${category.id}`}
                setSelectedOption={(product) => {setSelectedOption(product); closeDropdown()}}
              />
            )}
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default SelectProductDropdown
