import React, { useState, useEffect } from 'react'
import LocationForm from './LocationForm'
import axios from 'axios'

const LocationEditForm = (props) => {
  const [location, setLocation] = useState(null)
  const [saveSuccessful, setSaveSuccessful] = useState(null)
  const [saveText, setSaveText] = useState('')

  useEffect(() => {
    axios
      .get(Routes.api_internal_location_path(props.id, { format: 'json' }))
      .then((resp) => setLocation(resp.data))
  }, [])

  const saveLocation = (location, onFinished) => {
    const url = Routes.api_internal_location_path(location.id, {
      format: 'json'
    })
    axios
      .patch(url, location)
      .then((resp) => {
        setSaveSuccessful(true)
        setSaveText('Save was successful.')
        onFinished(true, null)
        setLocation(resp.data)
      })
      .catch((error) => {
        setSaveSuccessful(false)
        setSaveText('Save failed. Please review the values.')
        onFinished(false, error.response.data)
      })
  }

  const renderSaveMessage = () =>
    saveSuccessful === null ? null : (
      <div className={`alert alert-${saveSuccessful ? 'success' : 'danger'}`}>
        {saveText}
      </div>
    )

  if (!location) {
    return <></>
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        {renderSaveMessage()}
        <LocationForm
          location={location}
          currencies={props.currencies}
          onSubmit={saveLocation}
        />
      </div>
    </div>
  )
}

export default LocationEditForm
