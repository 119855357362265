import React from 'react'
import { Box, Typography } from '@mui/material'
import filter from 'lodash/filter'
import Icon from '../../shared/icon'
import Modal from '../../shared/modal'
import { ReplenishmentOrderItem } from './OrderItem'
import { t } from 'src/i18n'
import moment from 'moment'
import ReplenishmentOrderExport from './OrderExport'

const scope = "replenishment.sidebar"

export const ReplenishmentOrderPlacedModal = ({ showOrderPlacedModal, replenishmentOrder, handleClose, location }) => {

  const productReplenishmentsOfType = (type: 'solid' | 'liquid') => {
    const productReplenishments = filter(replenishmentOrder.productReplenishments, replenishment =>
      replenishment.containerType === type && !replenishment.destroy
    )
    const header = type.charAt(0).toUpperCase() + type.slice(1)

    if(!productReplenishments.length)
      return
    return (
      <div>
        <span className='sidebar-heading replenishment-order-subcategory'>{header} ingredients</span>
        {productReplenishments.map((replenishment, index) =>
          <ReplenishmentOrderItem
            key={`${replenishment.product.id}-${index}`}
            type="product"
            orderItem={replenishment}
            index={index}
            showRemove={false}
          />
        )}
      </div>
    )
  }

  const plateReplenishments = () => {
    if(!replenishmentOrder.plateReplenishments.length)
      return
    const plateReplenishments = replenishmentOrder.plateReplenishments.filter(replenishment => !replenishment.destroy)
    return (
      <div>
        <span className='sidebar-heading replenishment-order-subcategory'>{t('bowls', { scope })}</span>
        {plateReplenishments.map((replenishment, index) =>
          <ReplenishmentOrderItem
            key={`${replenishment.slotNumber}-${index}`}
            type="plates"
            orderItem={replenishment}
            index={index}
            showRemove={false}
          />
        )}
      </div>
    )
  }

  return (
    <Modal onClose={handleClose}>
      <Box>
        <div className="replenishment-order-modal">
        <Icon name="success"/>
        <br/>
        <Typography variant="h6" component="h2">
          {showOrderPlacedModal.orderState == 'placed'
            ? t('order_placed_message', { scope, orderId: replenishmentOrder.id })
            : t('order_updated_message', { scope, orderId: replenishmentOrder.id })
          }
        </Typography>
        <div className="location-container">
          <span>{t('replenishment.order_placed_modal.location')}</span><span className="location">{location}</span>
        </div>
        <br/>
        {productReplenishmentsOfType('solid')}
        {productReplenishmentsOfType('liquid')}
        {plateReplenishments()}
        <div className="date-container">
          <span>{t('replenishment.order_placed_modal.date')}</span><span className="date">{moment(replenishmentOrder.deliveryDate).format('M/D/YYYY')}</span>
        </div>
        <br/>
        <ReplenishmentOrderExport replenishmentOrder={replenishmentOrder}/>
        <div className="modal-btn">
          <button className="btn btn-primary" onClick={handleClose}>{t('close')}</button>
        </div>
        </div>
      </Box>
    </Modal>
  )
}
