import React, { PureComponent } from 'react'

class Loading extends PureComponent {
  render = () => (
    <div
      className={`spinner-border robis-spinner ${this.props.position || 'position-absolute'}`}
      role="status">
      <span className="sr-only">Loading...</span>
    </div>
  )
}

export default Loading
