import React from 'react'
import classNames from 'classnames'
import Icon from '../../shared/icon'
import { t } from 'src/i18n'
import MissingProductImage from 'images/missing_image.svg'

const scope = 'replenishment.sidebar'

const ProductOrderItem = ({ orderItem, index, showRemove, removeReplenishment }) => {

  const actionName = () => {
    return t(isRefill() ? 'refill' : 'product_change', { scope })
  }

  const isRefill = () => {
    return orderItem.product?.id == orderItem.previousProduct?.id
  }

  const showPreviousProduct = () => {
    return !isRefill()
  }

  const itemClassNames = classNames(
    'replenishment-order-item',
    {
      'with-previous-product': showPreviousProduct(),
      'first': index === 0,
    }
  )

  return (
      <div className={`${itemClassNames}`}>
        <div className="image">
          {showPreviousProduct() && <img className="old-product-img" src={orderItem.previousProduct?.imageUrl || 'images/product-placeholder.svg'}></img>}
          <img className="new-product-img" src={orderItem.product.imageUrl || MissingProductImage}/>
          <span className="slot-number">#{orderItem.humanSlotNumber}</span>
        </div>
        <div className="info">
          {showPreviousProduct() && <span className="previous-product">{orderItem.previousProduct?.name || t('empty_container', { scope })}</span>}
          <span className="new-product">{orderItem.product.name || t('empty_container', { scope })}</span>
          <span className="action-name">{actionName()}</span>
        </div>
        {showRemove &&
          <div className="delete-item">
            <Icon name='deleteIcon' onClick={() => removeReplenishment(orderItem.id, 'product')}/>
          </div>
        }
        <hr/>
      </div>
  )
}


const PlatesOrderItem = ({ orderItem, index, showRemove, removeReplenishment }) => {

  const itemClassNames = classNames(
    'replenishment-order-item',
    {
      'first': index === 0,
    }
  )

  return (
    <div className={`${itemClassNames}`}>
      <div className="image">
        <div className="plates-icon-container">
          <Icon name='plate' color='white'/>
        </div>
        <span className="slot-number">#{orderItem.slotNumber}</span>
      </div>
      <div className="info">
        <span className="new-product">{t('replenishment.bowls.bowl_stand', {slotNumber: orderItem.slotNumber})}</span>
        <span className="action-name">{orderItem.amount} pcs</span>
      </div>
      {showRemove &&
        <div className="delete-item">
         <Icon name='deleteIcon' onClick={() => removeReplenishment(orderItem.id, 'plates')}/>
        </div>
      }
      <hr/>
    </div>
)
}

export const ReplenishmentOrderItem = ({ orderItem, index, type, showRemove = true, removeReplenishment = (replenishmentItemId, type) => {}}) => {
  const props = {
    orderItem,
    index,
    showRemove,
    removeReplenishment
  }

  if(type == 'product') {
    return (<ProductOrderItem {...props}/>)
  } else {
    return (<PlatesOrderItem {...props}/>)
  }
}
