import React, { Fragment, PureComponent } from 'react'
import { Formik } from 'formik'
import Tooltip from '@mui/material/Tooltip'
import DurationInput from '../shared/durationInput'
import { Attachment, MenuCategory } from './types'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import map from 'lodash/map'
import find from 'lodash/find'
import { LocalizationContext } from '../shared/contexts'
import { t } from '../../i18n'
import PORTION_TYPES from '../shared/recipePortionTypes'

const recipeTypeOptions = map(PORTION_TYPES, type => (
  { label: t(`recipes.${type}`), value: type }
))

interface Props {
  id?: number,
  ref: string,
  name: string,
  price: string,
  preparation_time: string,
  portion_type: string,
  attachment: Attachment,
  removeAttachment: Function,
  imageWithinForm: string,
  imageUrl: string,
  handleChange: Function,
  fixErrorState: Function,
  errorMessages: {},
  menu_categories: MenuCategory[],
  menuCategoriesOptions: MenuCategory[],
}

class RecipeEditForm extends PureComponent<Props> {
  renderErrors = (field) => {
    return (
      <Fragment>
        {map(this.props.errorMessages[field], (errorMessage, i) =>
          <span className="error-text" style={{marginTop: `${i * 15}px`}}>{errorMessage}</span>
        )}
      </Fragment>
    )
  }

  handleInputChange = (name, value) => {
    this.props.handleChange(name, value)
    this.props.fixErrorState(name)
  }

  renderInput = (name, type, label, value, handleBlur, customBlur = false) => {
    return (
      <div className="recipe-input-wrapper">
        <label style={{ textTransform: 'capitalize' }}>
          {label}
        </label>
        <input
          id={name}
          type={type}
          name={name}
          onBlur={({ target: { value } }) => customBlur ? handleBlur(value) : handleBlur}
          value={value}
          checked={value}
          onChange={({ target: { name, value } }) => {this.handleInputChange(name, value)}}
          className={`form-control inverted ${this.props.errorMessages[name] ? 'error-state' : ''}`}
          placeholder={`Enter ${label}`}
        />
        {this.props.errorMessages[name] && this.renderErrors(name) }
      </div>
    )
  }

  renderCategoriesSelectValue = (selectedCategoryIds) => {
    if (selectedCategoryIds.length > 0) {
      const categoryNames = map(selectedCategoryIds, categoryId =>
        find(this.props.menuCategoriesOptions, o => o.value === categoryId).label
      )

      return categoryNames.join(', ')
    } else {
      return t('categories')
    }
  }

  renderSelect = (field, selectOptions, handleChange, value) => {
    if (!selectOptions) return null

    return (
      <Select
        className='recipe-select'
        multiple
        value={value}
        onChange={(opt) => handleChange(field, opt.target.value)}
        displayEmpty={true}
        input={<OutlinedInput />}
        renderValue={this.renderCategoriesSelectValue}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
              width: 250,
            },
          }
        }}
        sx={{ color: value.length > 0 ? 'black' : '#757575' }}
      >
        {selectOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    )
  }

  render = () => (
    <Formik
      ref='formik'
      enableReinitialize
      onSubmit={() => {}}
      initialValues={{
        id: this.props.id,
        name: this.props.name,
        price: this.props.price,
        preparation_time: this.props.preparation_time,
        attachment: this.props.attachment,
        imageWithinForm: this.props.imageWithinForm,
        imageUrl: this.props.imageUrl,
        menu_categories: this.props.menu_categories,
        portion_type: this.props.portion_type
      }}
    >
      {({
        values,
        errors,
        touched,
        status,
        handleBlur,
        handleSubmit,
        setStatus,
        setFieldValue,
      }) => {
        const handleImageChange = ({ target: { files } }) => {
          if (!files || !files[0]) return

          const { handleChange } = this.props

          handleChange('imageWithinForm', files[0].name)
          handleChange('imageUrl', URL.createObjectURL(files[0]))
        }

        const renderFileUpload = () => {
          const { attachment } = this.props
          const imageUrl  = values.imageUrl || (attachment && attachment.file_url) || '/images/missing_image.svg'

          return (
            <div className='custom-file'>
              <input
                style={{ display: 'none' }}
                id='imageWithinForm'
                type='file'
                ref='imageWithinForm'
                name='imageWithinForm'
                onChange={handleImageChange}
                aria-describedby='imageWithinForm'
              />
              <Tooltip title='Change image' placement='top' className='task-parameter' arrow>
                <label htmlFor='imageWithinForm' className='pointer'>
                  <img src={imageUrl} className={`image-preview ${this.props.errorMessages['attachment'] ? 'error-state' : ''}`}/>
                </label>
              </Tooltip>
            </div>
          )
        }

        const renderRecipeTypeOptions = () => {
          return (
            <div className='form-group mt-4'>
              <label>{t('recipes.type')}</label>
              <div className='mt-1'>
                {recipeTypeOptions.map((option, index) => (
                  <div
                    onClick={() => { handleTypeChange(option.value) }}
                    key={option.value}
                    className={`d-inline ${index === 0 ? 'ml-3' : 'ml-5'}`}
                  >
                    <input
                      onChange={() => { handleTypeChange(option.value) }}
                      type='radio'
                      name={option.value}
                      checked={this.props.portion_type === option.value}
                    />
                    <label
                      htmlFor={option.value}
                      className='pointer ml-1'
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )
        }

        const handlePriceChange = (price) => {
            this.props.handleChange('price', Number(price).toFixed(2))
        }

        const handleTypeChange = (portionType) => {
          this.props.handleChange('portion_type', portionType)
        }

        return (
          <form className='recipe-form' autoComplete='off'>
            <div className='recipe-header'>
              <div className='form-group recipe-image'>
                {renderFileUpload()}
              </div>
              <div className='form-group recipe-name'>
                {this.renderInput('name', 'text', 'Name', this.props.name, handleBlur)}
                {renderRecipeTypeOptions()}
              </div>
              <div className="form-group recipe-group">
                <label>Menu category</label>
                {this.renderSelect('menu_categories', this.props.menuCategoriesOptions, this.props.handleChange, values['menu_categories'])}
              </div>
              <div className='form-group recipe-price'>
                <LocalizationContext.Consumer>
                  {({ currency }) =>
                    this.renderInput('price', 'number', `Price (${currency.code})`, this.props.price, handlePriceChange, true)
                  }
                </LocalizationContext.Consumer>
              </div>
              <div className='form-group recipe-preparation-time recipe-input-wrapper'>
                <label>Preparation Time</label>
                <DurationInput
                  className={`form-control inverted ${this.props.errorMessages['preparation_time'] ? 'error-state' : ''}`}
                  value={values.preparation_time}
                  onChange={(value) => this.props.handleChange('preparation_time', value)}
                />
                  {this.props.errorMessages['preparation_time'] && this.renderErrors('preparation_time') }
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default RecipeEditForm
