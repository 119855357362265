import React from 'react'
import Icon from '../shared/icon'

const EmptyTablePlaceholder = ({ heading, subtext = '', className='' }) => {
  return(
    <div className={`empty-table-layout ${className}`}>
      <div className='empty-table-content'>
        <Icon className="flex-1 empty-table-icon" name='folder_icon'/>
        <div className="flex-1 empty-table-text">
          <h4>{heading}</h4>
          <span>{subtext}</span>
        </div>
      </div>
    </div>
  )
}

export default EmptyTablePlaceholder
