import React from 'react'
import map from 'lodash/map'
import times from 'lodash/times'
import find from 'lodash/find'
import classNames from 'classnames'
import Icon from '../shared/icon'
import { PlateContainer, PlateReplenishment, ReplenishmentOrder } from './types'
import { t } from "../../i18n";

const PLATE_CONTAINER_SLOT_COUNT = 2

interface PlateContainerSlotProps {
  slotNumber: number,
  container: PlateContainer,
  expandPlateContainer: Function,
  replenishmentView?: boolean,
  replenishedSlot?: PlateReplenishment
}

const AmountBar = ({ replenishedSlot, replenishmentView, maxAmount, remainingAmount, replenishedAmount, amountStatus,  }) => {

  const maxAmountBarSteps = 25

  const remainingBarSteps = Math.ceil(remainingAmount * (maxAmountBarSteps / maxAmount))

  let replenishedSteps = Math.ceil(replenishedAmount * (maxAmountBarSteps / maxAmount))
  const emptyBarSteps = maxAmountBarSteps - (remainingBarSteps + replenishedSteps)

  if(emptyBarSteps < 0) {
    replenishedSteps = replenishedSteps + emptyBarSteps // since Ceil is used for calculation it might happen that there is 1 step too much
  }

  return (
    <>
      {(!!replenishedSlot && replenishmentView) ?
        <>
          <div className={`amount-bar status-replenished`}>
            {times(emptyBarSteps, i =>
              <div key={i} className='amount-bar-step replenished-empty'></div>
            )}
          </div>

          <div className={`amount-bar status-replenished`}>
            {times(replenishedSteps, i =>
              <div key={i} className='amount-bar-step'></div>
            )}
          </div>

          <div className={`amount-bar status-${amountStatus}`}>
            {times(remainingBarSteps, i =>
              <div key={i} className='amount-bar-step'></div>
            )}
          </div>
        </> :
        <div className={`amount-bar status-${amountStatus}`}>
          {times(maxAmountBarSteps, i =>
            <div key={i} className={`amount-bar-step ${i < (maxAmountBarSteps - remainingBarSteps) ? 'empty' : ''}`}></div>
          )}
        </div>
      }
    </>
  )
}

const PlateContainerSlot: React.FC<PlateContainerSlotProps> = ({
  slotNumber, container, expandPlateContainer, replenishmentView, replenishedSlot
}) => {

  const maxAmount = container?.initial_amount || 100
  const remainingAmount = container?.remaining_amount || 0
  const replenishedAmount = replenishedSlot ? (replenishedSlot.amount - remainingAmount) : 0

  const amountStatus = container?.remaining_amount_status || 'alert'

  const containerClassNames = classNames(
    'plate-container',
    {
      'replenishment': replenishmentView,
      'replenished': !!replenishedSlot && replenishmentView,
    }
  )

  return (
    <div className={containerClassNames}  onClick={() => expandPlateContainer(slotNumber)}>
      <AmountBar
        replenishedSlot={replenishedSlot}
        replenishmentView={replenishmentView}
        maxAmount={maxAmount}
        remainingAmount={remainingAmount}
        replenishedAmount={replenishedAmount}
        amountStatus={amountStatus}
      />
      <div className='amount'>
        <div className='remaining-amount'>
          <span className={`status-${amountStatus}`}>{remainingAmount}</span>
          <span className='status-replenished'> {(!!replenishedSlot && replenishmentView) ? ` + ${replenishedAmount}` : ''}</span>
          <span>{` / ${maxAmount}`}</span>
        </div>
        <div>{t('recipes.bowls')}</div>
      </div>
      <div className="edit-plate-container">
        <div className='plate-icon'>
          <Icon name='plate' color={(!!replenishedSlot && replenishmentView) ? '#CA56F3' : "#000000"}/>
        </div>
        <div className='container-name'>{`Stand #${slotNumber}`}</div>
      </div>
    </div>
  )
}
interface PlatesViewProps {
  plateContainers: Record<number, PlateContainer>,
  expandPlateContainer: Function,
  replenishmentView: boolean,
  draftReplenishmentOrder: ReplenishmentOrder
}

const PlatesView: React.FC<PlatesViewProps> = ({
  plateContainers, expandPlateContainer, replenishmentView, draftReplenishmentOrder
}) => {
  const plateSlots = map(Array.from(Array(PLATE_CONTAINER_SLOT_COUNT).keys()), slotIndex => {
    const slotNumber = slotIndex + 1

    return { slotNumber, container: plateContainers[slotNumber] }
  })

  const replenishedSlot = (slot) => {
    return find(draftReplenishmentOrder.plateReplenishments, repl => repl.slotNumber == slot)
  }

  return (
    <div className='tab-content plates'>
      <div className='plates-wrapper'>
        {map(plateSlots, slot => (
          <PlateContainerSlot
            key={slot.slotNumber}
            slotNumber={slot.slotNumber}
            container={slot.container}
            expandPlateContainer={expandPlateContainer}
            replenishmentView={replenishmentView}
            replenishedSlot={replenishedSlot(slot.slotNumber)}
          />
          )
        )}
      </div>
    </div>
  )
}

export default PlatesView
