import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import { t } from '../../../i18n'

const DateTimeFilters = () => {
  const [timeFrom, setTimeFrom] = useState<Moment | null>(null)
  const [timeUntil, setTimeUntil] = useState<Moment | null>(null)

  const getQueryParam = (param) => {
    const url = new URL(location.toString())
    return url.searchParams.get(param) || null
  }

  const initializeFilters = () => {
    const timeFromParam = getQueryParam('time_from')
    setTimeFrom(timeFromParam ? moment(timeFromParam) : null)

    const timeUntilParam = getQueryParam('time_until')
    setTimeUntil(timeUntilParam ? moment(timeUntilParam) : null)
  }

  useEffect(() => {
    initializeFilters()
  }, [])

  return (
    <div className='d-inline-block float-right'>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDateTimePicker
          className='mr-2'
          label={t('scheduler_run_reports.from')}
          value={timeFrom}
          onChange={time => setTimeFrom(time)}
        />
        <input type='hidden' name='time_from' value={timeFrom ? timeFrom.toISOString() : ''} />
        <DesktopDateTimePicker
          label={t('scheduler_run_reports.to')}
          value={timeUntil}
          onChange={time => setTimeUntil(time)}
        />
        <input type='hidden' name='time_until' value={timeUntil ? timeUntil.toISOString() : ''} />
      </LocalizationProvider>
    </div>
  )
}

export default DateTimeFilters
