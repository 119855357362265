import React, { PureComponent, Fragment } from 'react'
import classNames from 'classnames'
import axios from 'axios'
import moment from 'moment'
import find from 'lodash/find'
import map from 'lodash/map'

import MenuMonthFilterControls from './menuMonthFilterControls'
import MenuModal from './menuModal'
import Calendar from '../shared/calendar'
import Loading from '../shared/loading'
import FlashNotification from '../shared/flashNotification'
import SectionTitle from '../shared/sectionTitle'

class MenuTable extends PureComponent {
  state = {
    menus: [],
    recipes: [],
    flashType: '',
    loading: false,
    date: null,
    menuItem: null,
    flashMessage: '',
    modalOpen: false,
    flashOpen: false,
    year: moment().startOf('month').year(),
    month: moment().startOf('month').month() + 1,
  }

  componentDidMount = () => {
    this.loadRecipes()
    this.loadMenus()
  }

  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })
  closeModal = () => this.setState({ modalOpen: false, menuRecipe: null })
  openModalForDate = (date, menuItem) => this.setState({ modalOpen: true, date, menuItem })
  setFlashMessage = (flashType, flashMessage, timeOut=3000) => (
    this.setState({ flashType, flashMessage, flashOpen: true },
      () => setTimeout(() => this.setState({ flashOpen: false }), timeOut)
    )
  )

  mergeUrl = () => Routes.merge_api_internal_menus_path()
  destroyUrl = (id) => Routes.api_internal_menu_path(id)

  loadRecipes = () => {
    this.startLoading()
    axios.get(
      Routes.api_internal_recipes_path()
    ).then(({ data }) =>
      this.setState({ recipes: data, loading: false })
    ).catch((error) =>
      this.setFlashMessage('danger', 'Error accured while loading recipes!')
    )
  }

  updateYearAndMonthFilter = (year, month) => {
    this.setState({ year, month }, () => this.loadMenus())
  }

  loadMenus = () => {
    const { year, month } = this.state
    this.startLoading()
    axios.get(
      Routes.within_month_api_internal_menus_path(`${year}-${month}`)
    ).then(({ data }) => {
      const menus = data
      this.setState({ menus, loading: false })
    }).catch((error) =>
      this.setFlashMessage('danger', 'Error accured while loading menus!')
    )
  }

  saveMenu = (data) => {
    const { dates, menu: { id, recipe_id, recipe_ids } } = data
    this.startLoading()
    axios.post(this.mergeUrl(), { menu: { dates, recipe_ids } }).then((response) => {
      this.stopLoading()
      this.closeModal()
      this.setFlashMessage('success', `Menu successfully ${id ? 'updated' : 'created'}`)
      this.loadMenus()
    }).catch((error) => {
      this.stopLoading()
      this.setFlashMessage('danger', error.response.data.error)
    })
  }

  destroyMenu = (id) => {
    if (!confirm('Do you really want to delete this menu?')) return

    this.startLoading()
    axios.delete(this.destroyUrl(id)).then((response) => {
      this.stopLoading()
      this.closeModal()
      this.setFlashMessage('success', `Menu successfully deleted`)
      this.loadMenus()
    }).catch((error) => {
      this.stopLoading()
      this.closeModal()
      this.setFlashMessage('danger', error.response.data.error)
    })
  }

  renderModal = () => this.props.isMenuCreateAllowed && this.state.modalOpen && (
    <MenuModal
      saveMenu={this.saveMenu}
      recipes={this.state.recipes}
      closeModal={this.closeModal}
      destroyMenu={this.destroyMenu}
      date={this.state.date}
      menuItem={this.state.menuItem}
      menuRecipes={this.state.menuRecipes || []}
    />
  )

  renderDateCell = (date, index) => {
    const { menus } = this.state

    const currentDate = moment().format('YYYY-MM-DD')
    const menuItem = find(menus, menu => menu.date === date)

    const classes = classNames(
      'menu-item',
      {
        'menu-item-disabled': !date,
        'menu-item-blank': !menuItem,
        'menu-item-present': menuItem,
        'menu-item-today': date === currentDate
      }
    )

    return (
      <td
        key={index}
        className={classes}
        onClick={() => date && this.openModalForDate(date, menuItem)}
        data-contains={date && `calendar-menu-item-${date}`}
      >
        {date && this.renderDateCellContent(date, menuItem)}
      </td>
    )
  }

  renderDateCellContent = (date, menuItem) => (
    <Fragment>
      <div>
        <span className='menu-item-date'>{moment(date).format('DD.MM')}</span>
      </div>
      {menuItem &&
        map(menuItem.menu_recipes, mr =>
          <p key={mr.id} className='menu-item-recipe-name'>{mr.recipe_name}</p>
        )
      }
      {!menuItem && this.renderAddButton(date)}
    </Fragment>
  )

  renderAddButton = (date) => {
    if (moment(date) < moment().startOf('day')) return

    return <div className='menu-item-add-button'>
      <i className='fa fa-plus-circle'/>
      <a>Add</a>
    </div>
  }

  renderWeekFilter = () => (
    <MenuMonthFilterControls
      year={this.state.year}
      month={this.state.month}
      updateYearAndMonthFilter={this.updateYearAndMonthFilter}
    />
  )

  renderFlashNotification = () => this.state.flashOpen && (
    <FlashNotification type={this.state.flashType} message={this.state.flashMessage} />
  )
  renderLoading = () => this.state.loading && <Loading />

  refreshMenu = () => {
    this.startLoading()
    axios.post('/api/internal/menus/menu_changed').then(res => {
      this.setFlashMessage('success', 'Menu webhook successfully called')
      this.stopLoading()
    }).catch(err => {
      this.setFlashMessage('danger', 'Menu webhook failed')
      this.stopLoading()
    })
  }

  render = () => {
    const { month, year } = this.state

    return (
      <Fragment>
        {this.renderFlashNotification()}
        {this.renderLoading()}
        <div className='d-flex mb-3'>
          <SectionTitle className='flex-1' rows={['Menu', 'Calendar']} />
          <div className='flex-1'>{this.renderWeekFilter()}</div>
          <div className='flex-1'></div>
        </div>
        <div>
          <button className='btn btn-primary mb-2' onClick={this.refreshMenu}>Webhook menu</button>
        </div>
        <Calendar
          className='text-center menu-table table-fixed'
          classNameThead='thead-robis'
          month={month}
          year={year}
          renderDateCell={this.renderDateCell}
        />
        {this.renderModal()}
      </Fragment>
    )
  }
}

export default MenuTable
