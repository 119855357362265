import React, { useState } from 'react'
import { Box, Drawer, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import map from 'lodash/map'
import filter from 'lodash/filter'
import Icon from '../../shared/icon'
import { ReplenishmentOrderAction } from '../types'
import { ReplenishmentOrderItem } from './OrderItem'
import Modal from '../../shared/modal'
import { t } from '../../../i18n'
import moment from 'moment'


const scope = 'replenishment'

const RemoveReplenishmentItemModal = ({ itemForDeletion, removeProductReplenishment, removePlateReplenishment, setShowDeleteConfirmModal }) => {

  const removeReplenishment = () => {
    if(itemForDeletion.type == 'product') {
      removeProductReplenishment()
    } else {
      removePlateReplenishment()
    }
  }

  return (
    <div className="remove-modal">
      <Modal
        onClose={() => setShowDeleteConfirmModal(false)}
      >
        <Box>
          <div className="replenishment-order-modal delete">
          <Typography variant="h6" component="h2">
            {t('remove_item_modal_text', { scope })}
          </Typography>
          <br/>
          <div className="modal-btn">
            <button className="btn btn-secondary" onClick={() => setShowDeleteConfirmModal(false)}>{t('cancel')}</button>
            <button className="btn btn-primary" onClick={removeReplenishment}>{t('remove')}</button>
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export const ReplenishmentCartSidebar = ({ draftReplenishmentOrder, sidebarOpen, toggleSidebar, errorMessages, updateCart, orderState }) => {

  const [itemForDeletion, setItemForDeletion] = useState({id: null, type: null})
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)

  const closeSidebar = () => {
    toggleSidebar(false)
  }

  const removePlateReplenishment = () => {
    const plateReplenishments = map(draftReplenishmentOrder.plateReplenishments, replenishment =>
      (replenishment.id === itemForDeletion.id) ? { ...replenishment, destroy: true } : replenishment
    )

    updateCart({ ...draftReplenishmentOrder, plateReplenishments }, ReplenishmentOrderAction.REMOVE_ITEM)
    setItemForDeletion({id: null, type: null})
    setShowDeleteConfirmModal(false)
  }

  const removeProductReplenishment = () => {
    const productReplenishments = map(draftReplenishmentOrder.productReplenishments, replenishment =>
      (replenishment.id === itemForDeletion.id) ? { ...replenishment, destroy: true } : replenishment
    )

    updateCart({ ...draftReplenishmentOrder, productReplenishments }, ReplenishmentOrderAction.REMOVE_ITEM)
    setItemForDeletion({id: null, type: null})
    setShowDeleteConfirmModal(false)
  }

  const placeOrder = () => {
    updateCart(draftReplenishmentOrder, ReplenishmentOrderAction.PLACE_ORDER)
  }

  const updateExistingOrder = () => {
    updateCart(draftReplenishmentOrder, ReplenishmentOrderAction.UPDATE_SENT_ORDER)
  }

  const openDeleteConfirmModal = (replenishmentItemId, type) => {
    setItemForDeletion({id: replenishmentItemId, type: type})
    setShowDeleteConfirmModal(true)
  }

  const productReplenishmentsOfType = (type: 'solid' | 'liquid') => {
    const productReplenishments = filter(draftReplenishmentOrder.productReplenishments, replenishment =>
      replenishment.containerType === type && !replenishment.destroy
    )
    const header = type.charAt(0).toUpperCase() + type.slice(1)

    if(!productReplenishments.length)
      return
    return (
      <div className={`${type}-ingredients`}>
        <span className='sidebar-heading replenishment-order-subcategory'>{header} ingredients</span>
        {productReplenishments.map((replenishment, index) =>
          <ReplenishmentOrderItem
            key={`${replenishment.product.id}-${index}`}
            type="product"
            orderItem={replenishment}
            index={index}
            removeReplenishment={openDeleteConfirmModal}
          />
        )}
      </div>
    )
  }

  const plateReplenishments = () => {
    if(!draftReplenishmentOrder.plateReplenishments.length)
      return
    const plateReplenishments = draftReplenishmentOrder.plateReplenishments.filter(replenishment => !replenishment.destroy)
    return (
      <div className="plates">
        <span className='sidebar-heading replenishment-order-subcategory'>{t('sidebar.bowls', { scope })}</span>
        {plateReplenishments.map((replenishment, index) =>
          <ReplenishmentOrderItem
            key={`${replenishment.slotNumber}-${index}`}
            type="plates"
            orderItem={replenishment}
            index={index}
            removeReplenishment={openDeleteConfirmModal}
          />
        )}
      </div>
    )
  }

  const cartEmpty = () => {
    return draftReplenishmentOrder.plateReplenishments.length == 0 && draftReplenishmentOrder.productReplenishments.length == 0
  }

  const cancel = () => {
    updateCart(draftReplenishmentOrder, ReplenishmentOrderAction.CANCEL)
    closeSidebar()
  }

  return (
    <div>
      {showDeleteConfirmModal &&
        <RemoveReplenishmentItemModal
          itemForDeletion={itemForDeletion}
          removePlateReplenishment={removePlateReplenishment}
          removeProductReplenishment={removeProductReplenishment}
          setShowDeleteConfirmModal={setShowDeleteConfirmModal}
        />
      }
      <Drawer
        className='replenishment-sidebar'
        anchor='right'
        open={sidebarOpen}
        onClose={closeSidebar}
        style={{zIndex: '2 !important'}}
      >
        <Box
        sx={{ width: 400, padding: '20px 15px', borderRadius: '10px 0 0 10px', zIndex: '2 !important' }}
        role="presentation"
        >
          <div className="replenishment-sidebar-content">
            <div className="sidebar-top">
              <div className='sidebar-header'>
                {orderState === 'editing' &&
                  <>
                    <span className='sidebar-heading'>Order #{draftReplenishmentOrder.editableOrderId}</span>
                    <span className='sidebar-editing'>Editing</span>
                  </>
                }
                {orderState !== 'editing' && <span className='sidebar-heading'>{t('sidebar.heading', { scope })}</span>}
                <Icon name='close' className="close-icon" onClick={closeSidebar}/>
              </div>
              {orderState == 'sent' && <><br/><span>{t('sidebar.order_sent_editing_message', { scope })}</span></>}
              <div className="replenishment-errors">
                {Object.keys(errorMessages).map(message => {return (
                  <span key={message}>
                    {errorMessages[message]}
                  </span>
                )})}
              </div>
              {orderState !== 'sent' &&
                <div className={`replenishment-date ${errorMessages.hasOwnProperty('delivery_date') ? 'has-error' : ''}`}>
                  <span>{t('sidebar.replenish_date', { scope })}</span>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker className="replenish-datepicker"
                      format="MM/DD/YYYY"
                      value={draftReplenishmentOrder.deliveryDate ? moment(draftReplenishmentOrder.deliveryDate) : null}
                      onChange={date => updateCart({...draftReplenishmentOrder, deliveryDate: date}, ReplenishmentOrderAction.CHANGE_DATE)}
                    />
                  </LocalizationProvider>
                </div>
              }
            </div>
            {orderState != 'sent' &&
              <>
                {cartEmpty() &&
                <div className='empty-cart-container'>
                  <span>{t('sidebar.basket_empty', { scope })}</span>
                  <Icon name='replenishmentEmptyCart'/>
                </div>
                }
                <div>
                  {productReplenishmentsOfType('solid')}
                  {productReplenishmentsOfType('liquid')}
                  {plateReplenishments()}
                </div>
                <div className="replenishment-buttons">
                  {orderState == 'draft' &&
                    <button
                      className={`btn btn-primary mt-3 ${cartEmpty() ? 'disabled' : ''}`}
                      disabled={cartEmpty()}
                      onClick={() => placeOrder()}>
                        {t('sidebar.order_btn', { scope })}
                    </button>
                  }
                  {orderState == 'editing' &&
                    <button
                      className={`btn btn-primary mt-3 ${cartEmpty() ? 'disabled' : ''}`}
                      disabled={cartEmpty()}
                      onClick={() => updateExistingOrder()}>
                        {t('sidebar.update_order_btn', { scope })}
                    </button>
                  }
                  <button className="btn btn-secondary" onClick={cancel}>Cancel</button>
                </div>
              </>
            }
          </div>
        </Box>
      </Drawer>
    </div>
  )
}
