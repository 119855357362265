import React, { Component } from 'react'
import moment from 'moment'
import map from 'lodash/map'
import { weekdayNames } from './common'

class Calendar extends Component {
  static defaultProps = {
    className: '',
    classNameThead: '',
    month: moment().month(),
    year: moment().year(),
    renderDateCell: (date, idx) => date ? <td key={date}>{moment(date).format('DD.MM')}</td> : <td key={idx}></td>
  }

  calendarDates = () => {
    const { month, year } = this.props

    const start = moment().year(year).month(month - 1).startOf('month')
    const end = moment().year(year).month(month - 1).endOf('month')
    let now = start.clone(), dates = [], groupIndex = 0

    dates[groupIndex] = now.isoWeekday() !== 1
      ? Array(now.isoWeekday() - 1).fill(null)
      : []

    while (now.isSameOrBefore(end)) {
      if (groupIndex !== (now.isoWeek() - start.isoWeek()) && dates[groupIndex].length === 7) {
        dates[groupIndex += 1] = []
      }
      const date = now.format('YYYY-MM-DD')
      const formattedDate = now.format('DD.MM')
      dates[groupIndex].push({ date, formattedDate })
      now.add(1, 'days')
    }

    // for filling remaining days with empty cells
    let lastWeek = dates[dates.length - 1]
    lastWeek.push(...Array(7 - lastWeek.length).fill(null))

    return dates
  }

  render = () => {
    const { renderDateCell, className, classNameThead } = this.props
    let globalIdx = 0

    return (
      <table className={`table ${className}`}>
        <thead className={classNameThead}>
          <tr>
            {map(weekdayNames, name => <th key={name}>{name}</th>)}
          </tr>
        </thead>
        <tbody>
          {map(this.calendarDates(), (week, index) => (
            <tr key={index}>
              {map(week, weekDay => renderDateCell(weekDay && weekDay.date, globalIdx++))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}

export default Calendar
