import React from 'react'
import map from 'lodash/map'
import Tab from '../shared/Tab'

const Tabs = ({ tabs, currentTabIndex, switchTab, className, alerts = {} }) => {
  return (
    <div className={`${className} flex-1`}>
      {map(tabs, ({ name, label }, index) =>
        <Tab key={name}
          label={label}
          index={index}
          currentTabIndex={currentTabIndex}
          switchTab={switchTab}
          hasAlert={alerts[name]}
        />
      )}
    </div>
  )
}

export default Tabs
