import React, { Component, Fragment } from 'react'
import Collapse from '@mui/material/Collapse'
import map from 'lodash/map'
import find from 'lodash/find'
import DraggableProduct from './DraggableProduct'
import { Droppable } from 'react-beautiful-dnd'
import { ProductCategoryTree } from '../types'

const Category = ({ id, name, image_url, isExpanded, onClick }) => {
  return (
    <div className='category' data-id={id} onClick={onClick}>
      <div className='category-content'>
        <div className='category-image-wrapper'>
          <img className='category-image-main' src={image_url} alt={name} />
        </div>
        <div className='category-name'>{name}</div>
          <i className={`fa ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down' }`}/>
      </div>
    </div>
  )
}

const Subcategory = ({ id, name, products, image_url, index, isExpanded, expandProduct, onClick }) => {
  const hasProducts = products.length > 0

  return (
    <div className='subcategory-wrapper' data-id={id}>
      <div className='subcategory' data-id={id} onClick={onClick}  key={id}>
        <img className='subcategory-image' src={image_url} alt={name} />
        <span className='subcategory-name'>{name}</span>
        <i className={`fa ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </div>

      <Collapse in={isExpanded}>
        {!hasProducts &&
          <p className='text-secondary text-center my-2'>There are no products in this category</p>
        }

        {hasProducts &&
          <Droppable droppableId={`category_${id}`} isDropDisabled={true}>
            {(provided, snapshot) =>
              <div className='products-wrapper' ref={provided.innerRef}>
                {map(products, (product, index) =>
                  <DraggableProduct
                    {...product}
                    expandProduct={expandProduct}
                    key={product.id}
                    index={index}
                    isSubcategoryProduct="true"
                  />
                )}
              </div>
            }
          </Droppable>
        }
      </Collapse>
    </div>
  )
}

interface Props {
  categorizedProducts: ProductCategoryTree[],
  expandProduct: Function,
  handleCategoryClick: Function,
  handleSubcategoryClick: Function,
  expandedCategoryColIndex: number,
  expandedCategoryId: number,
  expandedSubcategoryId: number,
}

class ProductCategories extends Component<Props> {
  expandedCategoryTree = () => {
    const { categorizedProducts, expandedCategoryId } = this.props

    return find(categorizedProducts, category => category.id === expandedCategoryId)
  }

  render() {
    const {
      categorizedProducts, handleCategoryClick, handleSubcategoryClick, expandProduct,
      expandedCategoryId, expandedSubcategoryId
    } = this.props

    return(
      <Fragment>
        {map(categorizedProducts, category => {
          return (
            <div key={`category-${category.id}`}>
              <Category
                {...category}
                isExpanded={category.id === expandedCategoryId}
                onClick={() => handleCategoryClick(category.id)}
              />
              <div className={'product-category-dropdown'}>
              <Collapse in={expandedCategoryId && category.id === expandedCategoryId}>
                {category.children.length === 0 &&
                  <p className='text-secondary text-center my-2'>There are no subcategories</p>
                }
                {map(category.children, (subcategory, index) =>
                  <Subcategory
                    {...subcategory}
                    isExpanded={subcategory.id === expandedSubcategoryId}
                    expandProduct={expandProduct}
                    index={index}
                    onClick={() => handleSubcategoryClick(subcategory.id)}
                    key={`subcategory-${subcategory.id}-${category.id}`}
                  />
                )}
                {map(category.products, (categoryProduct, index) =>
                  <Droppable droppableId={`category_${categoryProduct.id}`} isDropDisabled={true} key={`product-${categoryProduct.id}-${category.id}`}>
                    {(provided, snapshot) =>
                      <div className='products-wrapper' ref={provided.innerRef}>
                        <DraggableProduct
                          {...categoryProduct}
                          expandProduct={expandProduct}
                          index={index}
                        />
                      </div>
                    }
                  </Droppable>
                )}
              </Collapse>
            </div>
            </div>
          )
      })}
      </Fragment>
    )
  }
}

export default ProductCategories
