import React from 'react'
import Select from 'react-select'
import find from 'lodash/find'
import { Formik } from 'formik'
import { validationSchema } from './validationSchema'

const LocationForm = (props) => (
  <Formik
    initialValues={props.location}
    validationSchema={validationSchema}
    validate={(values) => validationSchema.validate(values)}
    onSubmit={(values, { setStatus, setSubmitting }) =>
      props.onSubmit(values, (success, errorData) => {
        let status = {}
        if (!success) {
          const fields = Object.getOwnPropertyNames(errorData.full_errors)
          for (let key in fields) {
            status[`${fields[key]}_error`] =
              errorData.full_errors[fields[key]].join(', ')
          }
        }
        setStatus(status)
        setSubmitting(false)
      })
    }
  >
    {({
      values,
      errors,
      touched,
      status,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setStatus
    }) => {
      const enhancedHandleChange = (e) => {
        let newStatus = Object.assign({}, status) // could be stale?
        newStatus[`${e.target.name}_error`] = ''
        setStatus(newStatus)
        handleChange(e)
      }

      const renderField = (
        label,
        name,
        type,
        placeHolder,
        autoComplete = 'off'
      ) => {
        const errorsPresent =
          (errors[name] && touched[name]) || (status && status[`${name}_error`])
        return (
          <div className="form-group">
            <label>{label}</label>
            <input
              id={name}
              name={name}
              type={type}
              onBlur={handleBlur}
              value={values[name] === null ? '' : values[name]}
              placeholder={placeHolder}
              autoComplete={autoComplete}
              onChange={enhancedHandleChange}
              className={`form-control inverted ${
                errorsPresent && 'is-invalid'
              }`}
            />
            {errorsPresent && (
              <div className="invalid-feedback">
                {errors[name] || (status && status[`${name}_error`])}
              </div>
            )}
          </div>
        )
      }

      const renderSelect = (label, name, placeholder, selectOptions) => (
        <div className="form-group">
          <label>{label}</label>
          <Select
            classNamePrefix="react-select"
            className={`react-select inverted ${
              touched[name] && errors[name] && 'is-invalid'
            }`}
            options={selectOptions}
            placeholder={placeholder}
            id={name}
            name={name}
            onBlur={handleBlur}
            value={find(selectOptions, (opt) => opt.value === values[name])}
            onChange={(e) =>
              enhancedHandleChange({
                target: { name: name, value: e.value }
              })
            }
          />
          {errors[name] && touched[name] && (
            <div className="invalid-feedback">{errors[name]}</div>
          )}
        </div>
      )

      const renderCheckbox = (label, name) => {
        const errorsPresent =
          (errors[name] && touched[name]) || (status && status[`${name}_error`])
        return (
          <fieldset className="form-group boolean">
            <div className="form-check">
              <input
                id={name}
                name={name}
                type="checkbox"
                checked={values[name]}
                onChange={enhancedHandleChange}
                className={`form-check-input boolean ${
                  errorsPresent && 'is-invalid'
                }`}
              />
              <label className="form-check-label boolean" htmlFor={name}>
                {label}
              </label>
              {errorsPresent && (
                <div className="invalid-feedback">
                  {errors[name] || (status && status[`${name}_error`])}
                </div>
              )}
            </div>
          </fieldset>
        )
      }

      const toSelectOptions = (items) =>
        items.map((value) => ({ label: value, value: value }))

      return (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col">
              {renderField('Name', 'name', 'name', 'Enter name')}
              {renderSelect(
                'Currency',
                'currency_id',
                'Select currency',
                props.currencies.map((currency) => ({
                  label: currency.code,
                  value: currency.id
                }))
              )}
              {renderSelect(
                'Temperature scale',
                'temperature_scale',
                'Select temperature scale',
                toSelectOptions(['celsius', 'fahrenheit'])
              )}
              {renderSelect(
                'Weight scale',
                'weight_scale',
                'Select weight scale',
                toSelectOptions(['metric', 'imperial'])
              )}
              {renderField(
                'Webhook URL',
                'webhook_url',
                'text',
                'Enter webhook URL'
              )}
              {renderCheckbox(
                'Nonstop working hours',
                'nonstop_working_hours',
                'checkbox'
              )}
              {values.nonstop_working_hours == true ? null : (
                <>
                  {renderField(
                    'Working start hour',
                    'working_start_hour',
                    'number',
                    'Enter working start hour'
                  )}
                  {renderField(
                    'Working end hour',
                    'working_end_hour',
                    'number',
                    'Enter working end hour'
                  )}
                </>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            Update Location
          </button>
          <button
            className="btn btn-link"
            onClick={() => window.history.back()}
          >
            Back to list
          </button>
        </form>
      )
    }}
  </Formik>
)

export default LocationForm
