export const User = ({ id, roles, email, lastName, firstName }) => (
  {
    id: id,
    roles: roles,
    email: email,
    last_name: lastName,
    first_name: firstName,
    password_confirmation: '',
    password: '',
  }
)
