import React from "react"
import Modal from '../shared/modal'
import {
  customSelectOption,
  customSelectValue,
  customValueContainerWithPlaceholder,
  checkboxOption,
  multiValue,
  menu
} from '../shared/common'
import Select from 'react-select'
import axios from 'axios'
import IntervalInput from '../shared/intervalInput'
import { extractDataErrors } from '../shared/extractDataErrors'
import Icon from "../shared/icon"
import Loading from "../shared/loading"
import { t } from '../../i18n'
import Product from '../models/Product'
import ProductCategory from '../models/ProductCategory'
import { LocalizationContext } from "../shared/contexts"
import NestedDropdown from '../shared/NestedDropdown.tsx'
import startCase from 'lodash/startCase'

const scope = 'product'
const possibleOptions = [{ label: t('category'), value: 'category' }, { label: t('product', { scope }), value: 'product' }]

const initialState = {
  selectedType: possibleOptions[0],
    ...new Product(),
    ...new ProductCategory()
}

const prepareData = (array) => array.map(record => ({ label: record.name, value: record.id }))

class ModalForm extends React.Component {
  static contextType = LocalizationContext

  state = {
    ...initialState,
    possibleGroups: [],
    possibleUnits: [],
    possibleAlergens: [],
    possibleFractions: [],
    loading: false,
    weightUnit: '',
    errorMessages: []
  }

  componentDidMount() {
    this.fetchProductAttributes()
  }

  static getDerivedStateFromProps(props, state) {
    // We build the form for edit after a product/category select
    if (props.selectedObject && Object.keys(props.selectedObject).length > 0 && !state.id) {
      const selectedObject = props.selectedObject
      const type = selectedObject.class_name === 'ProductCategory' ? 'Category' : selectedObject.class_name
      const locationId = selectedObject.parent_id || selectedObject.product_category_id
      const location = props.locations.filter(l => l.id === locationId)[0]
      let productAssocations = {}
      if (type === 'Product') {
        const { unit, fraction, alergens } = selectedObject
        productAssocations = {
          fraction_id: fraction && { label: fraction.name, value: fraction.id },
          alergen_ids: prepareData(alergens),
          unit_id: unit && { label: unit.name, value: unit.id },
        }
      }

      return {
        ...selectedObject,
        ...productAssocations,
        selectedType: { label: type, value: type.toLowerCase() },
        location: location && { label: location.ancestry.map(c => c.name).join(" / "), value: location.id, liquids: location.liquids },
      }
    }

    if(props.selectedCategory !== null && Object.keys(state.location).length === 0) {
      return {
        ...state,
        selectedType: { label: 'Product', value: 'product' },
        location: { label: props.selectedCategory.ancestry.map(c => c.name).join(" / "), value: props.selectedCategory.id },
      }
    }
    return state
  }

  editMode = () => !!this.state.id
  startLoading = () => this.setState({ loading: true })
  stopLoading = () => this.setState({ loading: false })

  closeModal = () => {
    if (this.props.returnToShowAfter) {
      this.props.setObjectForShow(this.props.selectedObject)
    } else {
      this.props.closeModal()
      this.clearState()
    }
  }

  handleSelectChange = (e) => this.setState({ selectedValue: e.target.value })
  selectAllAlergens = (e) => this.setState({ alergen_ids: this.state.possibleAlergens })
  clearAllAlergens = (e) => this.setState({ alergen_ids: [] })
  handleChange = (field, e) => this.setState({ [field]: e.target.value })
  clearState = () => this.setState({ ...initialState })

  handleImageChange = (e) => {
    const file = e.target.files[0]
    this.setState({ attachment: file })
  }

  handleAdjustableChange = (name, value) => {
    if (value.length === 0 || (Number.isInteger(Number.parseInt(value)) && Number.parseInt(value) >= 0)) {
      this.setState({ [name]: value })
    }
  }

  fetchProductAttributes() {
    this.startLoading()
    axios.all([
      axios.get(Routes.units_product_attributes_path()),
      axios.get(Routes.alergens_product_attributes_path()),
      axios.get(Routes.fractions_product_attributes_path())
    ]).then(response => {
      const unitsData = prepareData(response[0].data)
      const alergensData = prepareData(response[1].data)
      const fractionsData = prepareData(response[2].data)

      this.setState({ possibleUnits: unitsData, possibleAlergens: alergensData, possibleFractions: fractionsData })
      this.stopLoading()
    }).catch(err => {
      this.stopLoading()
      this.setState({ errorMessages: extractDataErrors(err).full_errors || err })
    })
  }

  formData(type) {
    const form = new FormData()
    if (type === 'category') {
      Object.keys(new ProductCategory()).forEach(categoryKey => {
        form.append(categoryKey, this.state[categoryKey])
      })
      form.append('parent_id', this.state.location && this.state.location.value || '')
    } else {
      Object.keys(new Product()).forEach(categoryKey => {
        if (this.state[categoryKey] instanceof Array) {
          form.append(categoryKey, this.state[categoryKey].map(v => v.value))
        } else if (typeof this.state[categoryKey] === 'object' && this.state[categoryKey] !== null && Object.keys(this.state[categoryKey]).length > 0) {
          form.append(categoryKey, this.state[categoryKey].value)
        }
        else {
          form.append(categoryKey, this.state[categoryKey])
        }
      })
      if(this.state.location.value) {
        form.append('product_category_id', this.state.location.value)
      }
    }
    return form
  }

  save(e) {
    const { selectedType } = this.state
    const formData = this.formData(selectedType.value)
    this.startLoading()

    axios({
      method: this.editMode() ? 'PUT' : 'POST',
      url: this.url(),
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      this.stopLoading()
      this.closeModal()
    }).catch(err => {
      this.stopLoading()
      this.setState({errorMessages: err.response.data.full_errors})
    })
  }

  url() {
    const { selectedType } = this.state
    if (this.editMode()) {
      return selectedType.value === 'category' ? Routes.product_category_path(this.state.id, { format: 'json' }) : Routes.product_path(this.state.id, { format: 'json' })
    } else {
      return selectedType.value === 'category' ? Routes.product_categories_path({ format: 'json' }) : Routes.products_path({ format: 'json' })
    }
  }

  renderSelectInput(selectOptions = [], field) {
    return (
      <Select className='react-select mb-3' classNamePrefix='react-select'
        options={selectOptions}
        value={this.state[field]}
        onChange={(opt) => this.handleChange(field, { target: { value: opt }})}
        components={{
          Option: customSelectOption,
          SingleValue: customSelectValue,
          IndicatorSeparator: () => null
        }}
      />
    )
  }

  renderSelectInputWithValue(selectOptions = [], field, placeholder, mandatory = false, ) {
    return (
      <div className={`form-select-input-wrapper ${this.state.errorMessages[field] ? 'error-state' : ''}`}>
        <div className="form-select-input">
          <span>{placeholder}</span>
          <Select
            className='react-select mb-3'
            classNamePrefix='react-select'
            placeholder={placeholder}
            options={selectOptions}
            value={this.state[field]}
            isSearchable={false}
            mandatory={mandatory}
            onChange={(opt) => this.handleChange(field, { target: { value: opt }})}
            components={{
              Option: customSelectOption,
              ValueContainer: customValueContainerWithPlaceholder,
              IndicatorSeparator: () => null
            }}
          />
        </div>
        {this.state.errorMessages[field] && <span className="error-text">{this.state.errorMessages[field]}</span>}
      </div>
    )
  }

  renderNestedDropdown = (selectOptions, field, placeholder) => {
    return (
      <NestedDropdown
        options={selectOptions}
        value={this.state[field]}
        handleChange={(opt) => this.handleChange(field, { target: { value: opt }})}
        placeholder={placeholder}
      />
    )
  }

  renderImagePreview() {
    const { attachment, file_url } = this.state

    if (attachment || file_url) {
      const url = attachment ? URL.createObjectURL(attachment) : file_url
      return (
        <React.Fragment>
          <img id="image" src={url} />
          <div className="overlay">
            <div>
              Change image
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <i className="bi bi-card-image"></i>
          <div className="overlay text-center">
            <div>Add image</div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderInput(field, placeholder, additonalClassNames) {
    return (
      <div className="input-wrapper">
        <div className="d-flex">
          <label htmlFor="attachment" className='file-placeholder'>
            {this.renderImagePreview()}
          </label>
          <input
            style={{ display: 'none' }}
            id='attachment'
            type='file'
            accept='image/*'
            name='attachment'
            onChange={this.handleImageChange}
          />

          <div className='form-group w-100'>
            <input
              id={field}
              onChange={(e) => {this.handleChange(field, e); this.fixErrorState(field)}}
              className={`form-control ${additonalClassNames} ${this.state.errorMessages[field] ? 'error-state' : ''}`}
              placeholder={placeholder}
              value={this.state[field]}
            />
          </div>
        </div>
        {this.state.errorMessages[field] && <span className="error-text"> {this.state.errorMessages[field]} </span>}
      </div>
    )
  }

  renderTextBoxInputWithLabel() {
    return (
      <div className="form-group">
        <label>Notes</label>
        <textarea value={this.state.notes} className="form-control" onChange={this.handleChange.bind(this, 'notes')} />
      </div>
    )
  }

  renderAdjustableInput(name, label, afterText, mandatory, tooltipText, maxLength, step) {
    return (
      <IntervalInput
        mandatory={mandatory}
        label={label}
        name={name}
        value={this.state[name]}
        onChange={this.handleAdjustableChange}
        afterText={afterText}
        tooltipText={tooltipText}
        maxLength={maxLength}
        step={step}
        classNames="narrow"
      />
    )
  }

  renderMultipleSelectInput(selectOptions = [], field, placeholder, mandatory = false) {
    return (
      <div className="form-select-input-wrapper">
        <div className="form-select-input">
          <span>{placeholder}</span>
          <Select
            className='react-select mb-3'
            classNamePrefix='react-select'
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions={false}
            placeholder={placeholder}
            options={selectOptions}
            value={this.state[field]}
            isSearchable={false}
            isClearable={false}
            mandatory={mandatory}
            selectAll={this.selectAllAlergens}
            clearAll={this.clearAllAlergens}
            onChange={(value) => this.handleChange(field, { target: { value: value }})}
            components={{
              Option: checkboxOption,
              ValueContainer: customValueContainerWithPlaceholder,
              MultiValue: multiValue,
              Menu: menu,
              IndicatorSeparator: () => null
            }}
          />
        </div>
      </div>
    )
  }

  fixErrorState = (field) => {
    if(this.state.errorMessages[field]) {
      this.setState({errorMessages: {...this.state.errorMessages, [field]: null}})
    }
  }

  renderRadioButtons(field) {

    return (
      <div className={`robis-radio-group-wrapper ${this.state.errorMessages[field] ? 'error-state' : ''}`}>
        <div className="robis-radio-group">
          <span className="robis-radio-group-label">
            {startCase(field)}
          </span>
          <div className="robis-radio-group-input">
            <div>
              <label className='radio-liquid mr-4'>
                <input
                  type='radio'
                  name='liquid'
                  value='liquid'
                  onChange={({ target: { value } }) => {this.setState({ [field]: value }); this.fixErrorState(field)}}
                  checked={this.state[field] === 'liquid'}
                />
                Liquid
              </label>
              <label className='radio-solid'>
                <input
                  type='radio'
                  name='solid'
                  value='solid'
                  onChange={({ target: { value } }) => {this.setState({ [field]: value }); this.fixErrorState(field)}}
                  checked={this.state[field] === 'solid'}
                />
                Solid
              </label>
            </div>
          </div>
        </div>
        {this.state.errorMessages[field] && <span className="error-text">{this.state.errorMessages[field]}</span>}
      </div>
    )
  }

  renderButtons() {
    if (this.props.show) {
      return (
        <button type="button" className='btn btn-primary mt-5' onClick={this.props.openEdit}>
          Edit {this.state.selectedType.label.toLowerCase()}
        </button>
      )
    } else if (this.editMode()) {
      return (
        <React.Fragment>
          <button type="button" className='btn btn-danger mr-3' onClick={() => this.props.destroy(this.state)}>
            Delete {this.state.selectedType.label.toLowerCase()}
          </button>
          <button type="button" className='btn btn-secondary mr-3' onClick={this.closeModal.bind(this)}>
            Cancel
          </button>
          <button type="button" className='btn btn-primary' onClick={this.save.bind(this)}>
            Save
          </button>
        </React.Fragment>
      )
    } else {
      return (
        <button type="button" className='btn btn-primary' onClick={this.save.bind(this)}>
          Create {this.state.selectedType.label.toLowerCase()}
        </button>
      )
    }
  }

  renderShowInput(label, value, classNames) {
    return (
      <div className={`show-input ${classNames}`}>
        <b>{label}</b>
        <span>{isNaN(value) ? startCase(value) : value}</span>
      </div>
    )
  }

  renderShowInputWithImage(imageUrl, value) {
    return (
      <div className='big-show-input'>
        {imageUrl && <img src={imageUrl} /> || <Icon name='categoryPlaceholder' className='big-image' />}
        <h4>{value}</h4>
      </div>
    )
  }

  renderModalContent() {
    if (this.props.show) {
      return this.renderShowForm()
    } else {
      return this.renderInputForm()
    }
  }

  renderShowForm() {
    const { name, feeder_type, alert, kcal, kjoul, alergens, processing_loss, notes, fraction, unit, location, file_url } = this.state

    return (
      <React.Fragment>
        {this.renderShowInput(t('category'), location.label)}
        {this.renderShowInputWithImage(file_url, name)}
        {this.renderShowInput(t('feeder_type', { scope }), feeder_type)}
        {this.renderShowInput(t('fraction', { scope }), fraction && fraction.name)}
        {this.renderShowInput(t('unit', { scope }), unit && unit.name)}
        {this.renderShowInput(t('alert', { scope }), alert)}
        {this.renderShowInput(t('kcal', { scope }), kcal)}
        {this.renderShowInput(t('kj', { scope }), kjoul)}
        {this.renderShowInput(t('alergens', { scope }), alergens.map(alergen => alergen.name).join(', '))}
        {this.renderShowInput(t('processing_loss', { scope }), processing_loss)}
        {this.renderShowInput(t(this.weightUnit === 'g' ? 'conversion_to_liters' : 'conversion_to_fluid_ounces', { scope }), this.state.volume_to_weight_multiplier)}
        {this.renderShowInput(t('notes', { scope }), notes, 'text-show-input')}
      </React.Fragment>
    )
  }

  categoryAncestryLabel = (category) => {
    const categoryAncestors = category.ancestry.slice(0, -1)
    return (
      <div>
        {categoryAncestors.length > 0 &&
          <span style={{color: '#A6A6A6'}}>{categoryAncestors.map(c => c.name).join(" / ")} / </span>
        }
        <span>{category.name}</span>
      </div>
    )
  }

  renderInputForm() {
    const { locations } = this.props
    const { selectedType } = this.state
    const filteredLocations = selectedType.value == 'category' ? locations.filter(category => category.parent_id == null) : locations
    const parsedLocations = filteredLocations.map(category => ({
      label: this.categoryAncestryLabel(category),
      value: category.id,
      depth: category.ancestry.length - 1,
      liquids: category.liquids
    }))
    if (selectedType.value === 'category') {
      return (
        <React.Fragment>
          {this.renderSelectInputWithValue(parsedLocations, 'location', t('category'))}
          {this.renderInput('name', 'Category name', 'big-input')}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {this.renderNestedDropdown(parsedLocations, 'location', t('category'))}
          {this.renderInput('name', t('product_name', { scope }), 'big-input')}
          {this.renderRadioButtons('feeder_type')}
          {this.renderSelectInputWithValue(this.state.possibleFractions, 'fraction_id', t('fraction', { scope }))}
          {this.renderSelectInputWithValue(this.state.possibleUnits, 'unit_id', t('unit', { scope }))}
          {this.renderAdjustableInput('alert', t('alert', { scope }), this.weightUnit)}
          {this.renderAdjustableInput('kcal', t('kcal', { scope }))}
          {this.renderAdjustableInput('kjoul', t('kj', { scope }))}
          {this.renderMultipleSelectInput(this.state.possibleAlergens, 'alergen_ids', t('alergens', { scope }))}
          {this.renderAdjustableInput('processing_loss', t('processing_loss', { scope }), '%')}
          {(this.state.feeder_type == 'liquid') && this.renderAdjustableInput(
            'volume_to_weight_multiplier',
            t(this.weightUnit === 'g' ? 'conversion_to_liters' : 'conversion_to_fluid_ounces', { scope }),
            '', false,
            this.weightUnit === 'g' ? '0.1 kg => ? l' : '1 oz => ? fl-oz',
            5,
            0.1
          )}
          {this.renderTextBoxInputWithLabel()}
        </React.Fragment>
      )
    }
  }

  headerContent() {
    if (this.props.show) {
      return <h3>{this.state.selectedType.label}</h3>
    } else if (this.editMode()) {
      return <h3>{t('modal.edit')} {this.state.selectedType.label}</h3>
    } else {
      return <h3>{t('modal.new')} </h3>
    }
  }

  renderLoading = () => this.state.loading && <Loading />

  renderPossibleTypes = () => {
    const marginClassName = (index) => {
      return index === 0 ? '' : 'margin-left-20'
    }

    const editClassName = this.editMode() ? 'cursor-default' : ''

    return (
      <div className="option-radio">
        {possibleOptions.map((option, index) => (
          <div
           onClick={() => this.setState({'selectedType': option})}
           key={option.value}
           className={`d-inline ${this.editMode() ? 'option-disabled' : ''}`}>
            <input
            onChange={() => this.setState({'selectedType': option})}
            className={[marginClassName(index), editClassName].join(' ')}
            type="radio"
            name={option.value}
            defaultValue={option}
            checked={this.state.selectedType.value === option.value}
            disabled={this.editMode()}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { modalOpen } = this.props
    const { weight_scale } = this.context

    this.weightUnit = {
      'metric': 'g',
      'imperial': 'oz'
    }[weight_scale]

    if (modalOpen) {
      return (
        <Modal onClose={this.closeModal} widen={true} headerContent={this.headerContent()}>
          <div className="pb-5 modal-form">
            {this.renderPossibleTypes()}
            {this.renderModalContent()}
            {this.renderButtons()}
            {this.renderLoading()}
          </div>
        </Modal>
      )
    } else {
      return ''
    }
  }
}

export default ModalForm
