import { Moment } from "moment"

export interface ProductCategory {
  id: number,
  name: string,
  ancestry: string,
  children: ProductCategory[]
}

export interface Product {
  id: number,
  fraction_name: string,
  image_url_medium: string,
  name: string,
  product_category: ProductCategory
  ancestry_tree: string
}

export interface ProductContainer {
  id?: number,
  slot_number: number,
  human_slot_number: number,
  initial_amount: number,
  used_amount?: number,
  remaining_amount?: number,
  remaining_amount_percent?: number,
  remaining_amount_status?: string,
  expiration_date: string,
  days_until_expiration?: number,
  expiration_date_status?: string,
  notes: string,
  product: Product,
  liquid: boolean,
  show_alert?: boolean,
  product_container_warnings: Record<string, string[]>
}

export interface ProductWithCategory {
  id: number,
  name: string,
  description: string,
  product_category_id: number,
  product_category_name: string,
  image_url_small: string,
  image_url_medium: string
}

export interface PlateContainer {
  id?: number,
  slot_number: number,
  initial_amount: number,
  used_amount?: number,
  remaining_amount?: number,
  remaining_amount_percent?: number,
  remaining_amount_status?: string,
  show_alert?: boolean,
}

export interface ContainerSlot {
  number: number,
  humanNumber: number,
  isLiquid: boolean,
}

export interface ProductWithCategory {
  id: number,
  name: string,
  description: string,
  product_category_id: number,
  product_category_name: string,
  image_url_small: string,
  image_url_medium: string
}

export interface ProductReplenishment {
  id?: number,
  product: Product,
  previousProduct: Product,
  productContainer: ProductContainer,
  containerType: 'solid' | 'liquid',
  slotNumber: number,
  destroy: boolean
}

export interface PlateReplenishment {
  id?: number,
  amount: number,
  slotNumber: number,
  destroy: boolean
}

export interface ReplenishmentOrder {
  id?: number,
  productReplenishments: ProductReplenishment[],
  plateReplenishments: PlateReplenishment[],
  state: 'draft' | 'sent' | 'completed' | 'closed',
  deliveryDate: Moment,
  editableOrderId: number,
  createdAt: Date
}

export enum ReplenishmentOrderAction {
  ADD_ITEM = 'add_item',
  REMOVE_ITEM = 'remove_item',
  PLACE_ORDER = 'place_order',
  UPDATE_SENT_ORDER = 'update_sent_order',
  CHANGE_DATE = 'change_date',
  CANCEL = 'cancel'
}
