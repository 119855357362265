import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  currency_id: Yup.number().label('Currency').required('Required'),
  temperature_scale: Yup.string()
    .label('Temperature scale')
    .required('Required'),
  weight_scale: Yup.string().label('Weight scale').required('Required'),
  webhook_url: Yup.string().label('Weight scale').url(),
  nonstop_working_hours: Yup.boolean().label('Nonstop working hours'),
  working_start_hour: Yup.number()
    .label('Working start hour')
    .integer()
    .nullable(),
  working_end_hour: Yup.number().label('Working end hour').integer().nullable()
})
