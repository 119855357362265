import React, { useRef } from 'react'
import map from 'lodash/map'
import includes from 'lodash/includes'
import DateTimeFilters from '../shared/DateTimeFilters'
import SectionTitle from '../../shared/sectionTitle'
import { t, l } from '../../../i18n'

const TdTextArea = ({ children }) => {
  return (
    <td className='p-0 pr-3'>
      <textarea className='w-100' disabled>
        {children}
      </textarea>
    </td>
  )
}

const LogRow = ({ log }) => {
  const statusCodeClass = () => {
    const isError = includes([4, 5], Math.floor(log.status / 100)) // 4xx, 5xx

    return isError ? 'danger' : 'success'
  }

  return (
    <tr>
      <td>{log.id}</td>
      <td>{log.location_name}</td>
      <td>{log.operation}</td>
      <td>{log.timestamp ? l('time.formats.default', log.timestamp) : ''}</td>
      <TdTextArea>{log.url}</TdTextArea>
      <TdTextArea>{log.request_body}</TdTextArea>
      <TdTextArea>{log.response_body}</TdTextArea>
      <td className='text-center'>
        <span className={`badge badge-${statusCodeClass()}`}>
          {log.status}
        </span>
      </td>
    </tr>
  )
}

const ExternalApiWebhookLogs = ({ logs }) => {
  const filterFormRef = useRef(null)

  const scope = 'external_api_webhook_logs'

  const handleFilterFormSubmit = (e) => {
    const KEY_ENTER = 13

    if (e.keyCode === KEY_ENTER) {
      filterFormRef.current.submit()
    }
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <SectionTitle rows={['Webhook', 'Logs']} />
          <form
            ref={filterFormRef}
            onKeyUp={handleFilterFormSubmit}
            tabIndex={0}
            className='d-flex justify-content-end'
          >
            <DateTimeFilters />
          </form>
        </div>
        <table className='table table-robis'>
          <thead className='thead-robis'>
            <tr>
              <th>#</th>
              <th>{t('location', { scope })}</th>
              <th>{t('operation', { scope })}</th>
              <th>{t('timestamp', { scope })}</th>
              <th>{t('url', { scope })}</th>
              <th>{t('request_body', { scope })}</th>
              <th>{t('response_body', { scope })}</th>
              <th>{t('status', { scope })}</th>
            </tr>
          </thead>
          <tbody>
            {map(logs, log => <LogRow key={log.id} log={log} />)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ExternalApiWebhookLogs
