import moment from 'moment-timezone'

export default function() {
  moment.locale(document.documentElement.lang)

  const tzMetaTag = document.querySelector('meta[name="timezone"]')
  if (tzMetaTag) {
    const timezone = tzMetaTag.getAttribute('content')
    moment.tz.setDefault(timezone)
  }
}
