import React, { PureComponent, Fragment } from 'react'
import Select from 'react-select'
import axios from 'axios'
import find from 'lodash/find'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import { Formik } from 'formik'
import Modal from '../shared/modal'
import SectionTitle from '../shared/sectionTitle'
import FlashNotification from '../shared/flashNotification'
import { customSelectOption, customSelectValue } from '../shared/common'

class OrderModal extends PureComponent {
  constructor(props) {
    super(props)

    this.blankOrderItem = {
      recipe_id: null,
      amount: 1
    }

    this.state = {
      flashOpen: false,
      flashType: '',
      flashMessage: '',
      modalOpen: false,
      orderItems: [this.blankOrderItem]
    }
  }

  submitForm = () => this.refs.formik.submitForm()
  openModal = () => this.setState({ modalOpen: true })
  closeModal = () => this.setState({ modalOpen: false, orderItems: [this.blankOrderItem] })
  modalOpen = () => this.state.modalOpen

  calculateOrderTotal = () => {
    const { activeRecipes } = this.props
    const { orderItems } = this.state

    if (activeRecipes.length === 0) return 0

    return reduce(orderItems, (sum, oi) =>
      sum + (oi.recipe_id ? (oi.amount * find(activeRecipes, r => r.id === oi.recipe_id).price) : 0)
    , 0)
  }

  createOrder = ({ paid, orderItems }, onFinished) => {
    axios.post(
      Routes.create_order_orders_path(), {
        paid,
        order_items_attributes: orderItems
      }
    ).then(
      resp => window.location.reload()
    ).catch((error) => {
      onFinished(false, error.response.data)
    })
  }

  setFlashMessage = (flashType, flashMessage, timeOut = 3000) => (
    this.setState({ flashType, flashMessage, flashOpen: true },
      () => {
        if (flashType !== 'danger') {
          setTimeout(() => this.setState({ flashOpen: false }), timeOut)
        }
      }
    )
  )

  renderFlashNotification = () => this.state.flashOpen && (
    <FlashNotification
      type={this.state.flashType}
      message={this.state.flashMessage}
      onClose={() => this.setState({ flashOpen: false })}
    />
  )

  renderForm = () => (
    <Formik
      ref='formik'
      initialValues={{
        paid: false,
        orderItems: this.state.orderItems
      }}
      onSubmit={(values, { setStatus }) =>
        this.createOrder(values, (success, errorData) =>
          !success && this.setFlashMessage('danger', errorData.error)
        )
      }
    >
      {({
        values,
        errors,
        touched,
        status,
        handleChange,
        handleBlur,
        handleSubmit,
        setStatus,
        setFieldValue
      }) => {
        const onOrderItemChange = (idx, name, e) => {
          let newOrderItems = [...this.state.orderItems]

          newOrderItems[idx] = { ...newOrderItems[idx], [name]: e.target.value }

          this.setState({ orderItems: newOrderItems })
          handleChange({ target: { name: 'orderItems', value: newOrderItems } })
        }

        const onAddOrderItem = () => {
          const newOrderItems = [...values.orderItems, this.blankOrderItem]

          this.setState({ orderItems: newOrderItems })
          handleChange({ target: { name: 'orderItems', value: newOrderItems } })
        }

        const onRemoveOrderItem = (idx) => {
          if (values.orderItems.length <= 1) return

          let newOrderItems = [...values.orderItems]

          newOrderItems.splice(idx, 1)

          this.setState({ orderItems: newOrderItems })
          handleChange({ target: { name: 'orderItems', value: newOrderItems } })
        }

        const renderOrderItem = (orderItem, idx) => {
          const { activeRecipes } = this.props

          const selectOptions = map(activeRecipes, recipe => ({
            value: recipe.id,
            label: `${recipe.name} | ${recipe.price.toFixed(2)} ${this.props.currency.code}`,
            imageUrl: recipe.image_url
          }))

          const amountInputName = `orderItems[${idx}].amount`
          const amountValue = values.orderItems[idx].amount

          return (
            <div className={`row mb-3 order-item-${idx}`} key={idx}>
              <div className='col-sm-8'>
                <div className='form-group mb-0'>
                  <Select className='react-select' classNamePrefix='react-select'
                    options={selectOptions}
                    value={find(selectOptions, opt => opt.value === values.orderItems[idx].recipe_id) || null}
                    onChange={(opt) => onOrderItemChange(idx, 'recipe_id', { target: { name: `orderItems[${idx}].recipe_id`, value: opt.value } })}
                    components={{
                      Option: customSelectOption,
                      SingleValue: customSelectValue
                    }}
                  />
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='form-group mb-0 number-spinner-wrapper'>
                  <button
                    type='button'
                    className='btn number-spinner decrease'
                    onClick={() => onOrderItemChange(idx, 'amount', { target: { name: amountInputName, value: Math.max(amountValue - 1, 0) } })}
                  >
                    -
                  </button>
                  <input className='form-control text-center amount-input'
                    type='number'
                    min='0'
                    name={amountInputName}
                    value={values.orderItems[idx].amount}
                    onChange={(e) => onOrderItemChange(idx, 'amount', e)}
                  />
                  <button
                    type='button'
                    className='btn number-spinner increase'
                    onClick={() => onOrderItemChange(idx, 'amount', { target: { name: amountInputName, value: amountValue + 1 } })}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className='col-sm-1 d-flex align-items-center'>
                <i
                  className={`button-delete fa fa-trash-o fa-2x pointer ${values.orderItems.length <= 1 ? 'disabled' : ''}`}
                  onClick={() => onRemoveOrderItem(idx)}
                />
              </div>
            </div>
          )
        }

        return (
          <form autoComplete='off' onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-sm-8'>
                <div className='form-group mb-0'><label>Recipe</label></div>
              </div>
              <div className='col-sm-3'>
                <div className='form-group mb-0'><label>Amount</label></div>
              </div>
              <div className='col-sm-1'></div>
            </div>
            {map(values.orderItems, (orderItem, idx) => renderOrderItem(orderItem, idx))}
            <div className='mb-5'>
              <span
                className='button-add pointer mb-5'
                onClick={onAddOrderItem}
              >
                <i className='fa fa-plus-circle large mr-2' />
                <span>Add</span>
              </span>
            </div>
            <label className='radio-paid mr-4'>
              <input
                type='radio'
                name='paid'
                onChange={({ target: { name } }) => setFieldValue('paid', true)}
                checked={!!values.paid}
              />
              Paid
            </label>
            <label className='radio-unpaid'>
              <input
                type='radio'
                name='paid'
                onChange={({ target: { name } }) => setFieldValue('paid', false)}
                checked={!values.paid}
              />
              Unpaid
            </label>
          </form>
        )
      }}
    </Formik>
  )

  renderModal = () => (
    <Modal
      onClose={this.closeModal}
      headerContent={<SectionTitle rows={['New', 'Order']} paddingLeft={0} />}
      footerContent={this.renderModalActions()}
      widen={true}
    >
      {this.renderForm()}
    </Modal>
  )

  renderModalActions = () => (
    <div className='d-flex justify-content-between align-items-end flex-1'>
      <button onClick={this.submitForm} className='btn btn-primary'>
        Create Order
      </button>
      <SectionTitle rows={['Total:', `${this.calculateOrderTotal().toFixed(2)} ${this.props.currency.code}`]} className='text-right' />
    </div>
  )

  renderModalOpenButton = () => this.props.newOrderAvailable && (
    <button className='btn btn-primary' onClick={this.openModal} disabled={this.modalOpen()}>
      + New Order
    </button>
  )

  render = () => (
    <Fragment>
      {this.renderModalOpenButton()}
      {this.modalOpen() && this.renderModal()}
      {this.renderFlashNotification()}
    </Fragment>
  )
}

export default OrderModal
