import React from 'react'
import Monitor from './monitor'

const MonitorScreen = ({ active_queues }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Monitor activeQueues={active_queues} />
        </div>
      </div>
    </div>
  )
}

export default MonitorScreen
