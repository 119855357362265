import React from 'react'
import axios from 'axios'
import groupBy from 'lodash/groupBy'
import Icon from './icon'
import ProductCategory from '../product_categories/ProductCategory'

export default class Autocomplete extends React.Component  {
  state = { search: '', list: [] }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside.bind(this))
  }

  handleClickOutside(e) {
    if (document.getElementById('autocomplete') && document.getElementById('autocomplete').contains(e.target)) {
      this.state.search.length > 3 && this.setState({ listOpen: true })
    } else {
      this.state.search.length && this.setState({ listOpen: false })
    }
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value }, this.fetchList)
  }

  fetchList() {
    const { search } = this.state
    if (search.length > 2) {
      const product_category_id = this.props.selectedCategory ? this.props.selectedCategory.id : null
      axios.get(Routes.search_products_path({name: search, product_category_id})).then(res => {
        this.setState({ list: res.data, listOpen: true })
      }).catch(err => {
        console.log(err)
      })
    } else {
      this.setState({ listOpen: false })
    }
  }

  renderCategoryItem(items) {
    return items.map(object => {
      return (
        <p className="list-item" key={object.id} onClick={() => this.props.setObjectForShow(object)}>
          {object.file_url && <img className="table-row-image" src={object.file_url} /> || <Icon name='categoryPlaceholder' className='table-row-image' />}
          {object.name}
        </p>
      )
    })
  }

  renderList() {
    if (!this.state.listOpen) return
    const groupedList = groupBy(this.state.list, (object) => object.product_category_name)

    return (
      <div className='autocomplete-list'>
        {Object.keys(groupedList).map(category => {
          const categoryId = groupedList[category][0].product_category_id
          return (
            <div className='autocomplete-list-item' key={category}>
              <a href={`/products/${categoryId}`}>{category}</a>
              {this.renderCategoryItem(groupedList[category])}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <div id='autocomplete' className='autocomplete-container'>
        <input className='input-without-style' type='search' autoComplete='off' placeholder='search...' value={this.state.search} onChange={this.handleSearch} />
        {this.renderList()}
      </div>
    )
  }
}

