import React, {useEffect, useRef, useState} from 'react'
import { Draggable } from "react-beautiful-dnd";
import { DropContainer } from './draggable'
import Icon from './icon'

const TableColumnManager = ({ selectAll, clearAll, updatePositions, possibleColumns, handleSettingChange, emptyColumns }) => {

  const [settingsOpen, setSettingsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside.bind(this))
  }, []);

  const toggleSettings = () => setSettingsOpen(!settingsOpen)

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setSettingsOpen(false)
    }
  }

  const renderSettings = () => {
    if (settingsOpen) {
      return (
        <div className="like-modal">
          <div className="d-flex multiple-menu-header">
            <span className="mr-4 as-button" onClick={selectAll}>Select all</span>
            <span className="as-button" onClick={clearAll}>Clear all</span>
          </div>
          <DropContainer updatePositions={updatePositions} collection={possibleColumns}>
            {possibleColumns.map((column, index) => {
              return (
                <Draggable key={column.id} draggableId={`drag-${column.id}`} index={index}>
                  {(provided) => (
                    <div
                      className='check-container'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={column.column_name}>
                        <Icon name='dragBurger' className='drag-icon mr-3'/>
                        <div className='form-check robo-option'>
                          <label className="form-check-label robo-checkbox-label"
                            htmlFor={`check-${column.column_name}`}>
                            {column.column_label}
                          </label>
                          <input id={`check-${column.column_name}`}
                            className="form-check-input robo-checkbox"
                            type="checkbox" checked={column.selected}
                            onChange={(e) => handleSettingChange(column, e.target.checked)}/>
                        </div>
                    </div>
                  )}
                </Draggable>
              )
            })}
          </DropContainer>
        </div>
      )
    }
  }

  const renderSettingsContainer = () => {
    return (
        <div ref={ref} id='settings-container'>
          <Icon name='settingsIcon' className="icon-button float-right"
                onClick={toggleSettings}/>
          {renderSettings()}
        </div>
    )
  }

  const actionColumnCount = emptyColumns || 1

  return (
      <React.Fragment>
        {Array.from({ length: actionColumnCount }, (_, index) =>
            (index + 1) === actionColumnCount
                ? <th key={index} className='settings'>{renderSettingsContainer()}</th>
                : <th key={index}></th>
        )}
      </React.Fragment>
  )
}

export {TableColumnManager}
