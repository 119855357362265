import React, { useRef } from 'react'
import map from 'lodash/map'
import DateTimeFilters from '../shared/DateTimeFilters'
import { t, l } from '../../../i18n'

const LogRow = ({ log }) => {
  return (
    <tr>
      <td>{log.id}</td>
      <td>{log.created_at ? l('time.formats.default', log.created_at) : ''}</td>
      <td>{log.started_at ? l('time.formats.default', log.started_at) : ''}</td>
      <td>{log.ended_at ? l('time.formats.default', log.ended_at) : ''}</td>
      <td>{log.state}</td>
      <td>{log.error_code}</td>
    </tr>
  )
}

const SchedulerRunReports = ({ logs }) => {
  const filterFormRef = useRef(null)

  const getQueryParam = (param) => {
    const url = new URL(location.toString())
    return url.searchParams.get(param) || null
  }

  const handleFilterFormSubmit = (e) => {
    const KEY_ENTER = 13

    if (e.keyCode === KEY_ENTER) {
      filterFormRef.current.submit()
    }
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <form
          ref={filterFormRef}
          onKeyUp={handleFilterFormSubmit}
          tabIndex={0}
          className='d-flex justify-content-between mb-2'
        >
          <input
            className='form-control d-inline-block w-auto'
            autoComplete='off'
            placeholder='Search...'
            type='search'
            name='q'
            defaultValue={getQueryParam('q') || ''}
          />
          <DateTimeFilters />
        </form>
        <table className='table table-robis'>
          <thead className='thead-robis'>
            <tr>
              <th>#</th>
              <th>{t('scheduler_run_reports.created_at')}</th>
              <th>{t('scheduler_run_reports.started_at')}</th>
              <th>{t('scheduler_run_reports.ended_at')}</th>
              <th>{t('scheduler_run_reports.state')}</th>
              <th>{t('scheduler_run_reports.error_code')}</th>
            </tr>
          </thead>
          <tbody>
            {map(logs, log => <LogRow key={log.id} log={log} />)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SchedulerRunReports
