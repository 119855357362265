const ProductCategory = () => {
  return {
    id: null,
    name: '',
    location: {},
    attachment: null,
    file_url: null,
    liquids: false
  }
}

export default ProductCategory
