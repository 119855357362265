import React, { PureComponent } from 'react'
import UsersForm from './UsersForm'
import axios from 'axios'

class UsersEditForm extends PureComponent {
  state = { user: null, saveSuccessful: null, saveText: '' }

  componentDidMount = () => (
    this.props.id
      ? axios.get(Routes.api_internal_user_path(this.props.id, { format: 'json' })).then((resp) =>
          this.setState({ user: resp.data })
        )
      : this.setState({ user: { first_name: 'abc', last_name: 'abc', email: 'mail@mail.com' } })
  )

  userPresent = () => this.state.user.id

  url = () => (
    this.userPresent()
      ? Routes.api_internal_user_path(this.state.user.id, { format: 'json' })
      : Routes.api_internal_users_path({ format: 'json' })
  )

  saveUser(user, onFinished) {
    const action = this.userPresent() ? 'patch' : 'post'
    axios[action](this.url(), user).then((resp) => {
      this.setState({ saveSuccessful: true, saveText: 'Save was successful.' })
      onFinished(true, null)
      this.setState({ user: resp.data })
    }).catch((error) => {
      this.setState({ saveSuccessful: false, saveText: 'Save failed. Please review the values.' })
      onFinished(false, error.response.data)
    })
  }

  renderSaveMessage = () => {
    const { saveSuccessful, saveText } = this.state
    return (
      this.state.saveSuccessful === null
        ? null
        : <div className={`alert alert-${saveSuccessful ? 'success' : 'danger'}`}>
            {saveText}
          </div>
    )
  }

  render() {
    const { user } = this.state
    if (!user) return false
    const { id, email, first_name, last_name, roles } = user
    return (
      <div className="row">
        <div className="col-sm-12">
          {this.renderSaveMessage()}
          <UsersForm
            id={id}
            roles={roles}
            email={email}
            lastName={last_name}
            firstName={first_name}
            onSubmit={this.saveUser.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default UsersEditForm
