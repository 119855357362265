import * as I18nJs from 'i18n-js'
import translations from './translations.json'

const I18n = new I18nJs.I18n(translations)
I18n.defaultLocale = 'en'
I18n.locale = document.documentElement.getAttribute('lang')
const t = I18n.t.bind(I18n)
const l = I18n.l.bind(I18n)

export default I18n
export { t, l }
