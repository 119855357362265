import React from 'react'
import { components } from 'react-select'

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const weekdayNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const disabledIconColor = '#CCCCCC'

export const customSelectOption = (props) => (
  <components.Option {...props} className='select-option d-flex align-items-center'>
    {props.data.imageUrl && <img src={props.data.imageUrl} />}
    {props.data.label}
  </components.Option>
)

export const customSelectValue = (props) => (
  <components.SingleValue {...props} className='d-flex align-items-center'>
    {props.data.imageUrl && <img src={props.data.imageUrl} />}
    {props.data.label}
  </components.SingleValue>
)

export const customValueContainerWithPlaceholder = ({ children, ...props }) => {
  const mandatory = props.selectProps.mandatory ? 'mandatory' : ''
  let values = React.Children.map(children, child => {
    if (child && child.type !== components.Placeholder) {
      return React.cloneElement(child, { className: `${child.props.className} d-flex align-items-center absolute-right-10` })
    }
  })
  if (props.isMulti) {
    values = <div className="d-flex align-items-center absolute-right-10">{values}</div>
  }

  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} className={`black-label ${mandatory}`}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      {values}
    </components.ValueContainer>
  )
}

export const checkboxOption = (props) => {
  return (
    <components.Option {...props} className="form-check robo-option">
      <input className="form-check-input robo-checkbox" type="checkbox" checked={props.isSelected} onChange={() => null} />
      <label className="form-check-label robo-checkbox-label">
        {props.children}
      </label>
    </components.Option>
  )
}

export const multiValue = props => {
  const allValues = props.getValue()
  let classNameAfter = ''

  if (allValues.length > 1) {
    const lastChild = allValues[allValues.length - 1].value === props.data.value
    if (!lastChild) {
      classNameAfter = 'comma-with-margin'
    }
  }

  return <span className={`${classNameAfter}`}>{props.children}</span>
}

export const menu = props => {
  return (
    <components.Menu {...props}>
      <div className="d-flex multiple-menu-header">
        <span className="mr-2 as-button" onClick={props.selectProps.selectAll}>Select all</span>
        <span className="as-button" onClick={props.selectProps.clearAll}>Clear all</span>
      </div>
      <div>{props.children}</div>
    </components.Menu>
  )
}
