import React from 'react'
import moment from 'moment'
import map from 'lodash/map'
import ProductContainerSlot from './ProductContainerSlot'
import { hasActiveReplenishment } from './shared'
import { ProductContainer, ReplenishmentOrder } from './types'

const LIQUID_CONTAINER_ROWS = 5
const LIQUID_CONTAINER_COLS = 6

const LIQUID_CONTAINER_SLOT_COUNT = LIQUID_CONTAINER_ROWS * LIQUID_CONTAINER_COLS

interface Props {
  searchQuery: string,
  currentProductCategoryId: number,
  containers: Record<number, ProductContainer>,
  currentDate: moment.Moment,
  expandContainer: Function,
  draftReplenishmentOrder: ReplenishmentOrder,
  activeReplenishmentOrders: ReplenishmentOrder[],
  orderSlots: Function,
  replenishmentView?: boolean
}

const LiquidsView: React.FC<Props> = ({
  searchQuery, currentProductCategoryId, containers, currentDate, expandContainer,
  draftReplenishmentOrder, activeReplenishmentOrders, orderSlots, replenishmentView
}) => {

  const slots = map(orderSlots(LIQUID_CONTAINER_COLS, LIQUID_CONTAINER_SLOT_COUNT), slotIndex => {
    return { slotNumber: slotIndex, container: containers[slotIndex] }
  })

  // slot numbers from human perspective
  const humanSlots = map(Array.from(Array(LIQUID_CONTAINER_SLOT_COUNT).keys()), slotIndex => {
    const slotNumber = slotIndex + 1

    return { slotNumber, container: containers[slotNumber] }
  })

  return (
    <div className='tab-content liquids' style={{ gridTemplateColumns: `repeat(${LIQUID_CONTAINER_COLS}, 1fr)` }}>
      {map(slots, (slot, index) =>
        <ProductContainerSlot
          key={slot.slotNumber}
          slotNumber={slot.slotNumber}
          humanSlotNumber={humanSlots[index].slotNumber}
          container={slot.container}
          currentDate={currentDate}
          expandContainer={expandContainer}
          searchQuery={searchQuery}
          currentProductCategoryId={currentProductCategoryId}
          isLiquid={true}
          replenishmentView={replenishmentView}
          hasDraftReplenishment={hasActiveReplenishment([draftReplenishmentOrder], 'liquid', slot.slotNumber)}
          hasSentReplenishment={hasActiveReplenishment(activeReplenishmentOrders, 'liquid', slot.slotNumber)}
        />
      )}
    </div>
  )
}

export default LiquidsView
