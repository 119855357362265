import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import forEach from 'lodash/forEach'
import consumer from '../../../src/cable'
import { t } from 'src/i18n'
import { ReplenishmentOrder } from '../stock_control/types'

enum BannerType {
  ORDER_CLOSED = 'order_closed',
  ORDER_EDIT_UNFINISHED = 'order_edit_unfinished',
  ORDER_SENT = 'order_sent'
}


const scope = 'replenishment.banner'

const ReplenishmentNotice = ({ order, isStockControlPage }) => {

  const [activeReplenishmentOrderState, setActiveReplenishmentOrderState] = useState({
    order_id: null,
    is_editing: false,
    state: ''
  })

  const channel = useRef(null)

  useEffect(() => {
    setActiveReplenishmentOrderState(order)
    forEach(consumer.subscriptions?.subscriptions, sub => {
      sub.unsubscribe()
    })
    channel.current = consumer.subscriptions.create({
      channel: 'ReplenishmentStateChannel'
    }, {
      received: handleChannelReceive
    })

    return () => {
      channel?.current.unsubscribe()
    }
  }, [])

  const handleChannelReceive = (data) => {
    setActiveReplenishmentOrderState(data.payload)
  }

  const getBannerType = () => {
    if(activeReplenishmentOrderState.state === 'draft' && activeReplenishmentOrderState.is_editing) {
      return BannerType.ORDER_EDIT_UNFINISHED
    } else if(activeReplenishmentOrderState.state === 'sent') {
      return BannerType.ORDER_SENT
    } else if(activeReplenishmentOrderState.state === 'closed') {
      return BannerType.ORDER_CLOSED
    } else {
      return null
    }
  }

  const bannerType = getBannerType()

  const editOrder = () => {
    axios.post('/replenishment_orders/enter_edit_order?format=json')
  }

  const openReplenishments = () => {
    window.location.replace('/stocks?replenishment=true&open-sidebar=true')
  }

  const OrderClosedBannerContent = () => {
    return (
      <div className="replenishment-notice">
        <span>{t('order_in_progress', { scope })}</span>
      </div>
    )
  }

  const OrderUnfinishedBannerContent = () => {
    return (
      <div className="replenishment-notice order-unfinished">
        <span>{t('order_not_finished', { scope })}</span>
        <span className="underlined" onClick={openReplenishments}>{t('finish_order', { scope })}</span>
      </div>
    )
  }

  const OrderSentBannerContent = () => {
    return (
      <div className="replenishment-notice">
        <span>{t('order_placed', { scope })}</span>
        <span className="green underlined" onClick={editOrder}>{t('edit_order', { scope })}</span>
      </div>
    )
  }
  if(!activeReplenishmentOrderState) {
    return
  }

  return (
    <div className="replenishment-notice-content">
      {(bannerType === BannerType.ORDER_EDIT_UNFINISHED) && <OrderUnfinishedBannerContent/>}
      {(bannerType === BannerType.ORDER_SENT && isStockControlPage) && <OrderSentBannerContent/>}
      {(bannerType === BannerType.ORDER_CLOSED && isStockControlPage) && <OrderClosedBannerContent/>}
    </div>
  )
}

export default ReplenishmentNotice
