import React, {Component, useContext} from 'react'
import moment from 'moment'
import axios from 'axios'
import map from 'lodash/map'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Modal from '../shared/modal'
import PlusMinusWrapper from '../shared/plusMinusWrapper'
import { ProductContainer, ContainerSlot, ProductWithCategory } from './types'
import { LocalizationContext, LocalizationContextType } from '../shared/contexts'
import I18n, { t } from '../../i18n'
import Icon from '../shared/icon'
import SelectProductDropdown from './SelectProductDropdown'

interface Props {
  expandedContainerSlot: ContainerSlot,
  expandedContainer: ProductContainer,
  closeContainer: React.MouseEventHandler,
  refreshStock: Function,
}

interface State {
  errors: string[],
  currentView: string,
  replacerContainer: ProductContainer,
  expandedContainer: ProductContainer,
  productOptions: ProductWithCategory[],
  dataLoading: boolean
}

const scope = 'stock_control.product_container'

class ProductContainerModal extends Component<Props, State> {
  static contextType = LocalizationContext

  blankContainer: ProductContainer
  weightUnit: string

  constructor(props: Props) {
    super(props)
    moment.locale(I18n.locale)

    this.blankContainer = {
      slot_number: props.expandedContainerSlot.number,
      human_slot_number: props.expandedContainerSlot.humanNumber,
      liquid: props.expandedContainerSlot.isLiquid,
      product: props.expandedContainer ? props.expandedContainer.product : null,
      expiration_date: null,
      initial_amount: 0,
      notes: '',
      product_container_warnings: {}
    }

    this.state = {
      errors: [],
      currentView: props.expandedContainer ? 'show' : 'edit',
      replacerContainer: this.blankContainer,
      expandedContainer: this.blankContainer,
      productOptions: [],
      dataLoading: true
    }
  }

  componentDidMount() {
    this.loadProductWithCategory()
    this.loadProductOptions()
  }

  loadProductWithCategory = () => {
    const productId = this.state.replacerContainer.product?.id

    if(productId) {
      axios.get(`/stocks/product?product_id=${productId}`).then(({ data }) => {
        this.setState({
          expandedContainer: {
            ...this.props.expandedContainer,
            product: data
          },
          dataLoading: false
        })
      })
    } else {
      this.setState({ dataLoading: false })
    }
  }

  loadProductOptions = () => {
    const type = this.props.expandedContainerSlot.isLiquid ? 'liquid_only' : 'solid_only'

    axios.get(`/products/products_with_categories?${type}=true`).then(({ data }) => {
      this.setState({ productOptions: data })
    })
  }

  changeView = (value) => {
    this.setState({ currentView: value })
  }

  handleFormCancel = () => {
    const { expandedContainer } = this.state

    if (expandedContainer.product) {
      this.changeView('show')
      this.resetReplacerContainerForm()
    } else {
      this.props.closeContainer(null)
    }
  }

  handleReplacerContainerChange = (name, value) => {
    let replacerContainer: ProductContainer = { ...this.state.replacerContainer }

    replacerContainer[name] = value

    this.setState({ replacerContainer })
  }

  handleReplacerContainerIntChange = (name, passedValue) => {
    const value = passedValue ? parseFloat(passedValue) : 0
    if (isNaN(value)) return

    this.handleReplacerContainerChange(name, Math.max(0, value))
  }

  resetReplacerContainerForm = () => {
    this.setState({ replacerContainer: { ...this.blankContainer } })
  }

  createProductContainer = () => {
    const { slot_number, liquid, product, expiration_date, initial_amount, notes } = this.state.replacerContainer
    axios.post('/product_containers?format=json', { product_container: {
      slot_number, liquid, expiration_date, initial_amount, notes, product_id: product.id
    }}).then(() => {
      this.props.refreshStock()
      this.changeView('saveResult')
    }).catch(error => {
      const errors = error.response.data.full_errors
      errors && this.setState({ errors: Object.values(errors) })
      this.changeView('saveError')
    })
  }

  removeProductContainer = () => {
    const { expandedContainer } = this.state

    axios.put(`/product_containers/${expandedContainer.id}/remove`).then(({ data }) => {
      this.props.refreshStock()
      this.changeView('removeResult')
    }).catch(({ response }) => {
      this.changeView('saveError')
    })
  }

  openEdit = (containerForEdit) => {
    this.setState({replacerContainer: {...containerForEdit}})
    this.changeView('edit')
  }

  onModalClose = (e) => {
    this.props.closeContainer(e)
    this.resetReplacerContainerForm()
  }

  modalShowContent = () => {
    let expandedContainer = { ... this.state.expandedContainer, human_slot_number: this.props.expandedContainerSlot.humanNumber}

    const expirationDate = moment(expandedContainer.expiration_date)
    const currentDate = moment().startOf('day')
    const daysUntilExpiration = expirationDate.diff(currentDate, 'days')

    const currentAmount = expandedContainer.initial_amount - expandedContainer.used_amount
    const amountPercent = (currentAmount / expandedContainer.initial_amount) * 100

    let amountStatus: string
    if (amountPercent <= 20) {
      amountStatus = 'danger'
    } else if (amountPercent <= 50)
      amountStatus = 'warning'
    else {
      amountStatus = 'success'
    }

    let expirationStatus: string
    if (daysUntilExpiration <= 2) {
      expirationStatus = 'danger'
    } else if (daysUntilExpiration <= 7) {
      expirationStatus = 'warning'
    } else {
      expirationStatus = 'success'
    }

    return (
      <div className='container-modal show'>
        <div className='product-info'>
          <div className="product-image">
            <img className='product-image' src={expandedContainer.product.image_url_medium} />
          </div>
          <div className="product-content">
            <span className='product-ancestry'>All categories {expandedContainer.product.ancestry_tree ? ` / ${expandedContainer.product.ancestry_tree}` : ''}</span>
            <span className='product-name'>{expandedContainer.product.name}</span>
          </div>
        </div>
        <div className='field'>
          <span className='field-name'>Amount</span>
          <span className='field-value'>
            {/* round up to 3 decimal places, no decimal places if not needed */}
            <span className={`${amountStatus}`}>{ Math.round(currentAmount*1000)/1000 }</span>
            <span> / {`${expandedContainer.initial_amount} ${this.weightUnit}`}</span>
          </span>
        </div>
        <div className='field'>
          <span className='field-name'>Expiration date</span>
          <span className='field-value'>
            <span className={expirationStatus}>{expirationDate.format('DD.MM.YYYY.')}</span>
          </span>
        </div>
        <div className='field-text'>
          <div className='field-name'>Notes</div>
          <div className='field-value'>{expandedContainer.notes}</div>
        </div>
        <div className='buttons'>
          <button className='btn btn-primary' onClick={() => this.openEdit(expandedContainer)}>{t('replace_container', { scope } )}</button>
        </div>
      </div>
    )
  }

  modalEditContent = () => {
    const { replacerContainer, expandedContainer, productOptions } = this.state
    const { product } = replacerContainer

    return (
      <div className='container-modal edit'>
        <div className="attribute-wrapper">
          <span className="input-label">Product</span>
          <div className="dropdown-wrapper">
            <SelectProductDropdown
              productType={replacerContainer.liquid ? 'liquid' : 'solid'}
              selectedOption={product}
              setSelectedOption={(product) => this.handleReplacerContainerChange('product', product)}
            />
          </div>
        </div>
        <div className='product-info'>
          <div className="product-image">
            <img className='product-image' src={product ? product.image_url_medium : 'images/product-placeholder.svg'} />
          </div>
          <div className="product-content">
            {product && <span className='product-ancestry'>All categories {product.ancestry_tree ? ` / ${product.ancestry_tree}` : ''}</span>}
            <span className='product-name'>{product ? product.name : 'New product' }</span>
          </div>
        </div>
        <div className="attribute-wrapper narrow">
          <span className='input-label'>Amount</span>
          <PlusMinusWrapper
            step={1}
            value={Number(replacerContainer.initial_amount)}
            onChange={(_, value) => this.handleReplacerContainerIntChange('initial_amount', value)}
          >
            <div className='subwrapper'>
              <input
                type='number'
                className={this.weightUnit === 'kg' ? 'with-unit-offset-oz-kg' : 'with-unit-offset-lbs'}
                value={Number(replacerContainer.initial_amount)}
                maxLength={4}
                onChange={({ target: { value } }) => this.handleReplacerContainerIntChange('initial_amount', value)}
              />
              <span className='input-unit'> {this.weightUnit}</span>
            </div>
          </PlusMinusWrapper>
        </div>
        <div className="attribute-wrapper">
          <span className='input-label'>Expiration date</span>
          <div className='input-wrapper date-wrapper'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                format="MM/DD/YYYY"
                value={replacerContainer.expiration_date ? moment(replacerContainer.expiration_date) : null}
                onChange={date => this.handleReplacerContainerChange('expiration_date', date)}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className='textarea-wrapper attribute-wrapper'>
          <span className='input-label'>Notes</span>
          <textarea className='input-control' rows={4}
            value={replacerContainer.notes}
            onChange={({ target: { value } }) => this.handleReplacerContainerChange('notes', value)}
          />
        </div>
        <div className='buttons'>
          {expandedContainer && expandedContainer.id &&
            <button className='btn btn-danger' onClick={() => this.changeView('removePrompt')}>Remove container</button>
          }
          <button className='btn btn-secondary' onClick={this.handleFormCancel}>Cancel</button>
          <button className='btn btn-primary'
            onClick={() => this.changeView('savePrompt')}
            disabled={!this.isReplacerContainerValid()}
          >
            {t('replace_container', { scope } )}
          </button>
        </div>
      </div>
    )
  }

  productImage = (container) => {
    if(container.product) {
      return (
        <div className='product-before'>
          <div className='product-image'><img src={container.product.image_url_medium}/></div>
          <div className='heavy-text'>{container.product.name}</div>
          <div className='product-expiration-date'>{moment(container.expiration_date).format('DD.MM.YYYY.')}</div>
        </div>
      )
    }
    return (
      <div className='product-before empty'>
        <div className='product-image'><Icon name='emptyProduct'/></div>
        <div className='heavy-text'>Empty</div>
      </div>
    )
  }

  modalSavePrompt = () => {
    const { expandedContainer, replacerContainer } = this.state

    return (
      <div className='container-modal save-prompt'>
        <div className='title-icon'>
          <Icon name='replace'/>
        </div>
        <div className='heavy-text'>{t('replace_container', { scope })}</div>
        <div className='product-before-after'>
          {this.productImage(expandedContainer)}
          <div className='product-after'>
            <div className='product-image'><img src={replacerContainer.product.image_url_medium}/></div>
            <div className='heavy-text'>{replacerContainer.product.name}</div>
            <div className='product-expiration-date'>{moment(replacerContainer.expiration_date).format('DD.MM.YYYY.')}</div>
          </div>
        </div>
        <p className='confirmation-prompt'>
          Are you sure you want to proceed?<br/>
          This cannot be undone!
        </p>
        <div className='buttons'>
          <button className='btn btn-secondary' onClick={() => this.changeView('edit')}>Cancel</button>
          <button className='btn btn-primary' onClick={this.createProductContainer}>Proceed</button>
        </div>
      </div>
    )
  }

  modalRemovePrompt = () => {
    const { expandedContainer } = this.state

    return (
      <div className='container-modal save-prompt'>
        <div className='title-icon danger'>
          <i className='fa fa-exclamation-circle' />
        </div>
        <div className='heavy-text'>Remove Container</div>
        <div className='product-before-after'>
          {expandedContainer &&
            <div className='product-after'>
              <div className='product-image'><img src={expandedContainer.product.image_url_medium}/></div>
              <div className='heavy-text'>{expandedContainer.product.name}</div>
              <div className='product-expiration-date'>{moment(expandedContainer.expiration_date).format('DD.MM.YYYY.')}</div>
            </div>
          }
        </div>
        <p className='confirmation-prompt'>
          Are you sure you want to proceed?<br/>
          This cannot be undone!
        </p>
        <div className='buttons'>
          <button className='btn btn-secondary' onClick={() => this.changeView('edit')}>Cancel</button>
          <button className='btn btn-primary' onClick={this.removeProductContainer}>Proceed</button>
        </div>
      </div>
    )
  }

  modalSaveResult = () => {
    const { replacerContainer } = this.state

    return (
      <div className='container-modal save-result'>
        <div className='title-icon success'>
          <i className='fa fa-check-circle' />
        </div>
        <div className='heavy-text'>Product changed</div>
        <div className='product-before-after'>
          <div className='product-after'>
            <div className='product-image'><img src={replacerContainer.product.image_url_medium}/></div>
            <div className='heavy-text'>{replacerContainer.product.name}</div>
            <div className='product-expiration-date'>{moment(replacerContainer.expiration_date).format('DD.MM.YYYY.')}</div>
          </div>
        </div>
        <p className='confirmation-prompt'>
          Product was successfully changed
        </p>
        <div className='buttons'>
          <button className='btn btn-primary' onClick={this.onModalClose}>Continue</button>
        </div>
      </div>
    )
  }

  modalRemoveResult = () => {
    return (
      <div className='container-modal save-result'>
        <div className='title-icon success'>
          <i className='fa fa-check-circle' />
        </div>
        <div className='heavy-text'>Container removed</div>
        <p className='confirmation-prompt mt-5'>
          Container was successfully removed
        </p>
        <div className='buttons'>
          <button className='btn btn-primary' onClick={this.props.closeContainer}>Continue</button>
        </div>
      </div>
    )
  }

  modalSaveError = () => {
    const { errors } = this.state

    return (
      <div className='container-modal save-result'>
        <div className='title-icon danger mt-5'>
          <i className='fa fa-times-circle' />
        </div>
        {map(errors, error =>
          <div className='heavy-text mt-1 mb-5'>{error}</div>
        )}
        {errors.length === 0 && <div className='heavy-text mt-1 mb-5'>Something went wrong</div>}
        <div className='buttons'>
          <button className='btn btn-primary' onClick={() => this.changeView('edit')}>Return</button>
        </div>
      </div>
    )
  }

  isReplacerContainerValid = () => {
    const { replacerContainer: { expiration_date, initial_amount, product } } = this.state

    return product && product.id && initial_amount && expiration_date
  }

  headerText = () => {
    const { currentView } = this.state
    const humanSlotNumber = this.state.replacerContainer.human_slot_number
    switch (currentView) {
      case 'show': return `Product container #${humanSlotNumber}`
      case 'edit': return `Replace container #${humanSlotNumber}`
      default: return null
    }
  }

  render() {
    const { currentView } = this.state

    const headerText = this.headerText()
    const { weight_scale } = this.context as LocalizationContextType

    this.weightUnit = {
      'metric': 'kg',
      'imperial': 'lbs'
    }[weight_scale]

      return (
        <>
          {!this.state.dataLoading &&
            <Modal onClose={this.props.closeContainer} widen='true'
              headerContent={headerText && <h3 className='header'>{headerText}</h3>}
            >
              {currentView === 'show' && this.modalShowContent()}
              {currentView === 'edit' && this.modalEditContent()}
              {currentView === 'savePrompt' && this.modalSavePrompt()}
              {currentView === 'saveResult' && this.props.expandedContainer && this.modalSaveResult()}
              {currentView === 'removePrompt' && this.modalRemovePrompt()}
              {currentView === 'removeResult' && this.modalRemoveResult()}
              {currentView === 'saveError' && this.modalSaveError()}
            </Modal>
          }
        </>
      )
    }
}

export default ProductContainerModal
