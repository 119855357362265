import React from 'react'
import { monthNames } from '../shared/common'

const loadPreviousMonth = (year, month, updateYearAndMonthFilter) => {
  let correctYear = year
  let prevMonth = month - 1
  if (prevMonth < 1) {
    correctYear -= 1
    prevMonth = 12
  }
  updateYearAndMonthFilter(correctYear, prevMonth)
}

const loadNextMonth = (year, month, updateYearAndMonthFilter) => {
  let nextMonth = month + 1
  let correctYear = year
  if (nextMonth > 12) {
    nextMonth = 1
    correctYear += 1
  }
  updateYearAndMonthFilter(correctYear, nextMonth)
}

const MenuMonthFilterControls = ({ year, month, updateYearAndMonthFilter }) => {
  return (
    <div className='text-center menu-month-filter' data-contains={`${year}-${month}`}>
      <button
        className='btn'
        data-contains='menu-month-filter-previous-month'
        onClick={() => loadPreviousMonth(year, month, updateYearAndMonthFilter)}
      >
        <i className='fa fa-chevron-left' />
      </button>
      <div className='section-heading d-inline-block'>
        <div className='section-heading-row'>{year}</div>
        <div className='section-heading-row bold'>{monthNames[month - 1]}</div>
      </div>
      <button
        className='btn'
        data-contains='menu-month-filter-next-month'
        onClick={() => loadNextMonth(year, month, updateYearAndMonthFilter)}
      >
        <i className='fa fa-chevron-right' />
      </button>
    </div>
  )
}

export default MenuMonthFilterControls
