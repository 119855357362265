/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker')

import 'material-design-lite/dist/material.min.css'
import 'font-awesome/css/font-awesome.css'
import '../src/bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../src/styles.scss'

import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import installComponents from '../src/components.js'
import startup from '../src/startup.js'

import 'bootstrap/js/dist/collapse' // for menu
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/tooltip'
// import 'bootstrap/js/dist/util'
// import 'bootstrap/js/dist/alert'
// import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'

Rails.start()
Turbolinks.start()
installComponents()
startup()
