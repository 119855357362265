import React, { Fragment } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'

interface Product {
  id: number,
  name: string,
  file_url: string,
}

interface ProductItemProps extends Product {
  isPlaceholder?: boolean,
  isDragging?: boolean,
  isSubcategoryProduct?: boolean;
  expandProduct: Function,
}

const DraggableProduct = ({ index, expandProduct, isSubcategoryProduct, ...product }) => {
  const operation = product.is_liquid ? 'add_sauce' : 'add_product'
  const productObj = { operation, product: product }

  return (
    <Draggable draggableId={JSON.stringify(productObj)} index={index}>
      {(provided, snapshot) => (
        <Fragment>
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`draggable-product ${snapshot.isDragging ? 'dragging' : ''}`}
            data-id={product.id}
            ref={provided.innerRef}
          >
            <ProductItem {...product as Product}
              expandProduct={expandProduct}
              isDragging={snapshot.isDragging}
              isSubcategoryProduct={isSubcategoryProduct}
            />
          </div>

          {snapshot.isDragging &&
            <ProductItem {...product as Product}
              expandProduct={expandProduct}
              isPlaceholder={true}
              isSubcategoryProduct={isSubcategoryProduct}
            />
          }
        </Fragment>
      )}
    </Draggable>
  )
}

const ProductItem: React.FC<ProductItemProps> = ({
  id, name, file_url, isPlaceholder, isDragging, expandProduct, isSubcategoryProduct
}) => {
  const productClassNames = classNames(
    'product',
    {
      'dragging': isDragging,
      'placeholder': isPlaceholder,
      'subcategory-product': isSubcategoryProduct
    }
  )

  return (
    <div className={`${productClassNames}`}>
      <img className='product-image' src={file_url} alt={name} />
      <span className='product-name'>{name}</span>

      <span className='product-expand-button' onClick={() => expandProduct(id)}>
        More
      </span>
    </div>
  )
}

export default DraggableProduct
