import setupAxios from './config/setupAxios'
import setupMoment from './config/setupMoment'
import Routes from './config/routes.js.erb'
import Turbolinks from 'turbolinks'
import jQuery from 'jquery'

export default function() {
  setupAxios()
  setupMoment()

  window.addEventListener('DOMContentLoaded', setupAxios)
  window.Routes = Routes

  document.addEventListener('turbolinks:load', () => {
    for (let form of document.querySelectorAll('form[data-turbo-enabled]')) {
      form.addEventListener("submit", function (event) {
        Turbolinks.visit(window.location.pathname + "?" + jQuery(event.target).serialize());
        event.preventDefault();
      });
    }
  })
}
