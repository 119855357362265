import React, { useState, useRef } from 'react'
import axios from 'axios'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import Select from 'react-select'
import { t, l } from '../../../i18n'
import SectionTitle from '../../shared/sectionTitle'
import FlashNotification from '../../shared/flashNotification'
import EmptyTablePlaceholder from "../../shared/EmptyTablePlaceholder";

const STATUS_NEW = 'new'
const STATUS_IN_PRODUCTION = 'in_production'
const STATUS_FINISHED = 'finished'
const ORDER_STATUSES = [STATUS_NEW, STATUS_IN_PRODUCTION, STATUS_FINISHED]

const statusBadgeClasses = {
  [STATUS_NEW]: 'info',
  [STATUS_IN_PRODUCTION]: 'primary',
  [STATUS_FINISHED]: 'success',
}

const UpdatableOrderStatus = ({ order, updateOrder }) => {
  return (
    <div className='dropdown'>
      <div
        className={`pointer badge badge-${statusBadgeClasses[order.status]}`}
        id={`order-${order.id}`}
        data-toggle='dropdown'
      >
        {t(`activerecord.attributes.configurator_order.statuses.${order.status}`)}
      </div>
      <div className='dropdown-menu' aria-labelledby={`order-${order.id}`}>
        {map(ORDER_STATUSES, status =>
          <div
            key={status}
            className={`dropdown-item pointer ${status === order.status ? 'font-weight-bold' : ''}`}
            onClick={() => updateOrder(order.id, { status })}
          >
            {t(`activerecord.attributes.configurator_order.statuses.${status}`)}
          </div>
        )}
      </div>
    </div>
  )
}

const UpdatableOrderDocumentation = ({ order, updateOrder }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [documentationUrl, setDocumentationUrl] = useState(order.documentation_url || '')
  const [editMode, setEditMode] = useState(!order.documentation_url)

  const updateDocumentationUrl = () => {
    updateOrder(order.id, { documentation_url: documentationUrl })

    if (!documentationUrl) {
      setIsOpen(false)
    } else {
      setEditMode(false)
    }
  }

  const cancelEdit = () => {
    if (order.documentation_url) {
      setEditMode(false)
      setDocumentationUrl(order.documentation_url)
    } else {
      setIsOpen(false) // No persisted value to show
      setDocumentationUrl('')
    }
  }

  const truncate = (str, charCount = 40) => (
    (str.length > charCount) ? `${str.substring(0, charCount)}...` : str
  )

  const handleBlur = ({ currentTarget, relatedTarget }) => {
    if (!currentTarget.contains(relatedTarget)) {
      setIsOpen(false)
    }
  }

  return (
    <div className='documentation-url' tabIndex={0} onBlur={handleBlur}>
      <i
        className={`documentation-url-btn bi bi-paperclip ${order.documentation_url ? 'present' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen &&
        <div className='popup-menu'>
          <div className='text-nowrap'>
            {!editMode && order.documentation_url && <>
              <a href={order.documentation_url} target='_blank'>
                <i className='bi bi-link mr-1' />
                <span>{truncate(order.documentation_url)}</span>
              </a>
              <i className='bi bi-pencil action-btn edit' onClick={() => setEditMode(true)} />
            </>}
            {editMode && <>
              <input
                type='text'
                value={documentationUrl}
                className='mr-4'
                placeholder={t('configurator_orders.documentation_url_placeholder')}
                onChange={({ target: { value } }) => setDocumentationUrl(value)}
              />
              <span className='action-btn save' onClick={updateDocumentationUrl}>{t('save').toLowerCase()}</span>
              <span className='mx-1'>|</span>
              <span className='action-btn cancel' onClick={cancelEdit}>{t('cancel').toLowerCase()}</span>
            </>}
          </div>
        </div>
      }
    </div>
  )
}

const TableRow = ({ order, updateOrder }) => {
  return (
    <tr>
      <td><UpdatableOrderStatus order={order} updateOrder={updateOrder} /></td>
      <td>{order.customer_information}</td>
      <td>
        <div>{`${order.fridge_feeders} ${t('configurator_orders.fridge').toLowerCase()}`}</div>
        <div>{`${order.non_fridge_feeders} ${t('configurator_orders.non_fridge').toLowerCase()}`}</div>
      </td>
      <td>{order.liquid_containers}</td>
      <td>{order.cooking_stations}</td>
      <td>{order.plate_blocks}</td>
      <td>
        <div>{`${order.small_serving_stations} ${t('configurator_orders.small').toLowerCase()}`}</div>
        <div>{`${order.big_serving_stations} ${t('configurator_orders.large').toLowerCase()}`}</div>
      </td>
      <td>{l('time.formats.full_no_seconds', order.created_at)}</td>
      <td className='text-center'>
        <UpdatableOrderDocumentation order={order} updateOrder={updateOrder} />
      </td>
    </tr>
  )
}

const Table = ({ orders, updateOrder }) => {
  const translateAttr = (attr) => t(`activerecord.attributes.configurator_order.${attr}`)

  return (
    <table className='table table-robis table-configurator-orders'>
      <thead className='thead-robis'>
        <tr>
          <th className='min-width'>{translateAttr('status')}</th>
          <th>{translateAttr('customer_information')}</th>
          <th>{translateAttr('feeders')}</th>
          <th>{translateAttr('liquid_containers')}</th>
          <th>{translateAttr('cooking_stations')}</th>
          <th>{translateAttr('plate_blocks')}</th>
          <th>{translateAttr('serving_stations')}</th>
          <th>{translateAttr('created_at')}</th>
          <th>{translateAttr('documentation')}</th>
        </tr>
      </thead>
      <tbody>
        {map(orders, order =>
          <TableRow key={order.id} order={order} updateOrder={updateOrder} />
        )}
      </tbody>
    </table>
  )
}

const ConfiguratorOrders = ({ orders: passedOrders }) => {
  const emptyNotification = { type: '', message: '', open: false }
  const [flashNotification, setFlashNotification] = useState(emptyNotification)

  const setFlashMessage = (type, message, timeOut = 3000) => {
    setFlashNotification({ type, message, open: true })
    setTimeout(() => setFlashNotification(emptyNotification), timeOut)
  }

  const [orders, setOrders] = useState(passedOrders)
  const filtersForm = useRef(null)
  const urlSearchParams = new URLSearchParams(window.location.search)

  const updateOrder = (id, params) => {
    axios.put(`/admin/configurator_orders/${id}`, { order: params }).then(({ data }) => {
      let newOrders = [...orders]

      const orderIndex = findIndex(newOrders, o => o.id === data.id)
      newOrders.splice(orderIndex, 1, data)

      setOrders(newOrders)
      setFlashMessage('success', t('saved_successfully'))
    }).catch(({ response }) => {
      console.log(response)
      setFlashMessage('danger', response.data)
    })
  }

  const statusOptions = [
    { value: 'all', label: 'All' },
    ...map(ORDER_STATUSES, status => (
      { value: status, label: t(`activerecord.attributes.configurator_order.statuses.${status}`) }
    ))
  ]

  return (
    <div className='row'>
      <div className='col-sm-12'>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <SectionTitle rows={['Configurator', 'Orders']} />
          <form className='d-flex justify-content-end w-50' data-turbo-enabled={true} ref={filtersForm}>
            <div className='w-50 right'>
              <Select className='react-select mr-2' classNamePrefix='react-select'
                name='status'
                placeholder='status'
                defaultValue={statusOptions.find(opt => opt.value === (urlSearchParams.get('status') || 'all'))}
                onChange={() => setTimeout(() => filtersForm.current.dispatchEvent(new Event('submit')), 150)}
                options={statusOptions}
              />
            </div>
          </form>
        </div>

        {!orders.length &&
          <EmptyTablePlaceholder heading="No orders yet"/>
        }

        {orders.length &&
          <Table orders={orders} updateOrder={updateOrder} />
        }

        {flashNotification.open && (
          <FlashNotification type={flashNotification.type} message={flashNotification.message} />
        )}
      </div>
    </div>
  )
}

export default ConfiguratorOrders
