import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Table, Tabs, Tab } from 'react-bootstrap'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'
import map from 'lodash/map'
import { t } from '../../i18n'

const POLLING_INTERVAL = 5000

const useInterval = (callback, interval) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }

    if (interval !== null) {
      let id = setInterval(tick, interval)
      return () => clearInterval(id)
    }
  }, [interval])
}

const Loader = () => (
  <div style={{
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ccc'
  }}>
    <i className='fa fa-circle-o-notch fa-spin fa-5x' />
  </div>
)

const Monitor = ({ activeQueues }) => {
  const getInitialTabKey = () => {
    const url = new URL(location)
    const paramValue = url.searchParams.get('active_tab_key')

    return parseInt(paramValue) || 1
  }

  const updateActiveTabUrlQuery = (activeTabKey) => {
    const url = new URL(location)
    url.searchParams.set('active_tab_key', activeTabKey)
    history.pushState(null, '', url)
  }

  const handleTabChange = (activeTabKey) => {
    updateActiveTabUrlQuery(activeTabKey)
    setActiveTabKey(activeTabKey)
  }

  const [activeTabKey, setActiveTabKey] = useState(getInitialTabKey())
  const [pollingEnabled, setPollingEnabled] = useState(false)

  const props = {
    pollingEnabled
  }

  const tabs = [
    {
      title: t('admin.monitor.system'),
      component: <SystemTab {...props} dataUrl={'/api/internal/work_batch/system_status'} />
    },
    {
      title: t('admin.monitor.lockers'),
      component: <BatchTable {...props} dataUrl={'/api/internal/work_batch/lockers_status'} />
    },
    {
      title: t('admin.monitor.robot_buffer'),
      component: <BatchTable {...props} dataUrl={'/api/internal/work_batch/main_batch_status'} />
    },
    {
      title: t('admin.monitor.robot'),
      component: <BatchTable {...props} dataUrl={'/api/internal/work_batch/main_batch_status?work_batch=true'} />
    },
    ...flatMap(activeQueues, boilerNr =>
      ([
        {
          title: `${t('admin.monitor.boiler_buffer')} ${boilerNr}`,
          component: <BatchTable {...props} dataUrl={`/api/internal/work_batch/boiler_batch_status?boiler_nr=${boilerNr}`} />
        },
        {
          title: `${t('admin.monitor.boiler')} ${boilerNr}`,
          component: <BatchTable {...props} dataUrl={`/api/internal/work_batch/boiler_batch_status?boiler_nr=${boilerNr}&work_batch=true`} />
        }
      ])
    )
  ]

  return (
    <div>
      <div className='text-right'>
        <button
          className={`btn mb-3 ${pollingEnabled ? 'btn-success' : 'btn-secondary'}`}
          onClick={() => setPollingEnabled(!pollingEnabled)}
        >
          <i className='mr-2 bi bi-arrow-clockwise' />
          {pollingEnabled ? 'Disable polling' : 'Enable polling'}
        </button>
      </div>

      <Tabs
        className='nav-tabs'
        activeKey={activeTabKey}
        animation={false}
        id='batch-tabs'
        onSelect={handleTabChange}
      >
        {map(tabs, (tab, index) =>
          <ConditionalTab
            key={`t${index + 1}`}
            tabClassName='nav-link'
            eventKey={index + 1}
            activeTabKey={activeTabKey}
            title={tab.title}
          >
            {tab.component}
          </ConditionalTab>
        )}
      </Tabs>
    </div>
  )
}

const SystemTab = ({ pollingEnabled, dataUrl }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [settings, setSettings] = useState({})
  const [robotVars, setRobotVars] = useState({})
  const [boilersVars, setBoilersVars] = useState({})

  useEffect(() => {
    loadData(true)
  }, [])

  useInterval(() => {
    loadData()
  }, pollingEnabled ? POLLING_INTERVAL : null)

  const loadData = (initial = false) => {
    if (initial) setLoading(true)

    axios.get(dataUrl).then(({ data }) => {
      setSettings(data.settings)
      setRobotVars(data.robot)
      setBoilersVars(data.boilers)
      setLoading(false)
    }).catch(({ response }) => {
      setErrorMessage(response.data)
      setLoading(false)
    })
  }

  if (errorMessage) return <p>{errorMessage}</p>
  if (loading) return <Loader/>

  return (
    <div className='mt-3'>
      <h4>{t('admin.monitor.settings')}</h4>
      <KeyValueTable keyValues={settings} />
      <hr/>
      <div className='row'>
        <div className='col-lg-6'>
          {map(Object.keys(boilersVars), boilerNr =>
            <div key={boilerNr}>
              <h4>{`${t('admin.monitor.boiler')} ${boilerNr}`}</h4>
              <KeyValueTable keyValues={boilersVars[boilerNr]} />
            </div>
          )}
        </div>
        <div className='col-lg-6'>
          <h4>{t('admin.monitor.robot')}</h4>
          <KeyValueTable keyValues={robotVars} />
        </div>
      </div>
    </div>
  )
}

const batchTdClass = (value) => !!value ? 'table-success' : ''

const tdClass = (key, value) => {
  const emptyValue = !value
  const emptyArray = Array.isArray(value) && value.length === 0

  if (emptyValue || emptyArray) return '' // no highlight for blank values (0, false, [])

  const keyPath = key.split('.')
  const lastKey = keyPath[keyPath.length - 1]
  const errorKeys = ['Error', 'HwError', 'ExeError', 'ExeErrorID']

  return includes(errorKeys, lastKey) ? 'table-danger' : 'table-success'
}

const exeErrorMessage = (exeErrorId) => {
  return {
    10010: 'Object number too small',
    10020: 'Object number too big',
    10030: 'Parameter number too small',
    10040: 'Parameter number too big',
    10050: 'Gripper object invalid',
    10060: 'Boiler not ready',
    10070: 'Gripper service has no solution',
    10080: 'Cup dispensing failed',
    10090: 'Double cup dispensing',
    10100: 'Gripper load changed',
    10110: 'Tray pick failure',
    10111: 'Gripper homing blocked',
    10120: 'Schnek dosing failed',
  }[exeErrorId]
}

const tdContent = (key, value) => {
  const strValue = value?.toString()

  // Decorate execution error ID with message
  if (key === 'gRobotCtrlInterface.Status.Info.ExeErrorID' && !!value) {
    return `${strValue} - ${exeErrorMessage(value)}`
  }

  return strValue
}

const KeyValueTable = ({ keyValues }) => {
  return (
    <Table responsive bordered hover className='table-sm mt-2'>
      <thead>
        <tr>
          <th>{t('admin.monitor.variable')}</th>
          <th>{t('admin.monitor.value')}</th>
        </tr>
      </thead>

      <tbody>
        {map(Object.keys(keyValues), (key, rowIndex) =>
          <tr key={rowIndex}>
            <td>{key}</td>
            <td className={tdClass(key, keyValues[key])}>
              {tdContent(key, keyValues[key])}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const BatchTable = ({ pollingEnabled, dataUrl }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [keys, setKeys] = useState([])
  const [valuesTable, setValuesTable] = useState([])

  useEffect(() => {
    loadData(true)
  }, [])

  useInterval(() => {
    loadData()
  }, pollingEnabled ? POLLING_INTERVAL : null)

  const loadData = (initial = false) => {
    if (initial) setLoading(true)

    axios.get(dataUrl).then(({ data }) => {
      setKeys(data.keys)
      setValuesTable(data.values_table)
      setLoading(false)
    }).catch(({ response }) => {
      setErrorMessage(response.data)
      setLoading(false)
    })
  }

  if (errorMessage) return <p>{errorMessage}</p>
  if (loading) return <Loader />

  return (
    <Table responsive bordered hover className='table-sm mt-2'>
      <thead>
        <tr>
          <th>#</th>
          {map(keys, (key, index) => <th key={index}>{key}</th>)}
        </tr>
      </thead>

      <tbody>
        {map(valuesTable, (row, rowIndex) =>
          <tr key={rowIndex}>
            <td>{rowIndex + 1}</td>
            {map(row, (value, colIndex) =>
              <td className={batchTdClass(value)} key={colIndex}>
                {value?.toString()}
              </td>
            )}
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const ConditionalTab = ({ tabClassName, activeTabKey, eventKey, title, children }) => {
  if (activeTabKey !== eventKey) return null

  return (
    <Tab
      tabClassName={tabClassName}
      eventKey={eventKey}
      title={title}
    >
      {children}
    </Tab>
  )
}

export default Monitor
