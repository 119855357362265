import MonitorScreen from './components/monitor_screen'
import UsersEditScreen from './components/users/UsersEditScreen'
import LocationEditScreen from './components/locations/LocationEditScreen'
import Recipes from './components/recipes'
import RecipesEditScreen from './components/recipes/RecipesEditScreen'
import MenuTable from './components/menus/menuTable'
import Orders from './components/orders'
import OrderModal from './components/orders/orderModal'
import Reports from './components/reports'
import ProductCategoriesIndex from './components/product_categories/ProductCategoriesIndex'
import StockControl from './components/stock_control'
import ConfiguratorOrders from './components/configurator/orders'
import ReplenishmentNotice from './components/shared/ReplenishmentNotice'
import EmptyTablePlaceholder from './components/shared/EmptyTablePlaceholder'
import SchedulerRunReportsIndex from './components/admin/scheduler_run_reports'
import ExternalApiWebhookLogs from './components/admin/external_api_webhook_logs'
import CustomVision from './components/admin/custom_vision'
import WebpackerReact from 'webpacker-react'

export default function () {
  WebpackerReact.setup({
    MonitorScreen,
    UsersEditScreen,
    LocationEditScreen,
    Recipes,
    RecipesEditScreen,
    MenuTable,
    Orders,
    OrderModal,
    Reports,
    ProductCategoriesIndex,
    StockControl,
    ConfiguratorOrders,
    ReplenishmentNotice,
    EmptyTablePlaceholder,
    SchedulerRunReportsIndex,
    ExternalApiWebhookLogs,
    CustomVision
  })
}
