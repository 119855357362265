import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { ProductWithCategory, ReplenishmentOrderAction } from '../types'
import { LocalizationContext } from '../../shared/contexts'
import Modal from '../../shared/modal'
import Icon from '../../shared/icon'
import { t } from 'src/i18n'
import MissingProductImage from 'images/missing_image.svg'

enum ProductReplenishmentMode {
  VIEW = 'view',
  REPLACE = 'replace',
  REFILL = 'refill'
}

const scope= "replenishment.replenishment_modal"

const RefillProductModal = ({ selectedContainer, closeModal, toggleSidebar }) => {

  const goToBasket = () => {
    toggleSidebar(true)
    closeModal()
  }

  return (
    <Modal onClose={closeModal}>
      <div className='container-modal save-prompt refill-product-modal'>
        <div className='title-icon'>
          <Icon name='replace'/>
        </div>
        <div className='heavy-text'>{t('container_header_refill', {scope, slotNumber: selectedContainer.slot_number})}</div>
        <img className="replenished-product-image" src={selectedContainer.product.image_url_medium} />
        <p className='confirmation-prompt'>
          {t('basket_success', { scope })}
        </p>
        <div className='buttons'>
          <button className='btn btn-secondary' onClick={goToBasket}>{t('go_to_basket', { scope })}</button>
          <button className='btn btn-primary' onClick={closeModal}>{t('continue', { scope })}</button>
        </div>
      </div>
    </Modal>
  )
}

const ReplaceProductModal = ({ selectedContainer, closeModal, containerSlot, draftReplenishmentOrder, updateCart, toggleSidebar, updateProductReplenishments}) => {
  const initialContainer = {...selectedContainer}
  const [products, setProducts] = useState<ProductWithCategory[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [newContainer, setNewContainer] = useState({ ...selectedContainer, product: null })

  useEffect(() => {
    fetchProducts()
  }, []);

  const fetchProducts = () => {
    const type = containerSlot.isLiquid ? 'liquid_only' : 'solid_only'

    axios.get(`/products/products_with_categories?${type}=true`).then(({ data }) => {
      setProducts(data)
    })
  }

  const closeModals = () => {
    closeModal()
    setShowConfirmationModal(false)
  }

  const addToReplenishmentCart = (empty = false) => {
    const containerType = containerSlot.isLiquid ? 'liquid' : 'solid'

    const productReplenishment = {
      product: empty? null : newContainer.product,
      containerType: containerType,
      slotNumber: containerSlot.number,
      productContainer: newContainer
    }

    updateProductReplenishments(productReplenishment)
  }

  const openCart = (empty) => {
    addToReplenishmentCart(empty)
    setShowConfirmationModal(true)
  }

  return (
    <Modal onClose={closeModal} widen='true' medium='true'
      headerContent={showConfirmationModal ? null : <h3 className='header'>{t('replace_product', { scope })}</h3>}>
      {!showConfirmationModal &&
        <div className='container-modal replace'>
          <div className='replace-product'>
            <img className='product-image' src={newContainer.product ? newContainer.product.image_url_medium : 'images/product-placeholder.svg'} />
            <div className='input-wrapper'>
              <Autocomplete
                options={products.sort((a, b) => -b.product_category_name.localeCompare(a.product_category_name))}
                groupBy={product => product.product_category_name}
                sx={{ width: '100%' }}
                getOptionLabel={product => product.name}
                value={newContainer.product as any}
                isOptionEqualToValue={(opt, value) => opt.id === value.id}
                onChange={(_e, product) => setNewContainer({...newContainer, product: product})}
                renderInput={(params) => <TextField {...params}/>}
              />
            </div>
          </div>
          <div className='buttons'>
          <button className='btn btn-secondary' onClick={() => openCart(true)}>{t('empty_container', { scope })}</button>
            <button className='btn btn-secondary' onClick={closeModal}>{t('cancel')}</button>
            <button className='btn btn-primary' disabled={!newContainer.product} onClick={() => openCart(false)}>{t('save_changes', { scope })}</button>
          </div>
        </div>
      }
      {showConfirmationModal &&
        <ReplaceProductConfirmationModal
          initialContainer={initialContainer}
          newProduct={newContainer.product}
          containerSlot={containerSlot}
          closeModal={closeModals}
          toggleSidebar={toggleSidebar}
        />
      }
    </Modal>
  )
}

const ProductImage = ({ product }) => {
  if(product) {
    return (
      <div className='product-before'>
        <div className='product-image'><img src={product.image_url_medium}/></div>
        <div className='heavy-text'>{product.name}</div>
      </div>
    )
  }
  return (
    <div className='product-before empty'>
      <div className='product-image'><Icon name='emptyProduct'/></div>
      <div className='heavy-text'>{t('replenishment.replenishment_modal.empty')}</div>
    </div>
  )
}

const ReplaceProductConfirmationModal = ({ initialContainer, newProduct, closeModal, containerSlot, toggleSidebar }) => {

  const goToBasket = () => {
    toggleSidebar(true)
    closeModal()
  }

  return (
    <div className='container-modal save-prompt'>
        <div className='title-icon'>
          <Icon name='replace'/>
        </div>

        <div className='heavy-text'>{t('container_header_product_change', { scope, slotNumber: containerSlot.number })}</div>
        <div className='product-before-after'>
          <ProductImage product={initialContainer.product}/>
          <div className='product-after'>
            <div className='product-image'> {newProduct ? <img src={newProduct.image_url_medium}/> : <Icon name='emptyProduct'/>}</div>
            <div className='heavy-text'>{newProduct ? newProduct.name : 'Empty'}</div>
          </div>
        </div>
        <p className='confirmation-prompt'>
        {t('basket_success', { scope })}
        </p>
        <div className='buttons'>
          <button className='btn btn-secondary' onClick={goToBasket}>{t('go_to_basket', { scope })}</button>
          <button className='btn btn-primary' onClick={closeModal}>{t('continue', { scope })}</button>
        </div>
      </div>
  )
}

const ProductReplenishmentViewModal = ({
  selectedContainer, containerSlot, closeModal, setCurrentMode, updateProductReplenishments, containerAlreadyReplenished, draftReplenishmentOrder
}) => {
  const { weight_scale } = useContext(LocalizationContext)
  const weightUnit = {
    metric: 'kg',
    imperial: 'lbs',
  }[weight_scale]

  const replenishmentProduct = () => {
    const containerType = containerSlot.isLiquid ? 'liquid' : 'solid'
    return draftReplenishmentOrder.productReplenishments.find(replenishment =>
      replenishment.slotNumber == containerSlot.number && replenishment.containerType == containerType)
  }

  const productChange = () => {
    return !!replenishmentProduct() && replenishmentProduct().product.id !== selectedContainer.product.id
  }

  const addToReplenishmentCart = () => {
    const containerType = containerSlot.isLiquid ? 'liquid' : 'solid'

    const productReplenishment = {
      product: selectedContainer.product,
      containerType: containerType,
      slotNumber: containerSlot.number,
      productContainer: selectedContainer
    }
    updateProductReplenishments(productReplenishment)
  }

  const replenishedContainerText = () => {
    return t(productChange() ? 'product_change' : 'to_refill', { scope })
  }

  const selectedContainerAlreadyReplenished = () => {
    return containerAlreadyReplenished(containerSlot.isLiquid ? 'liquid' : 'solid', containerSlot.number)
  }

  const headerContent = () => {
    if(selectedContainerAlreadyReplenished()) {
      return (
        <>
          <h3 className='header'>{t('product_container', { scope, slotNumber: selectedContainer.slot_number })}</h3>
          <span className='replenishment-header-text'>{replenishedContainerText()}</span>
        </>
      )
    }

    return (<h3 className='header'>{t('product_container', { scope, slotNumber: selectedContainer.slot_number })}</h3>)
  }

  const orderEditable = () => {
    return draftReplenishmentOrder.state == 'draft'
  }

  const ProductChangeMainInfo = () => {
    return (
      <>
        <div className='product-info'>
        <div className="product-image">
          <img className='product-image' src={replenishmentProduct().previousProduct.imageUrl} />
        </div>
        <div className="product-content">
          <span className='product-ancestry'>All categories / {replenishmentProduct().previousProduct.ancestry_tree}</span>
          <span className='product-name'>{replenishmentProduct().previousProduct.name || t('empty_container', { scope })}</span>
          {productChange() && <span className='replenishment-msg active'>Active</span>}
        </div>
      </div>
      <div className='product-info'>
        <div className="product-image">
          <img className='product-image' src={replenishmentProduct().product.imageUrl || MissingProductImage} />
        </div>
        <div className="product-content">
          <span className='product-ancestry'>All categories / {replenishmentProduct().product.ancestry_tree}</span>
          <span className='product-name'>{replenishmentProduct().product.name || t('empty_container', { scope })}</span>
          <span className='replenishment-msg after-replenishment'>After replenishment</span>
        </div>
      </div>
      </>
    )
  }

  const ProductRefillMainInfo = () => {
    return (
      <div className='product-info'>
        <div className="product-image">
          <img className='product-image' src={selectedContainer.product.image_url_medium} />
        </div>
        <div className="product-content">
          <span className='product-ancestry'>All categories / {selectedContainer.product.ancestry_tree}</span>
          <span className='product-name'>{selectedContainer.product.name}</span>
        </div>
      </div>
    )
  }

  const ProductMainInfo = () => {
    if(productChange()) {
      return <ProductChangeMainInfo/>
    } else {
      return <ProductRefillMainInfo/>
    }
  }

  const refillProduct = () => {
    addToReplenishmentCart()
    setCurrentMode(ProductReplenishmentMode.REFILL)
  }

  return(
    <Modal onClose={closeModal} widen='true'
      headerContent={headerContent()}>
      <div className='container-modal show'>
      <ProductMainInfo/>
        <div className='field'>
          <span className='field-name'>{t('replenishment.replenishment_modal.amount')}</span>
          <span className='field-value'>
            {selectedContainerAlreadyReplenished() ?
              <span className='replenishment'>{t('replenishment.replenishment_modal.to_be_updated')}</span> :
              <>
                <span className={`${selectedContainer.amountStatus}`}>{selectedContainer.remaining_amount}</span>
                <span> / {`${selectedContainer.initial_amount} ${weightUnit}`}</span>
              </>
            }
          </span>
        </div>
        <div className='field'>
          <span className='field-name'>{t('replenishment.replenishment_modal.expiration_date')}</span>
          <span className='field-value'>
            {selectedContainerAlreadyReplenished() ?
              <span className='replenishment'>{t('replenishment.replenishment_modal.to_be_updated')}</span> :
              <span className={selectedContainer.expirationStatus}>{moment(selectedContainer.expiration_date).format('DD.MM.YYYY.')}</span>
            }
          </span>
        </div>
        <div className='field-text'>
          <div className='field-name'>{t('replenishment.replenishment_modal.notes')}</div>
          <div className='field-value'>{selectedContainer.notes}</div>
        </div>
        <div className='buttons'>
          <button className='btn btn-secondary' onClick={closeModal}>{t('cancel')}</button>
          <button className={`btn btn-secondary btn-replace ${orderEditable() ? '' : 'disabled'}`}
            disabled={!orderEditable()} onClick={() => setCurrentMode(ProductReplenishmentMode.REPLACE)}>
            {t('replenishment.replenishment_modal.replace_product')}
          </button>
          {!selectedContainerAlreadyReplenished() &&
            <button
              className={`btn btn-primary btn-refill ${orderEditable() ? '' : 'disabled'}`}
              disabled={!orderEditable()}
              onClick={refillProduct}>
              {t('replenishment.replenishment_modal.refill_product')}
            </button>
            }
        </div>
      </div>
    </Modal>
  )
}

export const ProductReplenishmentModal = ({ selectedContainer, closeModal, containerSlot, draftReplenishmentOrder, updateCart, toggleSidebar }) => {
  const [currentMode, setCurrentMode] = useState(ProductReplenishmentMode.VIEW)

  const containerAlreadyReplenished = (containerSlotType, containerSlotNumber) => {
    return draftReplenishmentOrder.productReplenishments.some(replenishment => {
      const matchingSlotType = (replenishment.containerType === containerSlotType)
      const matchingSlotNumber = (replenishment.slotNumber === containerSlotNumber)

      return matchingSlotType && matchingSlotNumber
    })
  }

  const updateProductReplenishments = (newProductReplenishment) => {
    if(containerAlreadyReplenished(newProductReplenishment.containerType, newProductReplenishment.slotNumber)) {

      const updatedProductReplenishments = draftReplenishmentOrder.productReplenishments.map(replenishment =>
        (replenishment.containerType == newProductReplenishment.containerType && replenishment.slotNumber == newProductReplenishment.slotNumber)
          ? { ...newProductReplenishment, id: replenishment.id }
          : replenishment)

      updateCart({
        ...draftReplenishmentOrder,
        productReplenishments: updatedProductReplenishments
      }, ReplenishmentOrderAction.ADD_ITEM)
    } else {
      updateCart({
        ...draftReplenishmentOrder,
        productReplenishments: [...draftReplenishmentOrder.productReplenishments, newProductReplenishment]
      }, ReplenishmentOrderAction.ADD_ITEM)
    }
  }

  return(
    <>
      {(currentMode === ProductReplenishmentMode.VIEW && selectedContainer) &&
        <ProductReplenishmentViewModal
          selectedContainer={selectedContainer}
          containerSlot={containerSlot}
          closeModal={closeModal}
          setCurrentMode={setCurrentMode}
          updateProductReplenishments={updateProductReplenishments}
          containerAlreadyReplenished={containerAlreadyReplenished}
          draftReplenishmentOrder={draftReplenishmentOrder}
        />
      }
      {(currentMode === ProductReplenishmentMode.REPLACE || !selectedContainer) &&
        <ReplaceProductModal
          selectedContainer={selectedContainer}
          closeModal={closeModal}
          containerSlot={containerSlot}
          draftReplenishmentOrder={draftReplenishmentOrder}
          updateCart={updateCart}
          toggleSidebar={toggleSidebar}
          updateProductReplenishments={updateProductReplenishments}
        />
      }
      {currentMode === ProductReplenishmentMode.REFILL &&
        <RefillProductModal
          selectedContainer={selectedContainer}
          closeModal={closeModal}
          toggleSidebar={toggleSidebar}
        />
      }
    </>
  )
}
