import React, { PureComponent, Fragment } from 'react'
import Select from 'react-select'
import moment from 'moment'
import classNames from 'classnames'
import find from 'lodash/find'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import pull from 'lodash/pull'
import filter from 'lodash/filter'
import compact from 'lodash/compact'
import MenuMonthFilterControls from './menuMonthFilterControls'
import Modal from '../shared/modal'
import Calendar from '../shared/calendar'
import SectionTitle from '../shared/sectionTitle'
import { monthNames, customSelectOption, customSelectValue } from '../shared/common'

class DuplicatingCalendar extends React.Component {
  state = {
    year: moment().startOf('month').year(),
    month: moment().startOf('month').month() + 1
  }

  updateYearAndMonthFilter = (year, month) => {
    this.setState({ year, month })
  }

  renderDateCellContent = (date) => {
    if (!date) return

    const { originalDate, selectedDates } = this.props
    const currentDate = moment().format('YYYY-MM-DD')

    const classes = classNames(
      'calendar-date',
      {
        'selected': includes(selectedDates, date),
        'today': date === currentDate,
        'original': date === originalDate
      }
    )

    return (
      <div className={classes} onClick={() => this.props.toggleSelectedDate(date)}>
        <span>{moment(date).format('DD.MM')}</span>
      </div>
    )
  }

  render = () => {
    const { selectedDates } = this.props
    const { month, year } = this.state

    return (
      <div>
        <div className='d-flex justify-content-end mb-4'>
          <MenuMonthFilterControls
            year={this.state.year}
            month={this.state.month}
            updateYearAndMonthFilter={this.updateYearAndMonthFilter}
          />
        </div>
        <Calendar
          className='table-borderless table-calendar'
          month={month}
          year={year}
          renderDateCell={(date, idx) => <td key={idx}>{this.renderDateCellContent(date)}</td>}
        />
      </div>
    )
  }
}

class MenuModal extends PureComponent {
  constructor(props) {
    super(props)

    this.blankMenuRecipe = {
      id: null,
      recipe_id: null,
      recipe_name: ''
    }

    this.state = {
      duplicating: false,
      selectedDates: [],
      duplicateDates: [],
      date: props.date,
      menu: props.menuItem,
      menuRecipes: (props.menuItem && props.menuItem.menu_recipes.length > 0)
        ? props.menuItem.menu_recipes
        : [this.blankMenuRecipe]
    }
  }

  handleSelectChange = (index, opt) => {
    let menuRecipes = [...this.state.menuRecipes]

    if (opt) {
      menuRecipes[index] = { ...menuRecipes[index], recipe_id: opt.value, recipe_name: opt.label }
    } else {
      menuRecipes[index] = this.blankMenuRecipe
    }

    this.setState({ menuRecipes }, () => console.log(this.state.menuRecipes))
  }

  toggleSelectedDate = (date) => {
    if (date === this.state.date) return
    let selectedDates = [...this.state.selectedDates]

    if (includes(selectedDates, date)) {
      pull(selectedDates, date)
    } else {
      selectedDates.push(date)
    }

    this.setState({ selectedDates })
  }

  renderMainActions = () => {
    const { menu, menuRecipes, date, duplicateDates } = this.state
    const recipeIds = compact(map(menuRecipes, mr => mr.recipe_id))

    return (
      <Fragment>
        <button
          disabled={isEmpty(recipeIds)}
          className='btn btn-primary'
          onClick={() => this.props.saveMenu({
            dates: [date, ...duplicateDates],
            menu: { ...menu, recipe_ids: map(menuRecipes, mr => mr.recipe_id) }
          })}
        >
          Save
        </button>
        <button
          className='btn btn-secondary'
          onClick={() => this.setState({ duplicating: true })}
        >
          Duplicate
        </button>
        {menu && menu.id &&
          <button
            className='btn btn-danger'
            onClick={() => this.props.destroyMenu(menu.id)}
          >
            Delete
          </button>
        }
      </Fragment>
    )
  }

  renderDuplicateActions = () => {
    const { selectedDates, duplicateDates } = this.state

    return (
      <Fragment>
        <button
          className='btn btn-primary'
          onClick={() => this.setState({ duplicating: false, duplicateDates: selectedDates.sort() })}
        >
          {`Duplicate (${selectedDates.length})`}
        </button>
        <button
          className='btn btn-secondary'
          onClick={() => this.setState({ duplicating: false, selectedDates: duplicateDates })}
        >
          Cancel
        </button>
      </Fragment>
    )
  }

  selectOptions = () => (
    map(this.props.recipes, ({ id, name, attachment }) =>
      ({ value: id, label: name, imageUrl: attachment ? attachment.file_url : null })
    )
  )

  visibleSelectOptions = () => (
    filter(this.selectOptions(), opt =>
      !find(this.state.menuRecipes, recipe => recipe.recipe_id === opt.value)
    )
  )

  selectValue = (menuRecipe) => (
    (menuRecipe && menuRecipe.recipe_id)
      ? find(this.selectOptions(), opt => opt.value === menuRecipe.recipe_id)
      : {}
  )

  addNewRecipe = () => {
    const { menuRecipes } = this.state
    if (this.selectOptions().length === menuRecipes.length) return

    this.setState({
      menuRecipes: [...menuRecipes, this.blankMenuRecipe]
    }, () => console.log(this.state.menuRecipes))
  }

  deleteRecipe = (index) => {
    let menuRecipes = [...this.state.menuRecipes]
    if (menuRecipes.length < 2) return

    menuRecipes.splice(index, 1)

    this.setState({ menuRecipes }, () => console.log(this.state.menuRecipes))
  }

  renderForm = () => {
    const { duplicateDates, menuRecipes } = this.state

    return (
      <form>
        <div className='form-group' data-contains='menu-item-modal-recipe-select'>
          <p className='mb-0'><label>Select recipes</label></p>
          {map(this.state.menuRecipes, (menuRecipe, index) =>
            <div className={`d-flex align-items-center mb-3 index-${index}`} key={index}>
              <Select
                className='react-select flex-1'
                classNamePrefix='react-select'
                placeholder='Select recipe'
                value={this.selectValue(menuRecipe)}
                onChange={(opt) => this.handleSelectChange(index, opt)}
                options={this.visibleSelectOptions()}
                components={{
                  Option: customSelectOption,
                  SingleValue: customSelectValue
                }}
              />
            <i
              className={`button-delete fa fa-trash-o fa-2x ml-3 ${menuRecipes.length < 2 ? 'disabled' : 'pointer'}`}
              onClick={() => this.deleteRecipe(index)}
              disabled={menuRecipes.length < 2}
            />
            </div>
          )}
          <span
            className={`button-add ${this.selectOptions().length === menuRecipes.length ? 'disabled' : 'pointer'}`}
            onClick={this.addNewRecipe}
          >
            <i className='fa fa-plus-circle large mr-2' />
            <span>Add</span>
          </span>
        </div>
        {duplicateDates.length > 0 &&
          <div className='duplicate-dates-list my-2'>
            <span className='mr-2'>Duplicated to:</span>
            {map(duplicateDates, date =>
              <span key={date} className='badge badge-light mr-2'>{moment(date).format('DD.MM')}</span>
            )}
          </div>
        }
      </form>
    )
  }

  render = () => {
    const { duplicating, date, selectedDates } = this.state
    const momentDate = moment(date)

    return (
      <Modal
        onClose={this.props.closeModal}
        footerContent={duplicating ? this.renderDuplicateActions() : this.renderMainActions()}
        footerClassName='mr-auto'
        widen={true}
        headerContent={
          <SectionTitle
            rows={[monthNames[momentDate.month()], momentDate.date()]}
            dataContains={momentDate.format('YYYY-MM-DD')}
            paddingLeft={0}
          />
        }
      >
        {duplicating
          ? <DuplicatingCalendar
              originalDate={date}
              selectedDates={selectedDates}
              toggleSelectedDate={this.toggleSelectedDate}
            />
          : this.renderForm()
        }
      </Modal>
    )
  }
}

export default MenuModal
