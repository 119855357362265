import { createContext } from 'react'

interface Currency {
  code: string,
  symbol: string,
}

export interface LocalizationContextType {
  currency?: Currency,
  weight_scale?: string,
  temperature_scale?: string,
}

export const LocalizationContext = createContext<LocalizationContextType>({})
