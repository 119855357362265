import React from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DropContainer } from '../shared/draggable'
import Icon from '../shared/icon'

const renderProductCategoryRow = (child, possibleColumns) => {
  const childrenText = child.children ? `( ${child.children.length} ${child.children.length > 1 ?  'products' : 'product'} )` : ''
  return (
    <td colSpan={possibleColumns.length}>
      <div>
        {child.file_url && <img src={child.file_url} /> || <Icon name='categoryPlaceholder' className='table-row-image' />}
        <a href={`/products/${child.id}`}>
          <span className="main-text">{child.name}</span>
        </a>
        <span className="gray-text">{childrenText}</span>
      </div>
    </td>
  )
}

const renderDynamicTableColumns = (child, possibleColumns, setObjectForShow, edit) => {
  return possibleColumns.filter(c => c.selected).map((column, index) => {
    let value = child[column.column_name]

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      value = value.name
    } else if (Array.isArray(value)) {
      value = value.map(v => v.name).join(', ')
    }

    if (column.column_name === 'name') {
      if (child.class_name === 'ProductCategory') {
        value =
          <div className="name-column">
            {child.file_url && <img className="table-row-image" src={child.file_url} /> || <Icon name='categoryPlaceholder' className='table-row-image' />}
            <a className="table-row-name" href={`/products/${child.id}`} onClick={(e) => e.stopPropagation()}>{child.name}</a>
          </div>
      } else {
        value =
          <div className="name-column">
            {child.file_url && <img className="table-row-image" src={child.file_url} /> || <Icon name='productPlaceholder' className='table-row-image' />}
            <span className='as-link pointer table-row-name' onClick={() => setObjectForShow(child)}>{child.name}</span>
          </div>
      }
    }

    if (edit && index === 0) {
      value =
        <div className='d-flex align-items-center'>
          <Icon name='dragBurger' className='drag-icon mr-2' />
          {value}
        </div>
    }

    const colSpan = possibleColumns.filter(column => !column.selected).length + 1

    return (
      <td
      colSpan={column.column_name === 'name' ? colSpan : 1}
      style={{maxWidth: "250px"}}
      className={`td-${column.column_name}`}
      key={`${child.id}-${child[column.column_name]}-${column.column_name}`}>
        {value}
      </td>
    )
  })
}

const onChildClick = (child, setObjectForShow, toggleTableRow) => {
  if(child.class_name === 'ProductCategory') {
    toggleTableRow(child)
  } else if (child.class_name === 'Product') {
    setObjectForShow(child)
  }
}

const TableBody = ({ categoryChildren, edit, setObjectForEdit, setObjectForShow, destroy, toggleTableRow, possibleColumns }) => {
  return categoryChildren.map((child, index) => {
    return (
      <React.Fragment key={`${child.class_name}-${child.id}`}>
        <Draggable draggableId={child.name} index={index} isDragDisabled={!edit}>
          {(provided, snapshot) => (
            <tr onClick={() => onChildClick(child, setObjectForShow, toggleTableRow)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`${snapshot.isDragging ? 'dragging-row' : ''} ${child.class_name == 'ProductCategory' ? 'gray-row' : ''}`}>
              {child.class_name == 'ProductCategory' && renderProductCategoryRow(child, possibleColumns)}
              {child.class_name == 'Product' && renderDynamicTableColumns(child, possibleColumns, setObjectForShow, edit)}
              <td style={{ width: 120 }}>
                <TableRowSettings
                  category={child}
                  editEnabled={edit}
                  setObjectForEdit={setObjectForEdit}
                  destroy={destroy}
                  toggleTableRow={toggleTableRow}
                />
              </td>
            </tr>
          )}
        </Draggable>
        {child.tableChildsOpen &&
          <tr className='nested-table'>
            <td colSpan={possibleColumns.filter(c => c.selected).length + 1} className='nested-table-cell'>
              <table className='table table-robis'>
                <Droppable droppableId={`droppable-${child.id}`} type="NESTED">
                  {(provided, snapshot) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      <NestedTableRow
                        passedIndex={index}
                        possibleColumns={possibleColumns}
                        child={child}
                        edit={edit}
                        setObjectForEdit={setObjectForEdit}
                        setObjectForShow={setObjectForShow}
                        destroy={destroy} />
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </td>
          </tr>
        }
      </React.Fragment>
    )
  })
}

const TableRowSettings = ({ category, editEnabled, setObjectForEdit, destroy, toggleTableRow }) => {
  let chevron = ''
  let editIcons = ''
  if (category.class_name === 'ProductCategory' && toggleTableRow) {
    const arrowDirection = category.tableChildsOpen ? 'arrowUp' : 'arrowDown'
    chevron = <Icon className='icon-button pointer-event-disabled' name={arrowDirection} onClick={() => toggleTableRow(category)} />
  }
  if (editEnabled) {
    editIcons = (
      <React.Fragment>
        <Icon className='icon-button' name='deleteIcon' onClick={() => destroy(category)} />
        <Icon className='icon-button' name='editIcon' onClick={e => setObjectForEdit(category)} />
      </React.Fragment>
    )
  }
  return (
    <div className='icon-container'>
      {editIcons}
      {chevron}
    </div>
  )
}

const NestedTableRow = ({ child, edit, setObjectForEdit, setObjectForShow, destroy, possibleColumns, passedIndex }) => {
  const unselectedColumnsSize = possibleColumns.filter(c => !c.selected).length
  const settingsWidth = 120 - unselectedColumnsSize * 18
  return child.children.map((categoryChild, index) => {
    return (
      <Draggable key={categoryChild.id} draggableId={`nested-${categoryChild.id}`} index={index + 1 * passedIndex + 1} isDragDisabled={!edit}>
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`nested-row ${snapshot.isDragging ? 'dragging-row' : ''}`}>
            {renderDynamicTableColumns(categoryChild, possibleColumns, setObjectForShow, edit)}
            <td style={{ width: settingsWidth, minWidth: 120 }}>
              <TableRowSettings
                category={categoryChild}
                editEnabled={edit}
                setObjectForEdit={setObjectForEdit}
                destroy={destroy}
              />
            </td>
          </tr>
        )}
      </Draggable>
    )
  })
}

class Table extends React.Component {
  renderSettings = () => {
    const selectableColumns = this.props.possibleColumns.filter(column => column.mandatory == false)
    if (this.props.settingsOpen) {
      return (
        <div className="like-modal">
          <div className="d-flex multiple-menu-header">
            <span className="mr-4 as-button" onClick={this.props.selectAll}>Select all</span>
            <span className="as-button" onClick={this.props.clearAll}>Clear all</span>
          </div>
          <DropContainer updatePositions={this.props.updatePositions} collection={selectableColumns}>
            {selectableColumns.map((column, index) => {
              return (
                <Draggable key={column.id} draggableId={`drag-${column.id}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className='check-container'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={column.column_name}>
                      <Icon name='dragBurger' className='drag-icon mr-3' />
                      <div className='form-check robo-option'>
                        <label className="form-check-label robo-checkbox-label" htmlFor={`check-${column.column_name}`}>
                          {column.column_label}
                        </label>
                        <input id={`check-${column.column_name}`} className="form-check-input robo-checkbox"
                          type="checkbox" checked={column.selected} onChange={(e) => this.props.handleSettingChange(column, e.target.checked)} />
                      </div>
                    </div>
                  )}
                </Draggable>
              )
            })}
          </DropContainer>
        </div>
      )
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    this.props.updateTablePositions(result)
  }

  colSpan = () => {
    return this.props.possibleColumns.filter(column => !column.selected).length + 1
  }

  render() {
    return (
      <table className='table table-robis'>
        <thead className='thead-robis'>
          <tr>
            {this.props.possibleColumns.filter(c => c.selected).map(column => {
              return <th colSpan={column.column_name === 'name' ? this.colSpan() : 1} className={column.column_name} key={column.column_label}>{column.column_label}</th>
            })}
            <th className='settings'>
              <div id='settings-container'>
                <Icon name='settingsIcon' className="icon-button float-right" onClick={this.props.toggleSettings} />
                {this.renderSettings()}
              </div>
            </th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
          <Droppable droppableId="droppable" type="MAIN">
            {(provided, snapshot) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {this.props.children}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    )
  }
}

export { Table, TableBody }
