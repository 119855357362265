import React, { Component, Fragment } from 'react'
import UsersEditForm from './UsersEditForm'
import SectionTitle from '../shared/sectionTitle'

class UsersEditScreen extends Component {
  render = () => {
    const { id } = this.props
    const actionName = id ? 'Edit' : 'New'

    return (
      <Fragment>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <SectionTitle rows={[actionName, 'User']} />
        </div>
        <div className='card card-robis'>
          <UsersEditForm id={id}/>
        </div>
      </Fragment>
    )
  }
}

export default UsersEditScreen
