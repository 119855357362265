import React from 'react'

const DurationInput = ({ className, value, onChange }) => {
  const getMinutesValue = () => {
    return value ? Math.floor(value / 60) : ''
  }

  const getSecondsValue = () => {
    if (value) {
      const seconds = value % 60
      return (seconds > 9) ? seconds : `0${seconds}`
    } else {
      return ''
    }
  }

  const minutes = Math.floor(value / 60)
  const seconds = value % 60

  return (
    <div className={`duration-input d-flex justify-content-center align-items-center ${className}`}>
      <input
        type='number'
        className='duration-minutes'
        placeholder='mm'
        value={getMinutesValue(value)}
        onChange={({ target: { value } }) => onChange(parseInt(value || 0, 10) * 60 + seconds)}
      />
      <span>:</span>
      <input
        type='number'
        className='duration-seconds'
        placeholder='ss'
        value={getSecondsValue(value)}
        onChange={({ target: { value } }) => onChange((minutes * 60) + parseInt(value || 0, 10))}
      />
    </div>
  )
}

export default DurationInput
