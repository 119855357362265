import React, { useState, useEffect } from 'react'
import axios from 'axios'
import map from 'lodash/map'
import Loading from '../../shared/loading'
import SectionTitle from '../../shared/sectionTitle'
import { t, l } from '../../../i18n'

const ProjectRow = ({ project }) => {
  return (
    <tr>
      <td>
        <div className='d-inline-block mr-3 image-wrapper'>
          <img src={project.thumbnailUri} />
        </div>
        <span>{project.name}</span>
      </td>
      <td>{project.description}</td>
      <td>{l('time.formats.default', project.created)}</td>
      <td>{l('time.formats.default', project.lastModified)}</td>
      <td>
        <a
          className='btn btn-primary text-nowrap px-3'
          href={project.url}
          target='_blank'
        >
          <span>{t('open')}</span>
          <i className='fa fa-share ml-2' />
        </a>
      </td>
    </tr>
  )
}

const ProjectsTable = ({ projects }) => {
  return (
    <table className='table table-robis table-custom-vision'>
      <thead className='thead-robis'>
        <tr>
          <th>{t('custom_vision.project')}</th>
          <th>{t('custom_vision.description')}</th>
          <th>{t('custom_vision.created_at')}</th>
          <th>{t('custom_vision.modified_at')}</th>
          <th className='min-width'></th>
        </tr>
      </thead>
      <tbody>
        {map(projects, project =>
          <ProjectRow key={project.id} project={project} />
        )}
      </tbody>
    </table>
  )
}

const CustomVision = () => {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadProjects()
  }, [])

  const loadProjects = () => {
    axios.get('/admin/custom_vision/projects').then(({ data }) => {
      setProjects(data)
    }).finally(() =>
      setLoading(false)
    )
  }

  return (
    <>
      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <SectionTitle rows={['Custom Vision', 'Projects']} />
        <a
          className='btn btn-primary'
          href={'https://www.customvision.ai/projects'}
          target='_blank'
        >
          {t('go_to', { name: 'Custom Vision' })}
        </a>
      </div>
      {loading
        ? <Loading />
        : <ProjectsTable projects={projects} />
      }
    </>
  )
}

export default CustomVision
