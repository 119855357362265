const extractDataErrors = error => {
  let data = (error.response || {})['data']

  if (typeof data === 'string' || data === undefined || data === null) {
    data = { errors: {}, full_errors: {} }
    if (error.response && error.response.statusText) {
      data = { errors: error.response.statusText, full_errors: { error: error.response.statusText } }
    }
  }

  return { errors: (data.errors || {}), full_errors: Object.values(data.full_errors).join(', ') }
}

export { extractDataErrors }
