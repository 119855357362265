import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

const styles = {
  modal: {
    display: 'block'
  }
}

class Modal extends PureComponent {
  static defaultProps = {
    onClose: function () { }
  }

  render() {
    const { headerText, headerContent, children, footerContent, widen, medium, footerClassName } = this.props

    const modalClass = classNames({
      'modal-dialog': true,
      'modal-lg': widen,
      'modal-md': medium
    })

    return (
      <div className='modal-open'>
        <div className='modal-backdrop show' />
        <div className='modal' style={styles.modal}>
          <div className={modalClass}>
            <div className='modal-content'>
              <div className='modal-header'>
                {headerText && <h5 className='modal-title'>{headerText}</h5>}
                {headerContent}
                <button type="button" className='close' onClick={this.props.onClose}>
                  &times;
                </button>
              </div>
              <div className='modal-body'>
                {children}
              </div>
              {footerContent && <div className={`modal-footer ${footerClassName}`}>{footerContent}</div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
