import React from 'react'

const PlusMinusWrapper = ({ className = '', name = '', value, step = 1, onChange, children }) => {

  // handle floating point number precision rounding error
  const handlePlus = () => {
    value = parseFloat(parseFloat(value + step).toFixed(4))
    onChange(name, value)
  }

  const handleMinus = () => {
    value = parseFloat((value - step).toFixed(4))
    onChange(name, value)
  }

  return (
    <div className={`plus-minus-input ${className}`}>
      <button className='minus-button' onClick={handleMinus}>
        -
      </button>
      {children}
      <button className='plus-button' onClick={handlePlus}>
        +
      </button>
    </div>
  )
}

export default PlusMinusWrapper
