import React from 'react'
import LocationEditForm from './LocationEditForm'
import SectionTitle from '../shared/sectionTitle'

const LocationEditScreen = ({ id, currencies }) => {
  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SectionTitle rows={['Edit', 'Location']} />
      </div>
      <div className="card card-robis">
        <LocationEditForm id={id} currencies={currencies} />
      </div>
    </>
  )
}

export default LocationEditScreen
