import React from 'react'
import map from 'lodash/map'
import FeederSlot from './FeederSlot'

const FEEDER_COUNT = 8
const FEEDER_COLS = 4

const FEEDER_SLOTS = Array.from({ length: FEEDER_COUNT }, (_, i) => i + 1)

const FeedersView: React.FC<{feeders: any}> = ({ feeders }) => {
  return (
    <div
      className='tab-content liquids'
      style={{ gridTemplateColumns: `repeat(${FEEDER_COLS}, 1fr)` }}
    >
      {map(FEEDER_SLOTS, slotNumber =>
        <FeederSlot
          key={slotNumber}
          slotNumber={slotNumber}
          feeder={feeders[slotNumber]}
        />
      )}
    </div>
  )
}

export default FeedersView
